import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dropdown } from '../../../../general/Dropdown';
import { Row, Col, Checkbox, Radio, Button, Divider } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import { CRM_COMPONENT_TYPES, MODAL_TYPE } from '../../../../../static/constants';
import { TextArea } from '../../../../general/TextAreaComponent';
// import { CustomUploaderGrid } from '../../../../general/CustomUploaderGrid';
import { showToasterMessage, } from '../../../../../utils';
import MapComponent from '../locationComp';
import Search from 'antd/lib/transfer/search';
import { StatusCategoryModal } from '../../subStageComp';
import { SubSourceComp } from '../../subSourceComp';


const CreateLead = (props) => {

    const { formFieldsSetting } = props;

    const handleLeadSubmit = () => {

        if (!props.formName) {
            return showToasterMessage({
                description: 'Please select form name',
                messageType: 'error'
            })
        }

        for (let i = 0; i < (formFieldsSetting || []).length; i++) {
            if (formFieldsSetting[i].isRequired && !formFieldsSetting[i].value) {
                return showToasterMessage({
                    description: 'Some required fields are missing',
                    messageType: 'error'
                })
            }
        }



        (formFieldsSetting || []).forEach((fieldObj, i) => {
            const { icon, optionList, value } = fieldObj;

            delete fieldObj.icon;

            if (optionList) {
                fieldObj.optionList = JSON.stringify(optionList);
            }

            if (typeof value !== 'string') {
                fieldObj.value = JSON.stringify(value);
            }

            formFieldsSetting[i] = fieldObj;
        });

        props.updateState({
            formFieldsSetting
        });

        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            leadName: props?.formFieldsSetting ? props?.formFieldsSetting[0]?.value : '',
            leadSourceId: props.selectedLeadSource?.id,
            leadSourceName: props.selectedLeadSource?.name,
            leadStatusId: props.selectedLeadStatus?.id,
            statusName: props.selectedLeadStatus?.name,
            crmLeadDetailsList: props.formFieldsSetting,
            crmLeadEmpAssignedList: props.crmLeadEmpAssignedList,
            statusColorForUICell: '',
            createdByEmpId: props.userInfo.relationshipEmployeeId,
            updatedByEmpId: props.userInfo.relationshipEmployeeId,
            crmLeadFormSettingId: props.crmLeadFormSettingId,
            formName: props.formName,
            crmLeadTeamAssignedList: props.crmLeadTeamAssignedList,
            lat: props.latLong?.lat,
            log: props.latLong?.lng,
            isWalkInLead: props.isWalkInLead ? 1 : 0

        }
        props.saveOrUpdateLeads(payload, props);
    }

    const getComponentItem = (item, idx) => {
        switch (item.type) {
            case CRM_COMPONENT_TYPES.INPUT_TEXT: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>
                    <TextBox
                        placeholder={item.placeholder}
                        type="text"
                        value={item.value}
                        onChange={(e) => {
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.INPUT_NUMBER: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>

                    <TextBox
                        placeholder={item.placeholder}
                        type="text"
                        value={item.value}
                        onChange={(e) => {
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.MULTILINEINPUT: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>
                    <TextArea
                        style={{ width: '100%', padding: '0px 5px' }}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={(e) => {
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.DROPDOWN: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>
                    <Dropdown
                        items={item.options}
                        placeholder={item.placeholder}
                        valueKeyName='value'
                        optionKeyName='key'
                        value={item.value}
                        onSelect={
                            (optionValue, option) => {
                                const tempFormFieldsSetting = props.formFieldsSetting;
                                const itemObj = tempFormFieldsSetting[idx];
                                itemObj.value = option.value;
                                tempFormFieldsSetting[idx] = itemObj;
                                props.updateState({
                                    formFieldsSetting: tempFormFieldsSetting
                                })
                            }
                        }
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.MULTIPLE_CHOICE: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>
                    <Radio.Group
                        value={item.value}
                        onChange={(e) => {
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                        style={{ width: '100%' }}
                    >
                        <Row gutter={[16, 8]}>
                            {(item.options || []).map((rObj) => {
                                return <Col span={8}><Radio value={rObj.key}>{rObj.value}</Radio></Col>
                            })}
                        </Row>
                    </Radio.Group>
                </Col>
            }
            case CRM_COMPONENT_TYPES.CHECKBOX: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span> : ''}</div>
                    <Checkbox.Group
                        value={item.value}
                        onChange={(val) => {
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = val;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                        style={{ width: '100%' }}
                    >
                        <Row gutter={[16, 8]}>
                            {(item.options || []).map((obj) => {
                                return <Col span={8}><Checkbox value={obj.key}>{obj.value}</Checkbox></Col>
                            })}
                        </Row>

                    </Checkbox.Group>
                </Col>
            }
            // case CRM_COMPONENT_TYPES.FILE_ATTACHMENT: {
            //     return <Dragger {...dragProps} >
            //             <p className="ant-upload-drag-icon">
            //                 <i className="fi fi-rr-cloud-upload" style={{fontSize: '30px'}}></i>
            //             </p>
            //             <p className="ant-upload-text">Click or drag file to this area to upload</p>
            //             <p className="ant-upload-hint">
            //                 Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            //                 banned files.
            //             </p>
            //     </Dragger>
            // }
            //     break;
            default: { return }
        }
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: 'Add Status',
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }
    console.log("sdcssdsvdbfhfghfghfsdgh", props);

    const addItemConfirmationModal = (props, text, modalType) => {
        const { showModal } = props;
        const labelName = 'Status Name';
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        const modalData = {
            modalBody,
            handleSubmit: () => {
                const payload = {};
                props.saveOrUpdateLeadStatus(payload);
                props.hideModal();
            },
        };
        showModal(modalData);
    }
    const handlePopUp = (callBack) => {
        const modalData = {
            title: "confirmation",
            modalBody: <StatusCategoryModal callBack={callBack} {...props} />,
            width: 500,
            handleSubmit: () => {
            },
        };
        props.showModal(modalData);
    }

    const handlePopUpSource = (callBack) => {
        const modalData = {
            title: "confirmation",
            modalBody: <SubSourceComp callBack={callBack} {...props} />,
            width: 500,
            handleSubmit: () => {
            },
        };
        props.showModal(modalData);
    }

    return (
        <>
            <div className='lead_head'>Add New Lead</div>
            <Divider style={{ margin: '0px' }} />
            {props.isWalkInLead && props.stage !== 2 ? <div className='lead-map-div'><MapComponent {...props} value={{ lat: 0, lng: 0 }} radius={props.radius || 100} onChange={(value) => {
                props.updateState({
                    latLong: value
                })
            }} /></div> : <div className='lead-div'>
                <Row gutter={[24, 16]}>
                    {props.isWalkInLead && <Col span={24}>
                        <div>
                            <h2>Selected Address:</h2>
                            <p>{props.selectedAddress}</p>

                        </div>
                    </Col>}
                    <Col span={12}>
                        <div className='i-label'>Select Name<span className='required'>*</span></div>
                        <Dropdown
                            items={props.crmLeadFormList.filter((obj) => obj.isActive)}
                            valueKeyName='formName'
                            optionKeyName='crmLeadFormSettingId'
                            value={props.crmLeadFormSettingId}
                            placeholder={'Select Form'}
                            onSelect={
                                (optionValue, option) => {
                                    const selectedLeadForm = props.crmLeadFormList.find((obj => obj.crmLeadFormSettingId === optionValue));
                                    let camp = (props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === selectedLeadForm?.campaignId);
                                    console.log("gg", camp);

                                    props.getProjectById({ relationshipId: props.companyInfo.relationshipId, projectMasterId: camp?.projectMasterId })
                                    props.updateState({
                                        formFieldsSetting: JSON.parse(selectedLeadForm.formFieldsSetting),
                                        formName: option.name,
                                        crmLeadFormSettingId: optionValue,
                                        selectedLeadForm,
                                        isWalkInLead: selectedLeadForm.isWalkInLead ? true : false,
                                        radius: selectedLeadForm.radius,
                                        getProjectdata: true,
                                    });
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        {props.selectedLeadForm?.campaignName ? <>
                            <div className='i-label'>Campaign<span className='required'>*</span></div>
                            <TextBox
                                disable
                                value={(props.selectedLeadForm || {})?.campaignName}
                            />
                        </> : ""}
                    </Col>
                    {(props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === props.selectedLeadForm?.campaignId)
                        &&
                        <Col className="detail-para" span={12}>This Campaign is associated with Project -
                            {`${(props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === props.selectedLeadForm?.campaignId)
                                ?.projectName}`} </Col>}

                    <Divider style={{ marginBottom: '16px' }} />
                    <Col span={24}>
                        <div className='sub_head'>Lead Details</div>
                    </Col>
                    {
                        (props.formFieldsSetting || []).length ? props.formFieldsSetting.map((item, i) => {
                            return < Fragment key={'formField-' + i}>
                                {getComponentItem(item, i)}
                                {
                                    i === 2 ? <>
                                        <Col span={8}>

                                        </Col>
                                        <Divider style={{ marginBottom: '16px' }} />
                                        <Col span={24}>
                                            <div className='sub_head'>Additional Details</div>
                                        </Col>
                                    </>
                                        : ''
                                }
                            </Fragment>
                        })
                            :
                            <Col span={24}>
                                *Please select form to fill lead details.
                            </Col>
                    }



                    <Divider style={{ marginBottom: '16px' }} />
                    <Col span={24}>
                        <div className='sub_head'>Lead Information</div>
                    </Col>

                    <Col span={8}>
                        <div className='i-label'>Source<span className='required'>*</span></div>
                          <>
                            <div onClick={() => {
                            const callBack = (data) => {

                            props.updateState({
                                selectedLeadSource: { id: data.crmLeadSourceId, name: data.leadSourceName }
                            })
                            }
                            handlePopUpSource(callBack)
                            }}>
                            <TextBox
                                value={
                                    props.selectedLeadSource?.name || ""
                                }
                                placeholder={'Enter Parent Category'}
                            />
                            </div>

                            </>
                    </Col>

                    <Col span={8}>
                        <div className='i-label'>Status<span className='required'>*</span></div>
                      
                         <>

                            <div onClick={() => {
                            const callBack = (data) => {
                            props.updateState({
                                selectedLeadStatus: { id: data.leadStatusId, name: data.statusName }
                            });
                            }

                            handlePopUp(callBack);
                            }}>
                            <TextBox
                                value={
                                    props.selectedLeadStatus?.name || ""
                                }
                                placeholder={'Enter Parent Category'}
                            />
                            </div>

                            </>
                    </Col>
                </Row>
            </div>}
            {props.isWalkInLead && props.stage ?
                <div className='form-footer' style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                    <div className='f-left'>
                        <Button
                            onClick={() => {
                                if (props.stage === 2 && props.isWalkInLead) {
                                    props.updateState({
                                        stage: 1
                                    })
                                } else {

                                    props.popModalFromStack();
                                }
                            }}
                        >
                            {(props.stage === 2 && props.isWalkInLead) ? "Back" : "Cancel"}
                        </Button>
                    </div>
                    <div className='f-right'>
                        <Button
                            type='primary'
                            onClick={() => {
                                if (props.stage === 1 && props.isWalkInLead) {
                                    if (Object.keys(props.latLong || {})?.length && props.selectedAddress?.length) {
                                        props.updateState({
                                            stage: 2
                                        })

                                    } else {
                                        showToasterMessage({
                                            description: 'Select Location',
                                            messageType: 'error'
                                        });
                                    }
                                } else {
                                    handleLeadSubmit();
                                }
                            }}
                        >
                            {(props.stage === 1 && props.isWalkInLead) ? "Next" : "Submit"}
                        </Button>
                    </div>
                </div> : <div className='form-footer' style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                    <div className='f-left'>
                        <Button
                            onClick={() => {
                                props.popModalFromStack();
                            }}
                        >
                            {"Cancel"}
                        </Button>
                    </div>
                    <div className='f-right'>
                        <Button
                            type='primary'
                            onClick={() => {

                                handleLeadSubmit();

                            }}
                        >
                            {"Submit"}
                        </Button>
                    </div>
                </div>}
        </>
    );
};

export default injectIntl(CreateLead);
