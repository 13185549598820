import { SHIPMENT_ACTION_LIST } from '../static/constants';
const initialState = {
  shipmentData: {},
  shipmentList: {
    1: []
  },
  shipmentCount: 0,
  carriarList: [],
  carrierRates: [],
  isLoadingCarriarList: false,
  buyShipmentDetails: [],
  boughtShipment: {},
  shiprocketCarrierDetails: {},
  shiprocketOrderDetails: {},
  shiprocketIntgratedChannels: [],
}

const ShipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIPMENT_ACTION_LIST.SHIPMENT_LIST:
      return {
        ...state,
        shipmentList: {
          ...state.shipmentList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SHIPMENT_ACTION_LIST.SHIPMENT_COUNT:
      return { ...state, shipmentCount: action.data };
    case SHIPMENT_ACTION_LIST.SHIPMENT_DATA:
      return { ...state, shipmentData: action.data };
      case SHIPMENT_ACTION_LIST.RESET_SHIPMENT_DATA:
      return { ...state, shipmentData: initialState.shipmentData };
    case SHIPMENT_ACTION_LIST.SHIPMENT_LIST_PAGE_RESET:
      return { ...state, shipmentList: initialState.shipmentList };
    case SHIPMENT_ACTION_LIST.NEXT_SHIPMENT_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SHIPMENT_ACTION_LIST.NEXT_SHIPMENT_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case SHIPMENT_ACTION_LIST.CARRIAR_LIST:
      return { ...state, carriarList: action.data };
    case SHIPMENT_ACTION_LIST.CARRIARS_PRICE_LIST:
      return { ...state, carrierRates: action.data };
    case SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST:
      return { ...state, isLoadingCarriarList: action.data };
    case SHIPMENT_ACTION_LIST.BUY_SHIPMENT:
      return { ...state, buyShipmentDetails: action.data };
    case SHIPMENT_ACTION_LIST.BOUGHT_SHIPMENT:
      return { ...state, boughtShipment: action.data };  
    case SHIPMENT_ACTION_LIST.CARRIER_DETAILS:
      return { ...state, carrierDetails: action.data };
    case SHIPMENT_ACTION_LIST.SHIPROCKET_CARRIER_DETAILS:
      return { ...state, shiprocketCarrierDetails: action.data };
    case SHIPMENT_ACTION_LIST.SHIPROCKET_ORDER_DETAILS:
      return { ...state, shiprocketOrderDetails: action.data };
    case SHIPMENT_ACTION_LIST.SHIPROCKET_CHANNELS:
      return { ...state, shiprocketIntgratedChannels: action.data };
    default:
      return state;
  }
};

export default ShipmentReducer;