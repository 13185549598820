import React, { Fragment, useState, forwardRef } from "react";
import { FormattedMessage } from 'react-intl';
import * as debounce from 'lodash.debounce';
import { Select, Divider } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import * as getValue from 'lodash.get';
import './general.scss';
import * as find from "lodash.find";
import { PERMISSION_VALUES, TX_STATUS, CONSTANTS } from '../../static/constants';
import { checkACLPermission } from '../../utils';
import * as filter from "lodash.filter";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const { Option } = Select;

export const DetailDropdown = (props) => {
    const [fetching, updateFetching] = useState(props.onSearch ? true : false);
    const [initialize,setInitialize] = useState(false);
    const getSortItems = (items, direction="asc", sortKey)=>{
        let sortedItems;

        if(!items.length){
            return items;
        }
        
        if (typeof items[0] === 'string') {
            sortedItems = items.slice().sort();
        } 
        else if(typeof items[0] === 'object' && sortKey) {
            sortedItems = items.slice().sort((a, b) => {
                return direction?.toLocaleLowerCase() ==='asc' ? a[sortKey]?.localeCompare(b[sortKey]): b[sortKey]?.localeCompare(a[sortKey]) ;
            });
        }

        return sortedItems;
    }
    const items = props.sortItems ? getSortItems((props.items || []), props.sortDirection, (props.sortKey || props.valueKeyName)) : (props.items || []);
   
    let canAddNew = props.canAddNew || false;
    let canDelete = props.canDelete || false;
    let isSelectAll = props.isSelectAll || false;
    const permissions = props.permissions || {};
    const primaryPerm = permissions.primary;
    const operations = props.operations;
    const moduleName = props.moduleName;
    if (canAddNew && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.CREATE) > -1) {
        canAddNew = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.CREATE);
    }
    if (canDelete && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.DELETE) > -1) {
        canDelete = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.DELETE);
    }
    const deleteClickHandler = props.deleteClickHandler || (() => {
    });
    const [newTextObj, setNewTextObj] = useState({ textEntered: '', existsInData: false });

    setTimeout(() => {
        updateFetching(false);
    }, 20000);

    return (
        <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode={props.mode || 'default'}
            showSearch={props.showSearch === false ? false : true}
            className={props.className || ' custom-dropdown-width cell-dropdown'}
            dropdownClassName={{width:"300px"}}
            onDeselect={props.onDeSelect ? props.onDeSelect : () => {
            }}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            clearIcon={<i className="fa fa-times-circle" onClick={props.onClear}/>}
            allowClear={props.allowClear}
            disabled={props.disabled}
            style={props.style}
            value={props.value || props.defaultValue}
            optionFilterProp='name'
            notFoundContent= {  initialize ?  fetching  ? <Loading3QuartersOutlined className="spinnerIcon" spin /> : "No record(s) found":<div className="detailDropdown-StartupDiv"><div className="content">Begin Typing to Search Your {props.optionKeyName === "customerId" ?  "Customer" : "Supplier" }</div></div>}
            onSelect={props.onSelect}
            onChange={props.onChange}
            onClick = {props.onClick}          
            open = {props.open}
            onSearch={(searchedText) => {
                updateFetching(true)
                setInitialize(true); 
                props.onSearch ? debounce((searchedText) => {
                    props.onSearch(searchedText)
                }, 2000)(searchedText) : (searchedText => {
                    setTimeout(() => {
                        const searchedResults = items.filter(
                            x => (x[props.valueKeyName] || '').toString().toLowerCase().indexOf(searchedText.toString().toLowerCase()) > -1
                        );
                        updateFetching(false)

                            setInitialize(true);

                        if (!searchedResults.length) {
                            setInitialize(false);

                            setNewTextObj({ textEntered: searchedText, existsInData: false });
                        } else {
                            setNewTextObj({ textEntered: searchedText, existsInData: true });
                        }
                    }, 2000)
                })(searchedText)
            }}
            dropdownRender={dropdown => {
                return (
                    <Fragment>
                        {
                            canAddNew ?
                                <Fragment>
                                    <div
                                        style={{ padding: "4px 8px", cursor: "pointer" }}
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => {
                                            props.onAddNewClick(newTextObj)
                                        }}
                                        className='add-new'
                                    >
                                        <i className="fa fa-plus" />
                                        <FormattedMessage id='add' defaultMessage='' />
                                        {
                                            newTextObj.existsInData || !newTextObj.textEntered
                                                ? <FormattedMessage id=' new' defaultMessage='' />
                                                : ` ${newTextObj.textEntered}`
                                        }
                                    </div>
                                    <Divider style={{ margin: "1px 0" }} />
                                </Fragment>
                                : ''
                        }
                        {
                            isSelectAll ?
                                <>
                                    <div
                                        style={{ padding: "4px 8px", cursor: "pointer" }}
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => {
                                            props.selectAllClick()
                                        }}
                                        className='select-all'
                                    >
                                        <i className="fi fi-br-list-check mr5" /> Select All
                                    </div>
                                    <Divider style={{ margin: "1px 0" }} />
                                </>
                                : ''
                        }
                        {dropdown}
                    </Fragment>
                )
            }}
        > 
            {
                items.map && items.map((item) => {
                    const value = props.valueKeyName ? getValue(item, props.valueKeyName, '') : item;
                    const isFavourite = props.isFavouriteKeyName ? getValue(item, props.isFavouriteKeyName, '') : '';
                    const value2 = props.valueKeyName2 ? getValue(item, props.valueKeyName2, '') : '';
                    const isLock = props.isLockKeyName ? getValue(item, props.isLockKeyName, '') : '';
                    const key = typeof item === 'object' ? getValue(item, props.optionKeyName, '') : item;
                    const allSupplierBillingAddress = filter(item.boLocationList, {
                        locationType: CONSTANTS.BILLING_ADDRESS,
                      });
                      const defaultBillingAddress = find(allSupplierBillingAddress, {
                        isDefault: 1,
                      });
                      const selectedContact =(item.boContactList || []).find((x) => x.isPrimaryContact) || {};
                 
                  const addressLine = defaultBillingAddress? `${defaultBillingAddress.cityName}/${defaultBillingAddress.countryName}/${defaultBillingAddress.streetAddress1}/${defaultBillingAddress.streetAddress2}-${defaultBillingAddress.zipCode}`:""
                  const contactLine = Object.keys(selectedContact)?.length ? `${selectedContact.emailAddress} `:""
                  const phone = Object.keys(selectedContact) && selectedContact.countryCallingCode && selectedContact.cellPhone?.length ? `${selectedContact.countryCallingCode}-${selectedContact.cellPhone?selectedContact.cellPhone:""}`:""
                    return (
                        <Option key={key} name={value + (value2 ?   ' '+value2 : "")} value={key} 
                            className={props.optionClassAddress + " " + props.optionClass || ' custom-dropdown-option'}>
                            <span className={props.optionClass || ' custom-dropdown-option pl5'} >
                            <strong>{value}</strong>
                            {defaultBillingAddress ? <br /> : ""}

                             {defaultBillingAddress ?addressLine:""}
                             {selectedContact ? <br /> : ""}
                            
                            {selectedContact? contactLine:""}
                            {phone ? <br /> : ""}
                            {phone? phone:""}



                            </span>
                            {
                                canDelete ?
                                    (value === TX_STATUS.OPEN ? <i className='fa fa-lock option-delete-icon' style={{ float: 'right', marginTop: 5 }} />
                                        :
                                        isLock ?
                                            <i className='fa fa-lock option-delete-icon' style={{ float: 'right', marginTop: 5 }} /> :
                                            <i
                                                className='fa fa-trash option-delete-icon'
                                                onClick={e => {
                                                    const payload = {
                                                        id: key,
                                                        text: value,
                                                    };
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    deleteClickHandler(payload);
                                                }}
                                                style={{ float: 'right', marginTop: 5 }}
                                            />)
                                    : ''
                            }

                            {
                                isFavourite ? <span className="favourite-mark"><i className="fa fa-star"/></span> : ''
                            }
                        </Option>
                    )
                })
            }
        </Select>
    );
}

export const DropdownRef = forwardRef(DetailDropdown);
