/* eslint-disable no-control-regex */
export const CONSTANTS = {
  EMAIL_REGEX_EXP:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  INDIA_COUNTRY_ID: 45,
  RELATIONSHIP_BILLING_ADDRESS: "RelationshipBillingAddress",
  RELATIONSHIP_SHIPPING_ADDRESS: "RelationshipShippingAddress",
  BILLING_ADDRESS: "BillingAddress",
  SHIPPING_ADDRESS: "ShippingAddress",
  DISPLAY_DATE_FORMAT: "DD MMM YYYY",
  DISPLAY_DATE_TIME_FORMAT: "DD MMM YYYY HH:mm:ss",
  DISPLAY_DATE_FORMAT_FULl: "ddd, DD MMM YYYY HH:mm:ss",
  DISPLAY_DATE_FORMAT_FY: "DD MMMM YYYY",
  TABLE_DATE_FORMAT: "DD MMM YYYY",
  DISPLAY_DATE_FORMAT_SHORT: "DD MMM YY",
  BACKEND_FORMAT: "YYYY-MM-DD HH:mm:ss",
  BACKEND_FORMAT_FORMAT_SHORT: "YYYY-MM-DD",
  DISPLAY_TOOLTIP_DATE_FORMAT: "ddd, DD MMM YYYY hh:mm A",
  PRIORITIES: ["Low", "Medium", "High", "Urgent"],
  DISPLAY_DATE_FORMAT_SESSION: "MMMM YYYY",
  DISPLAY_ONLY_DATE: "DD MMMM YYYY",
  DISPLAY_ONLY_TIME: "HH:mm:ss",
  STATUS_TYPES: Object.freeze({
    NEW: "New",
    ACTIVE: "Active",
    DRAFT: "Draft",
    OPEN: "open",
  }),
  FILTER_DATE_TYPE: Object.freeze({
    START: "start",
    END: "end",
  }),
  DATE_PART_TYPE: Object.freeze({
    DAY: "day",
    MONTH: "month",
    YEAR: "year",
  }),
  IGST: "igst",
  CONTACT_TYPE_CUSTOMER: "Customer",
  CONTACT_TYPE_SUPPLIER: "Supplier",
  CONTACT_TYPE_FUNDRAISER: "Fundraiser",
  TYPE_IMAGE: "image",
  CGST_AND_SGST: "cgst_and_sgst",
  BASE_URL_S3: "https://s3.amazonaws.com",
  COMPANY_SIZE_LIST: [
    "1-9",
    "10-199",
    "200-499",
    "500-2000",
    "2000-9999",
    "10000+",
  ],
  INVENTORY_ITEM_TY_LIST: [
    "1-299",
    "300-599",
    "600-999",
    "1000-1999",
    "2000-4999",
    "5000+",
  ],
  AVERAGE_MONTHLY_ORDER_LIST: [
    "1-49",
    "50-99",
    "100-199",
    "200-499",
    "500-999",
    "1000+",
  ],
  SOURCE_OF_HEARING_LIST: [
    "Family or Friends",
    "Google",
    "Internet",
    "TV",
    "Advertisement",
    "Social Media (Facebook, Twitter, LinkedIn)",
    "Email/Newsletter",
    "Magazine Article",
    "Email",
  ],
  DEFAULT_DECIMAL_NUMBER_LENGTH: 2,
  PAYMENT_SOURCE: Object.freeze({
    MANUAL: "manual",
    CSV_UPLOAD: "csvUpload",
  }),
  WAREHOUSE_ADDRESS:"WarehouseAddress",
  NAME_REGEX_EXP:/^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
  WEBSITE_URL_REGEX:/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/
};

export const PLAN_IDS = {
  GOLD: 1,
  PLATINUM: 2,
  DIAMOND: 3,
  FLY: 4,
  GOLD_A: 5,
  PLATINUM_A: 6,
  DIAMOND_A: 7,
  CUSTOM_A: 8,
};

export const PERMISSION_VALUES = {
  READ: 1,
  CREATE: 2,
  UPDATE: 4,
  DELETE: 8,
};

export const MODULE_CODED_VALUES = {
  SUPPLIERS: "A",
  PURCHASE_REQUEST: "B",
  PURCHASE_ORDER: "C",
  PURCHASE_INVOICE: "D",
  SUPPLIER_PAYMENT: "E",
  DEBIT_MEMO: "F",
  CUSTOMERS: "G",
  RFQ: "H",
  SALES_QUOTE: "I",
  SALES_ORDER: "J",
  SALES_INVOICE: "K",
  CUSTOMER_PAYMENT: "L",
  CREDIT_MEMO: "M",
  QUALITY_CHECK: "MQ",
  PRODUCTS: "N",
  MY_WAREHOUSE: "O",
  STOCK_SUMMARY: "P",
  STOCK_ADJUSTMENT: "Q",
  AGED_STOCK: "R",
  PRODUCT_PRICE: "S",
  PRICE_LIST: "T",
  CUSTOMER_BILLING_ADDRESS: "U",
  SUPPLIER_BILLING_ADDRESS: "V",
  UOM: "W",
  LEDGER_ACCOUNTS: "X",
  BALANCE_SHEET: "Y",
  PROFIT_LOSS: "Z",
  TRIAL_BALANCE: "AA",
  OPENING_BALANCE: "AB",
  JOURNALS: "AC",
  BUSINESS_EXPENSE: "AD",
  BANK_RECONCILIATION: "AE",
  TAXES: "AF",
  GST_FILLING: "AG",
  FINANCIAL_YEARS: "AH",
  COST_CENTERS: "AI",
  REPORTS: "AJ",
  SETTINGS: "AK",
  LOCK_SCREEN: "AL",
  SALES_CHANNEL: "AM",
  PUBLIC_PRODCUCTS: "AN",
  INTEGRATIONS_PAYMENT: "AO",
  SHIPPING: "AP",
  ACCOUNTING: "AQ",
  SALES_INVOICE_MULTI_CURRENCY: "AR",
  PURCHASE_INVOICE_MULTI_CURRENCY: "AS",
  SUPPLIER_EXPENSE: "AT",
  SALES_QUOTE_MULTI_CURRENCY: "AU",
  SALES_ORDER_MULTI_CURRENCY: "AV",
  CUSTOMER_EXPENSE: "AW",
  PURCHASE_ORDER_MULTI_CURRENCY: "AX",
  PAYMENT_TERM: "AY",
  SALES_ORDER_PACKAGE: "AZ",
  SALES_ORDER_SHIPMENT: "BA",
  PRODUCT_CATEGORY: "BB",
  PRODUCT_MANUFACTURER: "BC",
  PRODUCT_BRAND: "BD",
  RECEIVE_PAYMENT: "BE",
  PAY_INVOICE: "BF",
  INVENTORY: "BG",
  CUSTOMER_ADDRESS: "BH",
  CUSTOMER_CONTACTS: "BI",
  CUSTOMER_ACTIVITY: "BJ",
  CUSTOMER_COMMUNICATION: "BK",
  SUPPLIER_ADDRESS: "BL",
  SUPPLIER_ACTIVITY: "BM",
  SUPPLIER_CONTACTS: "BN",
  SUPPLIER_COMMUNICATION: "BO",
  SETTINGS_COMPANY_INFORMATION: "BP",
  SETTINGS_USER_MANAGEMENT: "BQ",
  SETTINGS_DOCUMENTS_CENTER: "BR",
  SETTINGS_EMAIL_TEMPLATES: "BS",
  SETTINGS_REMARKS: "BT",
  SETTINGS_DOCUMENTS_NAME: "BU",
  SALES_ENQUIRY: "BV",
  SETTINGS_DOCUMENTS_APPROVAL: "BW",
  SETTINGS_REGISTRATION_FORM: "BX",
  SETTINGS_DONATION: "BY",
  SALES_INVOICE_STUDENT: "BZ",
  REGISTERED_MEMBER: "CA",
  BANKING: "CB",
  MEMBERSHIP: "CC",
  FUNDRAISING: "CD",
  COMMUNICATION: "CE",
  NEWS_LETTER: "CF",
  EVENTS: "CG",
  ONLINE_STORES: "CH",
  KIOSK: "CI",
  ECOMMERCE: "CJ",
  COMPANY_HIERARCY: "CK",
  PROJECT: "CL",
  SCHOOL_DASHBOARD: "CM",
  SCHOOL_DEPARTMENT: "CN",
  SCHOOL_COURSE_DETAIL: "CO",
  SCHOOL_CLASS_DETAIL: "CP",
  SCHOOL_ATTENDANCE: "CQ",
  SCHOOL_STUDENT: "CR",
  SCHOOL_TEACHER: "CS",
  SCHOOL_INVOICE: "CT",
  //SCHOOL_INVOICE: 'K',
  SCHOOL_FORM: "CU",
  SCHOOL_COMMUNICATION: "CV",
  DASHBOARD: "CW",
  SCHOOL_BROADCAST_MESSAGE: "CX",
  SCHOOL_PARENTS: "CY",
  SCHOOL_ONLINE_USER: "CZ",
  SCHOOL_DOCUMENT: "DA",
  SCHOOL_RECURRING_PAYMENTS: "DB",
  SCHOOL_ASSIGNMENTS: "DC",
  SCHOOL_PAYMENT: "DD",
  SCHOOL_CUSTOMERS: "DE",
  INVENTORY_VALUATION: "DF",
  APPEARANCE: "DG",
  CUSTOMERS_PROFILE: "DH",
  ADD_DELIVERY_TIME:"DI",
  // newly added -- 16 APR, 2024
  ALL_LEADS: "DJ",
  MY_LEADS: "DK",
  LEAD_FROM_SETTING: "DL",
  CRM_CUSTOMER: "DM",
  SCHOOL_TEACHER_ATTENDANCE: "DN",
  SCHOOL_TIME_TABLE: "DO",
  SALES_INQUIRY: "DP",
  SALES_SUPPORT: "DQ",
  PURCHASE_GRN: "DR",
  PURCHASE_PAYMENT: "DS",
  PURCHASE_LANDED_COST: "DT",
  CLOUD_DRIVE: "DU",
  ALL_CONTACT: "DV",
  PRODUCT_HSN: "DW",
  STOCK_TRANSFER: "DX",
  HRMS_EMPLOYEE: "DY",
  HRMS_EMPLOYEE_ATTENDANCE: "DZ",
  HRMS_LEAVE: "EA",
  HRMS_PAYROLL: "EB",
  HRMS_EMPLOYEE_SERVICES: "EC",
  HRMS_EMPLOYEE_TRAINING: "ED",
  HRMS_ASSET: "EE",
  SALES_REPORTS:"EF",
  INVENTORY_REPORTS:"EG",
  LEAD_REPORTS:"EH",
  CRM_REPORTS:"EI",
  E_STORE_APPEARANCE: "EJ",
  E_STORE_PRODUCT_MGMT: "EK",
  E_STORE_SETTING: "EL",
  INTEGRATION_MARKETPLACE: "EM",
  INTEGRATION_SHIPPING: "EN",
  INTEGRATION_ACCOUNTING: "EO",
  BUSINESS_EXPENSE: "EP",
  BANKING_BOOKS: "EQ",
  PURCHASE_REPORTS: "ER",
  WAREHOUSE_REPORTS: "ES",
  CUSTOM_REPORTS: "ET",
  PRODUCT_QRCODE: "EU",
  PRUDUCT_BARCODE: "EV",


// ----------------------
  // adding new for crm 
  CRM_OPPORTUNITY: "EW",
  CRM_LEAD_TASK_LIST: "EX",
  CRM_CALL_LOGS_LIST: "EY",
  CRM_LEAD_TRANSFER: "EZ",
  CRM_LEAD_WORKFLOWS: "FA",
  CRM_LEAD_STAGE: "FB",
  AMEND_SALES_ORDER: "FC",
  CRM_LEAD_ALL_REMINDER:"FE",
  CRM_LEAD_NOTES:'FF',
  PRODUCT_REVIEW:'PR',
  E_STORE_BLOGS_MGMT: "PS",
};

export const EDITABLE_MODULE_CODES = [
  MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
  MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
  MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
  MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
  MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
  MODULE_CODED_VALUES.SCHOOL_STUDENT,
  MODULE_CODED_VALUES.SCHOOL_TEACHER,
  MODULE_CODED_VALUES.SCHOOL_INVOICE,
  MODULE_CODED_VALUES.SCHOOL_FORM,
  MODULE_CODED_VALUES.SCHOOL_COMMUNICATION,
  MODULE_CODED_VALUES.DASHBOARD,
  MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
  MODULE_CODED_VALUES.SCHOOL_PARENTS,
  MODULE_CODED_VALUES.SCHOOL_ONLINE_USER,
  MODULE_CODED_VALUES.SCHOOL_DOCUMENT,
  MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
  MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
  MODULE_CODED_VALUES.SCHOOL_PAYMENT,
  MODULE_CODED_VALUES.AMEND_SALES_ORDER,
];

export const SECONDARY_PERM_KEYS = {
  PRODUCT_PRICE_LIST_WHOLESALE_PRICE: "productPrice.listing.wholesalePrice",
  PRODUCT_PRICE_LIST_RETAIL_PRICE: "productPrice.listing.retailPrice",
  PRODUCT_PRICE_LIST_PURCHASE_PRICE: "productPrice.listing.purchasedPrice",
  AGED_STOCK_LIST_LAST_PURCHASE_PRICE: "agedStock.listing.lastPurchasePrice",
  PRODUCT_PRICE_LIST_B2B_SELLING_PRICE: "productPrice.listing.b2bSellingPrice",
};

export const BUCKET_NAME = {
  BO_RELATIONSHIP: "bo-relationship",
  BO_SALES_INQUIRY: "bo-inquiry",
  BO_SALES_QUOTE: "bo-sales-quote",
  BO_SALES_ORDER: "bo-sales-order",
  BO_CUSTOMER_INVOICE: "bo-sales-invoice",
  BO_CREDIT_MEMO: "bo-credit-memo",
  BO_PURCHASE_REQUEST: "bo-purchase-request",
  BO_RFQ: "bo-supplier-rfq",
  BO_PURCHASE_ORDER: "bo-purchase-order",
  BO_INBOUND_DELIVERY: "bo-inbound-delivery",
  BO_SUPPLIER_INVOICE: "bo-purchase-invoice",
  BO_DEBIT_MEMO: "bo-debit-memo",
  BO_SAMPLE_RESOURCES: "sample-resources-u",
  VARIANT_IMAGE:"image",
  BO_VARIANT_IMAGE:"bo-variant-image"
};

export const CONSTANTS_TRANSACTIONS = {
  SALES_INVOICE: "Sales Invoice",
  SALES_QUOTE: "Sales Quote",
  SALES_ORDER: "Sales Order",
  SALES: "Sales",
  CREDIT_MEMO: "Credit Memo",
  SALES_DISCOUNT: "Sales Discount",
  SALES_PAYMENT: "Cutomer Payment",
  SALES_OUTPUT_TAX: "Sales Output Tax",
  CUSTOMER_EXPENSE: "Customer Expense",
  CUSTOMER_CESS: "Cess Payable",

  PURCHASE_REQUEST: "Purchase Request",
  RFQ: "RFQ",
  PURCHASE_INVOICE: "Purchase Invoice",
  STOCK_TRANSFER: "Stock Transfer",
  PURCHASE_ORDER: "Purchase Order",
  PURCHASE: "Purchase",
  DEBIT_MEMO: "Debit Memo",
  PURCHASE_PAYMENT: "Supplier Payment",
  SUPPLIER_CESS: "Cess Receivable",
  SUPPLIER_DISCOUNT: "Purchase Discount",
  SUPPLIER_EXPENSE: "Supplier Expense",
  PURCHASE_INPUT_TAX: "Purchase Input Tax",
  INQUIRY: "Inquiry",
  INBOUND_DELIVERY: "Inbound Delivery",
  SHIPMENT: "Shipment",
  PACKAGE: "Package",
  STUDENT: "Student",
  LANDED_COST: "Landed Cost",
  SALES_ORDER_ONLINE: "Sales Order Online",
  AMEND_SALES_ORDER: "Amend Sales Order",
};

export const CONSTANTS_TRANSACTIONS_KEY = {
  "Sales Invoice": "sales-invoice",
  "Sales Quote": "sales-quote",
  "Sales Order": "sales-order",
  "Sales Order Online": "sales-order-online",
  "Amend Sales Order": "amend-sales-order",
  Sales: "sales",
  "Credit Memo": "credit-memo",
  "Sales Discount": "sales-discount",
  "Cutomer Payment": "sales-payment",
  "Sales Output Tax": "sales-output-tax",
  "Customer Expense": "sales-expense",
  "Cess Payable": "cess-payable",
  "Stock Transfer": "stock-transfer",

  "Purchase Request": "purchase-request",
  RFQ: "rfq",
  "Purchase Invoice": "purchase-invoice",
  "Purchase Order": "purchase-order",
  Purchase: "purchase",
  "Debit Memo": "debit-memo",
  "Supplier Payment": "purchase-payment",
  "Cess Receivable": "cess-receivable",
  "Purchase Discount": "purchase-discount",
  "Supplier Expense": "purchase-expense",
  "Purchase Input Tax": "purchase-input-tax",
  Inquiry: "sales-inquiry",
  "Inbound Delivery": "inbound-delivery",
  Shipment: "shipment",
  Package: "package",
  "Landed Cost": "landed-cost",
};

export const ACCOUNTING_ENTRY_TYPE = {
  CREDIT: "credit",
  DEBIT: "debit",
};

export const SALE_INVOICE_TYPE = {
  STANDARD: "Standard Sales Invoice",
};

export const EXPENSE_TYPE = {
  TYPE_EXPENSE: "Expense",
};

export const RFQ_TYPE = {
  STANDARD: "Standard RFQ",
};

export const ENQUIRY_TYPE = {
  STANDARD: "Standard Sales Inquiry",
};

export const PURCHASE_INVOICE_TYPE = {
  STANDARD: "Standard Purchase Invoice",
};

export const PURCHASE_ORDER_TYPE = {
  STANDARD: "Standard Purchase Order",
};

export const TX_TYPE = {
  INVOICE: "Invoice",
  PO: "Purchase Order",
  PR: "Purchase Request",
  DEBIT_MEMO: "Debit Memo",
};

export const TX_TEMPLATE = {
  STANDARD: "standard",
  MULTI_CURRENCY: "multicurrency",
  STUDENT: "student",
};

export const TX_STATUS = {
  NEW: "new",
  OPEN: "open",
  PAID: "paid",
  COMPLETED: "completed",
  NOT_REQUIRED: "not.required",
  NOT_STARTED: "not.started",
  SPLIT: "Split",
  ACTIVE: "Active",
  UNPAID: "unpaid",
  DRAFT: "draft",
};

export const LEDGER_TYPE = {
  TYPE_SALES: "Sales",
  TYPE_SALES_RETURNS: "Sales Return",
  TYPE_PURCHASE_RETURNS: "Purchase Returns",
  TYPE_DEBTORS: "Debtors",
  TX_TYPE_CREDITORS: "Creditors",
  TX_PAYMENT_MADE: "Cash in hand",
  TYPE_PURCHASE: "Purchases",
  TYPE_CREDIT_MEMO: "Credit Memo",
  TYPE_DEBIT_MEMO: "Debit Memo",
  TYPE_PURCHASE_INPUT_TAX: "Purchase Input Tax",
  TYPE_SALES_DISCOUNT: "Sales Discount",
  TYPE_PURCHASE_DISCOUNT: "Discount Received",
  TYPE_SALES_OUTPUT_TAX: "Sales Output Tax",
  TYPE_CUSTOMER_EXPENSE: "Customer Expense",
  TYPE_SUPPLIER_EXPENSE: "Supplier Expense",
  TYPE_EXPENSE: "expense",
  TYPE_DISCOUNT: "discount",
  TYPE_TAX_IGST: "IGST",
  TYPE_TAX_VAT: "VAT",
  TYPE_TAX: "tax",
  TYPE_TAX_CGST: "CGST",
  TYPE_TAX_SGST: "SGST",
  TYPE_GENERAL_EXPENSE: "General Income",
  TYPE_GENERAL_SUPPLIER_EXPENSE: "General Expenses",
  TX_PAYMENT_RECIEVED: "Payment Recieved",
  TYPE_ROUNDING_OFF: "Rounding Off",
  TYPE_COUPON: "Coupon",
  TX_TYPE_OPENING_STOCK: "Opening Stock",
};

export const TRANSACTION_STATUS = {
  FULFILLED: "fulfilled",
  PARTIALLY_FULFILLED: "partially.fulfilled",
  COMPLETED: "completed",
  RECEIVED: "received",
  OVER_TOLERANCE: "over.tolerance",
};

export const GST_MECHANISM_OPTIONS = [
  "Charge",
  "Composition",
  "Exempt",
  "GST Number",
  "PAN Number",
  "Regular",
  "Reverse",
  "Scheme",
];
export const TAX_TYPE_OPTIONS_FOR_INDIA = ["CGST", "SGST", "IGST", "CESS"];
export const TAX_TYPE_OPTIONS_FOR_NON_INDIA = ["VAT"];

export const STORAGE_CONSTANTS = {
  REMEMBER_ME: "rememberMe",
  LOGIN_EMAIL: "email",
  LOGIN_PASSWORD: "password",
};

export const SIGN_UP_CONSTANTS = {
  IS_COMPANY_SETUP_REQUIRED: 1,
  HAS_ACCOUNT_ACTIVATED: 0,
  LOCATION_NAME: "HQ",
  LOCATION_TYPE: "RelationshipBillingAddress",
  IS_DEFAULT: "1",
  SUBSCRIPTION_ID: 6,
  CONTACT_TYPE: "User",
  RELATIONSHIP_ID: 121,
  STATUS: "Active",
  STATUS_COLOR: " success",
  IS_TRIAL: 0,
  IS_DESKTOP: false,
};

export const AUTH = {
  LOGOUT: "logout",
  SET_PERMISSIONS: "set_permissions",
  SHOW_RESEND_ACTIVATION: "show_resend_activation",
  HIDE_RESEND_ACTIVATION: "hide_resend_activation",
  SESSION_TYPE: "session_type"
};

export const COMMON_ACTIONS = {
  XERO_CONFIG_RELATIONSHIP_DATA: "xero_config_relationship_data",
  EMAIL_ADDRESS_LIST: "email_address_list",
  SHOW_LOADER: "show_loader",
  HIDE_LOADER: "hide_loader",
  SHOW_LOADER_EMAIL: "show_loader_email",
  HIDE_LOADER_EMAIL: "hide_loader_email",
  PAYMENT_LIST: "payment_list",
  PAYMENT_LIST_ADDITION: "payment_list_addition",
  PAYMENT_LIST_DELETION: "payment_list_deletion",
  INDUSTRY_LIST: "industry_list",
  INDUSTRY_LIST_ADDITION: "industry_list_addition",
  INDUSTRY_LIST_DELETION: "industry_list_deletion",
  SALUTATION_LIST: "slutation_list",
  SALUTATION_LIST_ADDITION: "slutation_list_addition",
  SALUTATION_LIST_DELETION: "slutation_list_deletion",
  PRICE_LIST: "price_list",
  PRICE_LIST_ADDITION: "price_list_addition",
  PRICE_LIST_DELETION: "price_list_deletion",
  UOM_LIST: "uom_list",
  RECENT_TX_LIST: "recent_tx_list",
  UOM_LIST_ADDITION: "uom_list_addition",
  UOM_LIST_DELETION: "uom_list_deletion",
  UOM_LIST_UPDATION: "uom_list_updation",
  EXPENSE_LIST: "expense_list",
  EXPENSE_LIST_DELETION: "expense_list_deletion",
  EXPENSE_LIST_ADDITION: "expense_list_addition",
  TAX_LIST: "tax_list",
  TAX_LIST_ADDITION: "tax_list_addition",
  TAX_LIST_DELETION: "tax_list_deletion",
  COUNTRIES_LIST: "countries_list",
  STATES_LIST: "states_list",
  BUSINESS_TYPE_LIST: "business_type_list",
  BUSINESS_TYPE_LIST_ADDITION: "business_type_list_addition",
  BUSINESS_TYPE_LIST_DELETION: "business_type_list_deletion",
  SOURCE_LIST: "source_list",
  SOURCE_LIST_ADDITION: "source_list_addition",
  SOURCE_LIST_DELETION: "source_list_deletion",
  LOGGEDIN_COMPANY_INFO: "logged_in_company_info",
  LOGGEDIN_USER_INFO: "logged_in_user_info",
  LOGGEDIN_USER_BANK_INFO: "logged_in_user_bank_info",
  LOGGEDIN_COMPANY_EMP_LIST: "logged_in_user_emp_list",
  PROJECT_LIST: "project_list",
  PROJECT_LIST_ADDITION: "project_list_addition",
  PROJECT_LIST_DELETION: "project_list_deletion",
  DOCUMENT_LIST: "document_list",
  DOCUMENT_LIST_ADDITION: "document_list_addition",
  DOCUMENT_LIST_DELETION: "document_list_deletion",
  LOG_OUT: "log_out",
  SUPPLIER_SOURCE_LIST: "supplier_source_list",
  SUPPLIER_SOURCE_LIST_ADDITION: "supplier_source_list_addition",
  SUPPLIER_SOURCE_LIST_DELETION: "supplier_source_list_deletion",
  PAYMENT_MODE_LIST: "payment_mode_list",
  CASH_EQUIVALENT_LEDGER: "cash_equivalent_ledgers",
  SUPPLIER_DETAIL: "supplier_detail",
  SUPPLIER_DETAIL_RESET: "supplier_detail_reset",
  CUSTOMER_DETAIL: "customer_detail",
  CUSTOMER_DETAIL_RESET: "customer_detail_reset",
  CUSTOMER_DETAIL_UPDATE: "customer_detail_update",
  CUSTOMER_DETAIL_UPDATE_RESET: "customer_detail_update_reset",
  DEPARTMENT_LIST: "department_list",
  DEPARTMENT_LIST_ADDITION: "department_list_addition",
  DEPARTMENT_LIST_DELETION: "department_list_deletion",
  EMPLOYEES_LIST: "employees_list",
  EMPLOYEES_LIST_ADDITION: "employees_list_addition",
  EMPLOYEES_LIST_DELETION: "employees_list_deletion",
  JOB_TITLE_LIST: "job_title_list",
  JOB_TITLE_LIST_ADDITION: "job_title_list_addition",
  JOB_TITLE_LIST_DELETION: "job_title_list_deletion",
  ADDRESS_DELETION: "address_deletion",
  BO_LOCATION_ADDITION: "bo_location_addition",
  BO_RELATIONSHIP_ADDRESS: "bo_relationship_address",
  BO_RELATIONSHIP_ADDRESS_RESET: "bo_relationship_address_reset",
  BO_LOCATION_DELETION: "bo_location_deletion",
  USER_PREFERENCES: "user_preferences",
  USER_PREFERENCES_FAIL: "user_preferences",
  SAVE_OR_UPDATE_PREFERENCE: "save_or_update_preference",
  SHIPPING_INSTRUCTION_LIST: "shipping_instruction_list",
  SHIPPING_INSTRUCTION_ADDITION: "shipping_instruction_addition",
  SHIPPING_INSTRUCTION_DELETION: "shipping_instruction_deletion",
  TAX_IDENTIFICATION_LIST: "tax_identification_list",
  TAX_IDENTIFICATION_ADDITION: "tax_identification_addition",
  TAX_IDENTIFICATION_DELETION: "tax_identification_deletion",
  REL_TAX_IDENTIFICATION_LIST: "rel_tax_identification_list",
  REL_TAX_IDENTIFICATION_ADDITION: "rel_tax_identification_addition",
  REL_TAX_IDENTIFICATION_DELETION: "rel_tax_identification_deletion",
  CUSTOMER_TAX_IDENTIFICATION_DELETION: "customer_tax_identification_deletion",
  SUPPLIER_TAX_IDENTIFICATION_DELETION: "supplier_tax_identification_deletion",
  QUALITY_CHECK_NAME: "quality_check_name",
  QUALITY_CHECK_NAME_ADDITION: "quality_check_name_addition",
  QUALITY_CHECK_NAME_DELETION: "quality_check_name_deletion",
  INCOTERM_LIST: "incoterm_list",
  INCOTERM_LIST_ADDITION: "incoterm_list_addition",
  INCOTERM_LIST_DELETION: "incoterm_list_deletion",
  USER_STATUS_LIST: "user_status_list",
  USER_STATUS_LIST_ADDITION: "user_status_list_addition",
  USER_STATUS_LIST_RESET: "user_status_list_reset",
  PAYPAL_CONFIG_RELATIONSHIP_DATA: "paypal_config_relationship_data",
  PAYPAL_DIRECT_CONFIG_RELATIONSHIP_DATA:
    "paypal_direct_config_relationship_data",
  ALL_PAYMENT_GATEWAY_CONFIG: "all_payment_gateway_config",
  PAY_ONLINE_URL: "pay_online_url",
  RESET_PAY_ONLINE_URL: "reset_pay_online_url",
  STRIPE_CONFIG_RELATIONSHIP_DATA: "stripe_config_relationship_data",
  CARD_CONNECT_CONFIG_RELATIONSHIP_DATA:
    "card_connect_config_relationship_data",
  WEB_INVOICE_DATA: "web_invoice_data",
  RECURRING_INVOICE_DATA: "recurrung_invoice_data",
  WEB_INVOICE_SHORT_URL: "web_invoice_short_url",
  RESET_WEB_INVOICE_SHORT_URL: "reset_web_invoice_short_url",
  SELECTED_PRODUCT_LIST: "selected_product_list",
  SUBDOMAIN: "subdomain",
  IS_SUBDAMIAN_EXIST: "is_subdomain_exist",
  EXPENSE_LEDGER_LIST: "expense_ledger_list",
  IS_ALIF_APP: "is_alif_app",
  CLIENT_REL_ADDITIONAL_INFO: "client_rel_additional_info",
  XERO_CONTACT_LIST: "xero_contact_list",
  XERO_LEDGER_LIST: "xero_ledger_list",
  XERO_TAX_LIST: "xero_tax_list",
  JOB_ROLES_LIST: "job_role_list",
  JOB_ROLES_LIST_ADDITION: "job_role_list_addition",
  JOB_ROLES_LIST_DELETION: "job_role_list_deletion",
  SCHOOL_SESSION_LIST: "school_session_list",
  SCHOOL_SESSION_LIST_ADDITION: "school_session_list_addition",
  SCHOOL_SESSION_LIST_DELETION: "school_session_list_deletion",
  DEFAULT_SCHOOL_FORM: "default_school_form",
  EMP_TYPE_LIST: "emp_type_list",
  EMP_TYPE_LIST_ADDITION: "emp_type_list_addition",
  EMP_TYPE_LIST_DELETION: "emp_type_list_deletion",
  ASSIGNMENTS_GROUP_LIST: "assignment_group_list",
  ASSIGNMENTS_GROUP_ADDITION: "assignment_group_addition",
  ASSIGNMENTS_GROUP_DELETION: "assignment_group_deletion",
  STUDENT_EDUCATION_LIST: "student_education_list",
  STORE_SETTING_VALUES: "store_setting_values",
  SUPPLIER_IMPORT_HEADER: "supplier_import_header",
  CUSTOMER_IMPORT_HEADER: "customer_import_header",
  PRODUCT_IMPORT_HEADER: "product_import_header",
  CONTACT_EDUCATION_LIST: "contact_education_list",
  SHOW_SKELETON: "show_skeleton",
  HIDE_SKELETON: "hide_skeleton",
  SHOW_LIST_LOADER: "show_list_loader",
  HIDE_LIST_LOADER: "hide_list_loader",
  SHOW_HEADER_LOADER: "show_header_loader",
  HIDE_HEADER_LOADER: "hide_header_loader",
  SHOW_DRAWER_LOADER: "show_drawer_loader",
  HIDE_DRAWER_LOADER: "hide_drawer_loader",
  SHOW_MODAL_LOADER: "show_modal_loader",
  HIDE_MODAL_LOADER: "hide_modal_loader",
  SHOW_MODAL1_LOADER: "show_modal1_loader",
  HIDE_MODAL1_LOADER: "hide_modal1_loader",
  SHOW_MODAL2_LOADER: "show_modal2_loader",
  HIDE_MODAL2_LOADER: "hide_modal2_loader",
  SHOW_MODAL3_LOADER: "show_modal3_loader",
  HIDE_MODAL3_LOADER: "hide_modal3_loader",
  SHOW_CREATE_LOADER: "show_create_loader",
  HIDE_CREATE_LOADER: "hide_create_loader",
  SHOW_SAVE_LOADER: "show_save_loader",
  HIDE_SAVE_LOADER: "hide_save_loader",
  PRICING_PLANS_LIST:"pricing_plans_list",
  USER_LOCATION: "user_location",
  SHOW_DETAIL_LOADER: "show_detail_loader",
  HIDE_DETAIL_LOADER: "hide_detail_loader",
  VARIANT_IMAGE_UPLOAD:"variant_image_upload",
  PLAN_INDUSTRY_LIST: "plan_industry_list",
  SHOW_PLAN_LOADER: 'show_plan_loader',
  HIDE_PLAN_LOADER: 'hide_plan_loader',
  ALPIDE_PRODUCT_LIST: "alpide_product_list",
  SUBCRIPTION_PLAN_LIST: "subscription_plan_list",
  SUBCRIPTION_ADDON_LIST: "subscription_addon_list",
  PRODUCT_LIST:"product_list",
  RAZORPAY_PAYMENT_ORDER: "razorpay_payment_order",
  SUBSCRIBED_PRODUCT:"subscribed_product"
};

export const CUSTOMER_ACTION_LIST = {
  CUSTOMER_LEDGER_LIST: "customer_ledger_list",
  RESET_CUSTOMER_LEDGER_LIST: "reset_customer_ledger_list",
  CUSTOMER_LIST: "customer_list",
  CUSTOMER_COUNT: "customer_count",
  CUSTOMER_LIST_PAGE_RESET: "customer_list_page_reset",
  CUSTOMER_OPEN_ORDER_COUNT: "customer_open_order_count",
  CUSTOMER_OPEN_SO_COUNT: "customer_open_so_count",
  CUSTOMER_UNPAID_BALANCE: "customer_unpaid_balance",
  CUSTOMER_SALES_REVENUE: "customer_sales_revenue",
  CUSTOMER_TOTAL_ORDER: "customer_total_order",
  CUSTOMER_TOTAL_ORDER_COUNT_BY_R_ID: "customer_total_order_count_by_r_id",
  TOTAL_ORDER_LIST_PAGE_RESET: "total_order_list_reset",
  CUSTOMER_OPEN_ORDER: "customer_open_order",
  CUSTOMER_OPEN_ORDER_COUNT_BY_CUSTOMER_ID:
    "customer_open_order_count_by_customer_id",
  OPEN_ORDER_LIST_PAGE_RESET: "open_order_list_reset",
  CUSTOMER_UNPAID_INVOICES_BY_CUSTOMER: "customer_unpaid_invoices_by_customer",
  CUSTOMER_UNPAID_INVOICES_COUNT_BY_CUSTOMER:
    "customer_unpaid_invoices_count_by_customer",
  CUSTOMER_UNPAID_INVOICES_RESET: "customer_unpaid_invoices_reset",
  CUSTOMER_PAID_INVOICES_BY_CUSTOMER: "customer_paid_invoices_by_customer",
  CUSTOMER_PAID_INVOICES_COUNT_BY_CUSTOMER:
    "customer_paid_invoices_count_by_customer",
  CUSTOMER_PAID_INVOICES_RESET: "customer_paid_invoices_reset",
  FETCH_BILLING_ADDRESSES: "fetch_billing_address",
  FETCH_SHIPPING_ADDRESSES: "fetch_shipping_address",
  ADD_BILLING_ADDRESS: "add_billing_address",
  ADD_SHIPPING_ADDRESS: "add_shipping_address",
  UPDATE_BILLING_ADDRESS: "update_billing_address",
  UPDATE_SHIPPING_ADDRESS: "update_shipping_address",
  DELETE_BILLING_ADDRESS: "delete_billing_address",
  DELETE_SHIPPING_ADDRESS: "delete_shipping_address",
  FETCH_CONTACTS: "fetch_contacts",
  ADD_CONTACT: "add_contact",
  UPDATE_CONTACT: "update_contact",
  DELETE_CONTACT: "delete_contact",
  FETCH_USERS: "fetch_users",
  FETCH_ACTIVITY_PRIORITIES: "fetch_activity_priorities",
  ADD_ACTIVITY_PRIORITIES: "add_activity_priorities",
  DELETE_ACTIVITY_PRIORITIES: "delete_activity_priorities",
  FETCH_ACTIVITY_STATUS: "fetch_activity_status",
  ADD_ACTIVITY_STATUS: "add_activity_status",
  DELETE_ACTIVITY_STATUS: "delete_activity_status",
  FETCH_ACTIVITY_TYPES: "fetch_activity_types",
  ADD_ACTIVITY_TYPES: "add_activity_types",
  DELETE_ACTIVITY_TYPES: "delete_activity_types",
  FETCH_TRANSACTIONS: "fetch_transactions",
  FETCH_TRANSACTIONS_COUNT: "fetch_transactions_count",
  FETCH_SALES_ORDER_TRANSACTIONS: "fetch_sales_order_transactions",
  FETCH_RFQ_TRANSACTIONS: "fetch_rfq_transactions",
  FETCH_RFQ_TRANSACTIONS_COUNT: "fetch_rfq_transactions_count",
  FETCH_SQ_TRANSACTIONS: "fetch_sq_transactions",
  FETCH_SQ_TRANSACTIONS_COUNT: "fetch_sq_transactions_count",
  FETCH_SALES_ORDER_TRANSACTIONS_COUNT: "fetch_sales_order_transactions_count",
  FETCH_INVOICE_LIST: "fetch_invoice_list",
  FETCH_INVOICE_LIST_COUNT: "fetch_invoice_list_count",
  FETCH_CREDIT_MEMO_LIST: "fetch_credit_memo_list",
  FETCH_CREDIT_MEMO_LIST_COUNT: "fetch_credit_momo_list_count",
  FETCH_PAYMENT_LIST: "fetch_payment_list",
  FETCH_PAYMENT_LIST_COUNT: "fetch_payment_list_count",
  TRANSACTIONS_RESET: "transactions_reset",
  TRANSACTIONS_RFQ_RESET: "transaction_rfq_rest",
  TRANSACTIONS_SQ_RESET: "transaction_sq_rest",
  PAYMENT_TRANSACTIONS_RESET: "payments_reset",
  INVOICE_TRANSACTIONS_RESET: "invoice_reset",
  SALES_ORDER_TRANSACTIONS_RESET: "sales_order_reset",
  CREDIT_MEMO_TRANSACTIONS_RESET: "credit_memo_reset",
  FETCH_COMMUINACTIONS: "fetch_communications",
  COMMUNICATION_ADDED: "communication_added",
  COMMUNICATION_DELETED: "communication_deleted",
  ACTIVITY_ADDED: "activity_added",
  RESET_COMMUNICATIONS: "reset_communications",
  RESET_ACTIVITIES: "reset_activites",
  RESET_CONTACTS: "reset_contacts",
  RESET_ADDRESS: "reset_address",
  FETCH_ACTIVITIES: "fetch_activities",
  DELETE_ACTIVITY: "delete_activity",
  FETCH_ADDRESSES: "fetch_addresses",
  FETCH_TASKS: "fetch_tasks",
  ADD_UPDATE_TASK: "add_update_task",
  DELETE_TASK: "delete_task",
  ADD_CUSTOMER_BO_LOCATION: "add_customer_bo_location",
  DELETE_CUSTOMER_BO_LOCATION: "delete_customer_bo_location",
  ADD_CUSTOMER_BO_CONTACT: "add_customer_bo_contact",
  DELETE_CUSTOMER_BO_CONTACT: "delete_customer_bo_contact",
  MEMBER_COUNT: "member_count",
  CUSTOMER_INVOICE_LEDGER_SUMMARY_LIST: "supplier_invoice_ledger_summary_list",
  CUSTOMER_PURCHASE_ITEMS: "customer_purchase_items",
  COUNT_CUSTOMER_PURCHASE_ITEMS: "count_customer_purchase_items",
  CUSTOMER_ACTIVITIES_TRACK_LIST: "customer_activities_track",
  FETCH_CUSTOMER_CONTACTS:"fetch_customer_contacts",
  FETCH_SUPPLIER_CONTACTS:"fetch_supplier_contacts",
  CUSTOMER_TOTAL_ORDER_COUNT_BY_CUSTOMER_ID:"customer_total_order_count_by_customer_id",
  CUSTOMER_PAID_BALANCE: "customer_paid_balance",
  FETCH_SUBSCRIBERS: "fetch_subscribers",
  

};

export const CUSTOMER_LISTING_PAGE = {
  TOTAL_ORDERS: "total_orders",
  OPEN_SO: "open_so",
  UNPAID_BALANCE: "unpaid_balance",
  SALES_REVENUE: "sales_revenue",
  PAID_BALANCE: "paid_balance",

};

export const STUDENT_REGISTRATION_LISTING_PAGE = {
  TOTAL_PARENTS: "total_parents",
  TOTAL_STUDENT: "total_student",
  FEES_DUE: "fees_due",
  FEES_PAID: "fees_paid",
};

export const SUPPLIER_LISTING_PAGE = {
  TOTAL_ORDERS: "total_orders",
  OPEN_PO: "open_po",
  UNPAID_BALANCE: "unpaid_balance",
  TOTAL_PURCHASE: "total_purchase",
};

export const PRODUCT_LISTING_PAGE = {
  OUT_OF_STOCK: "out_of_stock",
  REORDER_STOCK: "reorder_stock",
  LOW_STOCK: "low_stock",
};

export const JOURNAL_ACTION_LIST = {
  JOURNAL_LIST: "journal_list",
  JOURNAL_DETAILS_DATA: "journal_details_data",
};

export const DASHBOARD_ACTION_LIST = {
  SALES_DATA_LIST: "sales_data_list",
  PURCHASE_DATA_LIST: "purchase_data_list",
  TOP_PURCHASE_SUPPLIER: "top_purchase_supplier",
  TOP_SALES_CUSTOMER: "top_sales_customer",
  TOP_PAYMENT_CUSTOMER: "top_payment_customer",
  TOP_PAYMENT_SUPPLIER: "top_payment_supplier",
  FASTEST_MOVING_PRODUCTS: "fastes_moving_products",
  CUSTOMER_UNPAID_INVOICES: "customer_unpaid_invoices",
  SUPPLIER_UNPAID_INVOICES: "supplier_unpaid_invoices",
};

export const DASHBOARD_PAGE_CONSTANTS = {
  CUSTOMER_DUE: "customer_due",
  SUPPLIER_DUE: "supplier_due",
  BALANCE_SHEET: "balance_sheet",
  PROFIT_AND_LOSS: "profit_and_loss",
};

export const LEDGER_ACTION_LIST = {
  NEXT_LEDGER_ACCOUNT_NO: "next_ledger_account_no",
  RESERVED_LEDGER_LIST: "reserved_ledger_list",
  RESET_RESERVED_LEDGER_LIST: "reset_reserved_ledger_list",
  LEDGER_DETAIL_LIST: "ledger_detail_list",
  LEDGER_DETAIL_LIST_COUNT: "ledger_detail_list_count",
  LEDGER_DETAIL_LIST_RESET: "ledger_detail_list",
  DEFAULT_LEDGER_TYPE: {
    SUPPLIER_EXPENSE: "Supplier Expense",
    PURCHASE_DISCOUNT: "Purchase Discount",
    DISCOUNT_RECIEVED: "Discount Received",
    PURCHASE_TAX: "Purchase Input Tax",
    PURCHASE: "Purchase",
    PURCHASES: "Purchases",
    CREDITORS: "Creditors",
    CASH_IN_HAND: "Cash in hand",
    CASH_AT_BANK: "Cash at bank"
  },
  GENERAL_INCOME: "General Income",
  CUSTOMER_EXPENSE: "Customer Expense",
  SALES: "Sales",
  SALES_OUTPUT_TAX: "Sales Output Tax",
  SALES_DISCOUNT: "Sales Discount",
  DEBTORS: "Debtors",
  PURCHASE: "Purchases",
  GENERAL_EXPENSE: "General Expense",
  CREDITORS: "Creditors",
  PURCHASE_INPUT_TAX: "Purchase Input Tax",
  DISCOUNT_RECIEVED: "Discount Received",
  ALL_LEDGER_ACCOUNTS: "all_ledger_accounts",
  ALL_LEDGER_ACCOUNT_GROUPS: "all_ledger_account_groups",
  ALL_CATEGORIES: "all_categories",
  ALL_CATEGORY_GROUP: "all_category_group",
  ALL_PREV_FY_LEDGER_ACCOUNTS: "all_prev_fy_ledger_accounts",

  SHOW_ALL_LEDGER_ACCOUNTS: "show_all_ledger_accounts",
  SHOW_ALL_LEDGER_ACCOUNT_GROUPS: "show_all_ledger_account_groups",
  SHOW_ALL_CATEGORIES: "show_all_categories",
  SHOW_ALL_CATEGORY_GROUP: "show_all_category_group",

  HIDE_ALL_LEDGER_ACCOUNTS: "hide_all_ledger_accounts",
  HIDE_ALL_LEDGER_ACCOUNT_GROUPS: "hide_all_ledger_account_groups",
  HIDE_ALL_CATEGORIES: "hide_all_categories",
  HIDE_ALL_CATEGORY_GROUP: "hide_all_category_group",
  ALL_LEDGER_ACCOUNTS_SUMMARY: "all_ledger_accounts_summary",
  LEDGER_DETAILS: "ledger_details",

};

export const INVENTORY_ACTION_LIST = {
  CATEGORY_LIST: "category_list",
  CATEGORY_LIST_ADDITION: "category_list_addition",
  CATEGORY_LIST_DELETION: "category_list_deletion",
  CATEGORY_LIST_UPDATION: "category_list_updation",
  BRAND_LIST: "brand_list",
  BRAND_LIST_ADDITION: "brand_list_addition",
  BRAND_LIST_DELETION: "brand_list_deletion",
  BRAND_LIST_UPDATION: "brand_list_updation",
  UOM_LIST: "uom_list",
  MANUFACTURE_LIST: "manufacture_list",
  MANUFACTURE_LIST_ADDITION: "manufacture_list_addition",
  MANUFACTURE_LIST_DELETION: "manufacture_list_deletion",
  MANUFACTURE_LIST_UPDATION: "manufacture_list_updation",
  WAREHOUSE_LIST: "warehouse_list",
  WAREHOUSE_LIST_UPDATION: "warehouse_list_updation",
  WAREHOUSE_LIST_ADDITION: "warehouse_list_addition",
  WAREHOUSE_LIST_DELETION: "warehouse_list_deletion",
  ITEM_VARIANT_ATTRIBUTE_LIST: "item_variant_attribute",
  ITEM_VARIANT_ATTRIBUTE_LIST_ADDITION: "item_variant_attribute_addition",
  ITEM_VARIANT_ATTRIBUTE_LIST_DELETION: "item_variant_attribute_deletion",
  SKU_DATA: "sku_data",
  ALL_GST_RATES: "all_gst_rates",
  ALL_LEDGER_ACCOUNTS: "all_ledger_accounts",
  SALES_LEDGER_ACCOUNTS: "sales_ledger_accounts",
  PURCHASE_LEDGER_ACCOUNTS: "purchase_ledger_accounts",
  CGST_TAXES: "cgst_taxes",
  SGST_TAXES: "sgst_taxes",
  IGST_TAXES: "igst_taxes",
  GLOBLE_TAXES: "globle_taxes",
  GLOBLE_TAXES_ADDITION: "globle_taxes_addition",
  GLOBLE_TAXES_DELETION: "globle_taxes_deletion",
  CUSTOMER_TYPE_LIST: "customer_type_list",
  CUSTOMER_LIST: "customer_list",
  STOCK_TRANSFER: "stock_transfer",
  STOCK_TRANSFER_COUNT: "stock_transfer_count",
  TAXJAR_CATEGORY_LIST: "taxjar_category_list",
  STOCK_TRANSFER_DETAIL:"stock_transfer_detail",
  FETCH_RETURN_PRODUCTS: "fetch_return_products",
  FETCH_REPLACE_PRODUCTS: "fetch_replace_products",
  FETCH_CANCEL_PRODUCTS: "fetch_cancel_products",
  OPENING_QUANTITY: "product_opening_quantity",
  OPENING_PRODUCT_QUANTITY_COUNT: "opening_quantity_count",
  OPENING_BALANCE_BY_ID: "opening_quantity_by_Id"
};

export const PRODUCT_ACTION_LIST = {
  PRODUCT_LIST: "product_list",
  PRODUCT_COUNT: "product_count",
  PRODUCT_LIST_PAGE_RESET: "product_list_page_reset",
  PRODUCT_OUT_OF_STOCK_COUNT: "product_out_of_stock_count",
  PRODUCT_REORDER_STOCK: "product_reorder_stock",
  PRODUCT_LOW_STOCK: "product_low_stock",
  PRODUCT_INVENTORY_VALUATION: "product_inventory_valuation",
  PRODUCT_OUT_OF_STOCK_LIST: "product_out_of_stock_list",
  PRODUCT_OUT_OF_STOCK_LIST_PAGE_RESET: "product_out_of_stock_list_page_reset",
  PRODUCT_REORDER_STOCK_LIST: "product_reorder_stock_list",
  PRODUCT_REORDER_STOCK_LIST_PAGE_RESET:
    "product_reorder_stock_list_page_reset",
  PRODUCT_LOW_STOCK_LIST: "product_low_stock_list",
  PRODUCT_LOW_STOCK_LIST_PAGE_RESET: "product_low_stock_list_page_reset",
  PRODUCT_SALES_TRANSACTIONS: "product_sales_transactions",
  PRODUCT_PURCHASE_TRANSACTIONS: "product_purchase_transactions",
  PRODUCT_SALES_TRANSACTIONS_COUNT: "product_sales_transactions_count",
  PRODUCT_PURCHASE_TRANSACTIONS_COUNT: "product_purchase_transactions_count",
  RESET_PRODUCT_SALES_TRANSACTIONS: "reset_product_sales_transactions",
  RESET_PRODUCT_PURCHASE_TRANSACTIONS: "reset_product_purchase_transactions",
  PRODUCT_DETAILS: "product_details",
  RESET_PRODUCT_DETAILS: "reset_product_details",
  PRODUCT_INVENTORY_ITEMBYID: "reset_product_itemById",
  RATING_REVIEW_LIST:"rating_review_list",
  RATING_REVIEW_COUNT:"rating_review_count",
  PUBLISHED_LIST:"published_list",
  PUBLISHED_LIST_COUNT:"published_list_count"
};

export const ECOMMERCE_ACTION_LIST = {
  PUBLISHED_PRODUCT_LIST: "published_product_list",
  PUBLISHED_PRODUCT_COUNT: "published_product_count",
  PUBLISHED_PRODUCT_LIST_PAGE_RESET: "published_product_list_page_reset",
  UNPUBLISHED_PRODUCT_LIST: "unpublished_product_list",
  UNPUBLISHED_PRODUCT_COUNT: "unpublished_product_count",
  UNPUBLISHED_PRODUCT_LIST_PAGE_RESET: "unpublished_product_list_page_reset",
  CLEARANCE_PRODUCT_LIST: "clearance_product_list",
  CLEARANCE_PRODUCT_COUNT: "clearance_product_count",
  CLEARANCE_PRODUCT_LIST_PAGE_RESET: "clearance_product_list_page_reset",
  DEAL_OF_THE_DAY_PRODUCT_LIST: "deal_of_the_day_product_list",
  DEAL_OF_THE_DAY_PRODUCT_COUNT: "deal_of_the_day_product_count",
  DEAL_OF_THE_DAY_PRODUCT_LIST_PAGE_RESET:
    "deal_of_the_day_product_list_page_reset",
  NEW_ARRIVAL_PRODUCT_LIST: "new_arrival_product_list",
  NEW_ARRIVAL_PRODUCT_COUNT: "new_arrival_product_count",
  NEW_ARRIVAL_PRODUCT_LIST_PAGE_RESET: "new_arrival_product_list_page_reset",
  BEST_SELLING_PRODUCT_LIST: "best_selling_product_list",
  BEST_SELLING_PRODUCT_COUNT: "best_selling_product_count",
  BEST_SELLING_PRODUCT_LIST_PAGE_RESET: "best_selling_product_list_page_reset",
  ON_SALE_PRODUCT_LIST: "on_sale_product_list",
  ON_SALE_PRODUCT_COUNT: "on_sale_product_count",
  ON_SALE_PRODUCT_LIST_PAGE_RESET: "on_sale_product_list_page_reset",
  ALL_CATEGORY_LIST: "all_category_list",
  ALL_CATEGORY_COUNT: "all_category_count",
  PUBLISHED_CATEGORY_LIST: "published_category_list",
  PUBLISHED_CATEGORY_COUNT: "published_category_count",
  PUBLISHED_CATEGORY_LIST_PAGE_RESET: "published_category_list_page_reset",
  UNPUBLISHED_CATEGORY_LIST: "unpublished_category_list",
  UNPUBLISHED_CATEGORY_COUNT: "unpublished_category_count",
  UNPUBLISHED_CATEGORY_LIST_PAGE_RESET: "unpublished_category_list_page_reset",
};

export const INTEGRATION = {
  PLAID_LINK_TOKEN: "plaid_link_token",
  PLAID_LINKED_ACCOUNT: "plaid_linked_account",
  LINKED_MARKETPLACE_LIST: "linked_marketplace_list",
  RESET_LINKED_MARKETPLACE_LIST: "reset_linked_marketplace_list",
  LINKED_SHIP_STATIONS: "linked_ship_stations",
  LINKED_EASY_POST: "linked_easy_post",
  BREVO_SETTINGS:"brevo_settings"
};

export const PURCHASE_REQUEST_ACTION_LIST = {
  RFQ_PR_DATA: "rfq_pr_data",
  RESET_RFQ_PR_DATA: "reset_rfq_pr_data",
  PURCHASE_REQUEST_LIST: "purchase_request_list",
  PURCHASE_REQUEST_COUNT: "purchase_request_count",
  PURCHASE_REQUEST_LIST_PAGE_RESET: "purchase_request_list_page_reset",
  PURCHASE_REQUEST_DETAIL: "purchase_request_detail",
  RESET_PURCHASE_REQUEST_DETAIL: "reset_purchase_request_detail",
  NEXT_PURCHASE_REQUEST_NUMBER: "next_purchase_request_number",
  NEXT_PURCHASE_REQUEST_NUMBER_RESET: "next_purchase_request_number_reset",
  PO_PR_DATA: "po_pr_data",
  RESET_PO_PR_DATA: "reset_po_pr_data",
  SO_PR_DATA: "so_pr_data",
  RESET_SO_PR_DATA: "reset_so_pr_data",
  PURCHASE_REQUEST_DRAFT_LIST: "purchase_request_draft_list",
  PURCHASE_REQUEST_DRAFT_COUNT: "purchase_request_draft_count",
  PURCHASE_REQUEST_DRAFT_LIST_PAGE_RESET:
    "purchase_request_draft_list_page_reset",
};

export const RFQ_ACTION_LIST = {
  RFQ_LIST: "rfq_list",
  RFQ_DRAFT_LIST: "rfq_draft_list",
  RFQ_COUNT: "rfq_count",
  RFQ_DRAFT_COUNT: "rfq_draft_count",
  RFQ_LIST_PAGE_RESET: "rfq_list_page_reset",
  RFQ_DETAIL: "rfq_detail",
  RFQ_Data: "rfq_data",
  RESET_RFQ_DETAIL: "reset_rfq_detail",
  RFQ_PRICE_CODES: "rfq_price_codes",
  RFQ_PRICE_CODES_ADDITION: "rfq_price_codes_addition",
  RFQ_PRICE_CODES_DELETION: "rfq_price_codes_deletion",
  RFQ_STATUS_LIST: "rfq_status_list",
  RFQ_STATUS_LIST_ADDITION: "rfq_status_list_addition",
  RFQ_STATUS_LIST_DELETION: "rfq_status_list_deletion",
  NEXT_RFQ_NUMBER: "next_rfq_number",
  NEXT_RFQ_NUMBER_RESET: "next_rfq_number_reset",
};

export const INQUIRY_ACTION_LIST = {
  ENQUIRY_LIST: "enquiry_list",
  ENQUIRY_COUNT: "enquiry_count",
  ENQUIRY_LIST_PAGE_RESET: "enquiry_list_page_reset",
  ENQUIRY_DETAIL: "enquiry_detail",
  ENQUIRY_DATA: "enquiry_data",
  RESET_ENQUIRY_DETAIL: "reset_enquiry_detail",
  ENQUIRY_PRICE_CODES: "enquiry_price_codes",
  ENQUIRY_PRICE_CODES_ADDITION: "enquiry_price_codes_addition",
  ENQUIRY_PRICE_CODES_DELETION: "enquiry_price_codes_deletion",
  ENQUIRY_STATUS_LIST: "enquiry_status_list",
  ENQUIRY_STATUS_LIST_ADDITION: "enquiry_status_list_addition",
  ENQUIRY_STATUS_LIST_DELETION: "enquiry_status_list_deletion",
  NEXT_ENQUIRY_NUMBER: "next_enquiry_number",
  NEXT_ENQUIRY_NUMBER_RESET: "next_enquiry_number_reset",
  INQUIRY_DRAFT_LIST: "inquiry_draft_list",
  INQUIRY_DRAFT_COUNT: "inquiry_draft_count",
  INQUIRY_DRAFT_LIST_PAGE_RESET: "inquiry_draft_list_page_reset",
};

export const PURCHASE_ORDER_ACTION_LIST = {
  RESET_RFQ_PO_DATA: "reset_rfq_po_data",
  RFQ_PO_DATA: "rfq_po_data",
  PURCHASE_ORDER_LIST: "purchase_order_list",
  PURCHASE_ORDER_COUNT: "purchase_order_count",
  PURCHASE_ORDER_LIST_PAGE_RESET: "purchase_order_list_page_reset",
  PURCHASE_ORDER_DETAIL: "purchase_order_detail",
  RESET_PO_DETAIL: "reset_po_detail",
  NEXT_PO_NUMBER: "next_po_number",
  NEXT_PO_NUMBER_RESET: "next_po_number_reset",
  PO_INBOUND_DELIVERY_LIST: "po_inbound_delivery_list",
  PO_INBOUND_DELIVERY_LIST_RESET: "po_inbound_delivery_list_reset",
  PURCHASE_ORDER_DRAFT_LIST: "purchase_order_draft_list",
  PURCHASE_ORDER_DRAFT_COUNT: "purchase_order_draft_count",
  PURCHASE_ORDER_DRAFT_LIST_PAGE_RESET: "purchase_order_draft_list_page_reset",
};

export const INBOUND_DELIVERY_ACTION_LIST = {
  INBOUND_DELIVERY_LIST: "inbound_delivery_list",
  INBOUND_DELIVERY_COUNT: "inbound_delivery_count",
  INBOUND_DELIVERY_PO_LIST: "inbound_delivery_po_list",
  INBOUND_DELIVERY_LIST_PAGE_RESET: "inbound_delivery_list_page_reset",
  INBOUND_DELIVERY_DATA: "inbound_delivery_data",
  RESET_INBOUND_DELIVERY_DATA: "reset_inbound_delivery_data",
  RESET_ID_PO_DATA: "reset_id_po_data",
  ID_PO_DATA: "id_po_data",
  NEXT_ID_NUMBER: "next_id_number",
  NEXT_ID_NUMBER_RESET: "next_id_number_reset",
  INBOUND_DELIVERY_DRAFT_LIST: "inbound_delivery_draft_list",
  INBOUND_DELIVERY_DRAFT_COUNT: "inbound_delivery_draft_count",
  INBOUND_DELIVERY_DRAFT_LIST_PAGE_RESET:
    "inbound_delivery_draft_list_page_reset",
};

export const PURCHASE_INVOICE_ACTION_LIST = {
  PURCHASE_INVOICE_LIST: "purchase_invoice_list",
  PURCHASE_INVOICE_COUNT: "purchase_invoice_count",
  PURCHASE_INVOICE_LIST_PAGE_RESET: "purchase_invoice_list_page_reset",
  PURCHASE_INVOICE_LIST_BY_SUPPLIER_ID: "purchase_invoice_list_by_supplier_id",
  PURCHASE_INVOICE_DETAIL: "purchase_invoice_detail",
  RESET_PURCHASE_INVOICE_DETAIL: "reset_purchase_invoice_detail",
  PURCHASE_INVOICE_PO_DETAIL: "purchase_invoice_po_detail",
  RESET_PO_INVOICE_DATA: "reset_po_invoice_data",
  NEXT_PURCHASE_INVOICE_NUMBER: "next_purchase_invoice_number",
  NEXT_PURCHASE_INVOICE_NUMBER_RESET: "next_purchase_invoice_number_reset",
  ID_INVOICE_DATA: "id_invoice_data",
  RESET_ID_INVOICE_DATA: "reset_id_invoice_data",
  PURCHASE_INVOICE_DRAFT_LIST: "purchase_invoice_draft_list",
  PURCHASE_INVOICE_DRAFT_COUNT: "purchase_invoice_draft_count",
  PURCHASE_INVOICE_DRAFT_LIST_PAGE_RESET:
    "purchase_invoice_draft_list_page_reset",
  AVAILABLE_DEBITS: "available_debits",
  AVAILABLE_DEBITS_RESET: "available_debits_reset",
  AVAILABLE_DEBITS_LIST: "available_debits_list",
  AVAILABLE_DEBITS_LIST_RESET: "available_debits_list_reset",
};

export const SUPPLIER_PAYMENT_ACTION_LIST = {
  SUPPLIER_PAYMENT_LIST: "supplier_payment_list",
  SUPPLIER_PAYMENT_COUNT: "supplier_payment_count",
  SUPPLIER_PAYMENT_LIST_PAGE_RESET: "supplier_payment_list_page_reset",
  SUPPLIER_PAYMENT_DETAIL: "supplier_payment_detail",
  NEXT_SUPPLIER_PAYMENT_NUMBER: "next_supplier_payment_number",
  NEXT_SUPPLIER_PAYMENT_NUMBER_RESET: "next_supplier_payment_number_reset",
};

export const DEBIT_MEMO_ACTION_LIST = {
  DEBIT_MEMO_LIST: "debit_memo_list",
  DEBIT_MEMO_COUNT: "debit_memo_count",
  DEBIT_MEMO_LIST_PAGE_RESET: "debit_memo_list_page_reset",
  DEBIT_MEMO_DETAIL: "debit_memo_detail",
  DEBIT_MEMO_DETAIL_RESET: "debit_memo_detail_reset",
  NEXT_DEBIT_MEMO_NUMBER: "next_debit_memo_number",
  NEXT_DEBIT_MEMO_NUMBER_RESET: "next_debit_memo_number_reset",
};

export const HIERARCHY_ACTION_LIST = {
  HIERARCHY_LIST: "hierarchyList",
  HIERARCHY_LIST_DELETION: "hierarchy_list_deletion",
  HIERARCHY_LIST_BY_LEVEL: "hierarchy_list_by_level",
  RESET_HIERARCHY_LIST_BY_LEVEL: "reset_hierarchy_list_by_level",
  PARENT_HIERARCHY_LIST_BY_LEVEL: "parent_hierarchy_list_by_level",
  RESET_PARENT_HIERARCHY_LIST_BY_LEVEL: "reset_parent_hierarchy_list_by_level",
  CHILD_HIERARCHY_LIST_BY_LEVEL: "child_hierarchy_list_by_level",
  RESET_CHILD_HIERARCHY_LIST_BY_LEVEL: "reset_child_hierarchy_list_by_level",
};

export const PROJECT_ACTION_LIST = {
  PROJECT_LIST: "projects",
  PROJECT_COUNT: "project_count",
  RESET_PROJECT_LIST: "reset_project_list",
  PROJECT_DELETION: "project_list_deletion",
  PROJECT_DETAIL_LIST: "project_detail_list",
  PROJECT_BY_ID: "project_by_id",
  PROJECT_WITH_SUBPROJECT: "project_with_subproject",
};

export const SUPPLIER_EXPENSE_ACTION_LIST = {
  SUPPLIER_EXPENSE_LIST: "supplier_expense_list",
  SUPPLIER_EXPENSE_COUNT: "supplier_expense_count",
  SUPPLIER_EXPENSE_LIST_PAGE_RESET: "supplier_expense_list_page_reset",
  SUPPLIER_EXPENSE_DETAIL: "supplier_expense_detail",
};

export const BUSINESS_EXPENSE_ACTION_LIST = {
  BUSINESS_EXPENSE_LIST: "business_expense_list",
  BUSINESS_EXPENSE_COUNT: "business_expense_count",
  BUSINESS_EXPENSE_LIST_PAGE_RESET: "business_expense_list_page_reset",
  BUSINESS_EXPENSE_DETAIL: "business_expense_detail",
  BUSINESS_EXPENSE_DETAIL_RESET: "business_expense_detail_reset",
  NEXT_EXPENSE_NUMBER: "next_expense_number",
  NEXT_EXPENSE_NUMBER_RESET: "next_expense_number_reset",
};

export const SQ_ACTION_LIST = {
  SQ_LIST: "sq_list",
  RESET_SQ_DATA: "reset_sq_data",
  SALES_QUOTE_COUNT: "sales_quote_count",
  SQ_LIST_PAGE_RESET: "sq_list_page_reset",
  SQ_DETAIL: "sq_detail",
  NEXT_SQ_NUMBER: "next_sq_number",
  NEXT_SQ_NUMBER_RESET: "next_sq_number_reset",
  SQ_DRAFT_LIST: "sq_draft_list",
  SQ_DRAFT_COUNT: "sq_draft_count",
  SO_UN_SHIPPPED_LIST_TRY: "so_un_shipped_list_try",
  SQ_DRAFT_LIST_PAGE_RESET: "sq_draft_list_page_reset",
  
};

export const SO_ACTION_LIST = {
  SHOW_SO_LISTING_LOADER: "show_so_listing_loader",
  HIDE_SO_LISTING_LOADER: "hide_so_listing_loader",
  SO_LIST: "so_list",
  SALES_ORDER_COUNT: "sales_order_count",
  RESET_SO_DATA_BY_CUSTOMER_ID: "reset_so_data_by_customer_id",
  SO_DATA_BY_CUSTOMER_ID: "so_data_by_customer_id",
  SO_LIST_PAGE_RESET: "so_list_page_reset",
  SO_DETAIL: "so_detail",
  SO_SOURCES: "so_sources",
  SO_SOURCES_ADDITION: "so_sources_addition",
  SO_SOURCES_DELETION: "so_sources_deleltion",
  SO_PACKAGE_DATA: "so_package_data",
  SO_PACKAGE_DATA_RESET: "so_package_data_reset",
  SO_SHIPMENT_LIST: "so_shipment_list",
  SO_PACKAGE_LIST: "so_package_list",
  SO_UN_SHIPPPED_LIST: "so_un_shipped_list",
  FRIEGHT_LIST: "frieght_list",
  ADD_FRIEGHT_TYPE: "add_cfrieght_type",
  DELETE_FRIEGHT_TYPE: "add_frieght_type",
  CARRIER_LIST: "carrier_list",
  ADD_CARRIER_NAME: "add_carrier_name",
  DELETE_CARRIER_NAME: "delete_carrier_name",
  SO_INVOICE_DATA: "so_invoice_data",
  RESET_SO_DATA: "RESET_SO_DATA",
  SQ_SO_DATA: "sq_so_data",
  RESET_SO_INVOICE_DATA: "reset_so_invoice_data",
  RESET_SQ_SO_DATA: "reset_sq_so_data",
  SO_PACKAGE_DETAIL: "so_package_detail",
  RESET_SO_PACKAGE_DETAIL: "reset_so_package_detail",
  SO_DELIVERY_NOTES_DETAIL: "so_delivery_notes_detail",
  SO_DELIVERY_NOTES_DETAIL_RESET: "so_delivery_notes_detail_reset",
  SO_SHIPPING_INVOICE_DETAIL: "so_shipping_invoice_detail",
  NEXT_SO_NUMBER: "next_so_number",
  NEXT_SO_NUMBER_RESET: "next_so_number_reset",
  SO_UNFULFILLED_LIST: "so_unfulfilled_list",
  SO_UNFULFILLED_LIST_RESET: "so_unfulfilled_list_reset",
  SHIPMENT_LIST_FOR_INVOICE: "shipment_list_for_invoice",
  SHIPMENT_LIST_FOR_INVOICE_RESET: "shipment_list_for_invoice_reset",
  SO_DRAFT_LIST: "so_draft_list",
  SO_DRAFT_COUNT: "so_draft_count",
  SO_DRAFT_LIST_PAGE_RESET: "so_draft_list_page_reset",
  SO_UNPACKED_LIST: "so_unpacked_list",
  SO_UNPACKED_LIST_RESET: "so_unpacked_list_reset",
  SO_ONLINE_LIST:"so_online_list",
  SALES_ORDER_ONLINE_COUNT:"sales_order_online_count",
  ECOM_DETAILS:"ecom_details",
  CANCELLED_SO_LIST: "cancelled_so_list",
  CANCELLED_SO_COUNT: "cancelled_so_count",
  AMEND_SO_LIST: "amend_so_list",
  AMEND_SO_INVOICE_DATA: "amend_so_invoice_data",
  SO_UN_SHIPPPED_LIST_RESET: "so_un_shipped_list_reset",
  SO_DRAFT_LIST_PAGE_TRY: "so_draft_list_page_try",
  SO_UNSHIPPED_LIST_PAGE_RESET: "so_unshippid_list_page_reset",
};

export const SALES_COMPLAINT_ACTION_LIST = {
  SALES_COMPLAINT_LIST: "sales_complaint_list",
  SALES_COMPLAINT_COUNT: "sales_complaint_count",
  SALES_COMPLAINT_LIST_PAGE_RESET: "sales_complaint_list_page_reset",
  SALES_COMPLAINT_TYPE_LIST: "sales_complaint_type_list",
  SALES_COMPLAINT_TYPE_LIST_ADDITION: "sales_complaint_type_list_addition",
  SALES_COMPLAINT_TYPE_LIST_DELETION: "sales_complaint_type_list_deletion",
  NEXT_COMPLAINT_NUMBER: "next_complaint_number",
  NEXT_COMPLAINT_NUMBER_RESET: "next_complaint_number_reset",
  SALES_COMPLAINT_DATA:"sales_complaint_data",
  COMPLAINT_COUNT:"complaint_count"
};

export const SALES_INVOICE_ACTION_LIST = {
  E_INVOICE_LIST: "e_invoice_list",
  SALES_INVOICE_LIST: "sales_invoice_list",
  PROFORMA_INVOICE_LIST: "proforma_invoice_list",
  PROFORMA_INVOICE_COUNT: "proforma_invoice_count",
  SALES_INVOICE_COUNT: "sales_invoice_count",
  SALES_INVOICE_LIST_PAGE_RESET: "sales_invoice_list_page_reset",
  PRODUCT_LIST: "product_list",
  SALES_INVOICE_BY_SO_ID: "sales_invoice_by_so_id",
  CUSTOMER_LIST: "sales_customer_list",
  SALES_INVOICE_DETAIL: "sales_invoice_detail",
  SALES_INVOICE_DETAIL_RESET: "sales_invoice_detail_reset",
  SALES_E_INVOICE_DETAIL: "sales_e_invoice_detail",
  SALES_E_INVOICE_DETAIL_RESET: "sales_e_invoice_detail_reset",
  NEXT_SALES_INVOICE_NUMBER: "next_sales_invoice_number",
  NEXT_SALES_INVOICE_NUMBER_RESET: "next_sales_invoice_number_reset",
  SALES_INVOICE_DRAFT_LIST: "sales_invoice_draft_list",
  SALES_INVOICE_DRAFT_COUNT: "sales_invoice_draft_count",
  SALES_INVOICE_DRAFT_LIST_PAGE_RESET: "sales_invoice_draft_list_page_reset",
  AVAILABLE_CREDITS: "available_credits",
  AVAILABLE_CREDITS_RESET: "available_credits_reset",
  AVAILABLE_CREDIT_LIST: "available_credits_list",
  AVAILABLE_CREDIT_LIST_RESET: "available_credits_list_reset",
  RECURRING_INVOICE_SETUP: "recurring_invoice_setup",
  RECURRING_INVOICE_SETUP_RESET: "recurring_invoice_setup_reset",
  RECURRING_INVOICE_LIST: "recurring_invoice_list",
  RECURRING_INVOICE_COUNT: "recurring_invoice_count",
  RECURRING_INVOICE_LIST_PAGE_RESET: "recurring_invoice_list_page_reset",
  INVOICE_REMINDER_SETUP: "invoice_reminder_setup",
  INVOICE_REMINDER_SETUP_RESET: "invoice_reminder_setup_reset",
  INVOICE_REMINDER_LIST: "invoice_reminder_list",
  INVOICE_REMINDER_COUNT: "invoice_reminder_count",
  INVOICE_REMINDER_LIST_PAGE_RESET: "invoice_reminder_list_page_reset",
  INVOICE_LIST_BY_MASTER_ID: "invoice_list_by_master_id",
  INVOICE_LIST_BY_MASTER_ID_RESET: "invoice_list_by_master_id_reset",
  CUSTOMER_PROFORMA_INVOICE_LIST: "customer_proforma_invoice_list",
  INVOICE_PROFORMA_DETAILS: "invoice_proforma_details",
  INVOICE_PROFORMA_LIST: "invoice_proforma_list",
  POST_PAYMENT_DATA:"post_payment_data",
 
};

export const CUSTOMER_PAYMENT_ACTION_LIST = {
  CUSTOMER_PAYMENT_LIST: "customer_payment_list",
  CUSTOMER_PAYMENT_CASHFLOW: "customer_payment_cashflow",
  CUSTOMER_PAYMENT_COUNT: "customer_payment_count",
  RECENT_10_PAYMENTS: "recent_10_payments",
  CUSTOMER_PAYMENT_LIST_PAGE_RESET: "customer_payment_list_page_reset",
  CUSTOMER_PAYMENT_DETAIL: "customer_payment_detail",
  NEXT_PAYMENT_NUMBER: "next_payment_number",
  NEXT_PAYMENT_NUMBER_RESET: "next_payment_number_reset",
  CUSTOMER_SUBSCRIPTION_LIST: "customer_subscription_list",
  CUSTOMER_SUBSCRIPTION_COUNT: "customer_subscription_count",
  CUSTOMER_SUBSCRIPTION_LIST_PAGE_RESET: "customer_subscription_list_page_reset",
  ALL_CUSTOMER_PAYMENTS: "all_customer_payments",
};

export const PACKAGE_ACTION_LIST = {
  PACKAGE_LIST: "package_list",
  PACKAGE_COUNT: "package_count",
  PACKAGE_LIST_PAGE_RESET: "package_list_page_reset",
  NEXT_PACKAGE_NUMBER: "next_package_number",
  NEXT_PACKAGE_NUMBER_RESET: "next_package_number_reset",
};

export const SHIPMENT_ACTION_LIST = {
  SHIPMENT_LIST: "shipment_list",
  SHIPMENT_DATA: "shipment_data",
  RESET_SHIPMENT_DATA: "reset_shipment_data",
  SHIPMENT_COUNT: "shipment_count",
  SHIPMENT_LIST_PAGE_RESET: "shipment_list_page_reset",
  NEXT_SHIPMENT_NUMBER: "next_shipment_number",
  NEXT_SHIPMENT_NUMBER_RESET: "next_shipment_number_reset",
  CARRIAR_LIST: "carriar_list",
  CARRIARS_PRICE_LIST: "carriars_price_list",
  LOADING_CARRIAR_LIST: "loading_carriar_list",
  BUY_SHIPMENT: "buy_shipment",
  BOUGHT_SHIPMENT: "bought_shipment",
  CARRIER_DETAILS: "carrier_details",
  SHIPROCKET_CARRIER_DETAILS: "shiprocket_carrier_details",
  SHIPROCKET_ORDER_DETAILS: "shiprocket_order_details",
  SHIPROCKET_CHANNELS: "shiprocket_channels",
};

export const CREDIT_MEMO_ACTION_LIST = {
  CREDIT_MEMO_LIST: "credit_memo_list",
  CREDIT_MEMO_COUNT: "credit_memo_count",
  CREDIT_MEMO_LIST_PAGE_RESET: "credit_memo_list_page_reset",
  COST_CENTER_LIST: "cost_center_list",
  CREDIT_MEMO_DETAIL: "credit_memo_detail",
  CREDIT_MEMO_DETAIL_RESET: "credit_memo_detail_reset",
  NEXT_CREDIT_MEMO_NUMBER: "next_credit_memo_number",
  NEXT_CREDIT_MEMO_NUMBER_RESET: "next_credit_memo_number_reset",
};

export const CUSTOMER_EXPENSE_ACTION_LIST = {
  CUSTOMER_EXPENSE_LIST: "customer_expense_list",
  CUSTOMER_EXPENSE_COUNT: "customer_expense_count",
  CUSTOMER_EXPENSE_LIST_PAGE_RESET: "customer_expense_list_page_reset",
  CUSTOMER_EXPENSE_DETAIL: "customer_expense_detail",
};

export const SUPPLIER_ACTION_LIST = {
  SUPPLIERS_LIST: "suppliers",
  SUPPLIER_COUNT: "supplier_count",
  SUPPLIERS_PAGE_RESET: "suppliers_page_reset",
  SUPPLIER_OPEN_ORDER_COUNT: "supplier_open_order_count",
  SUPPLIER_OPEN_PO_COUNT: "supplier_open_po_count",
  SUPPLIER_UNPAID_BALANCE: "supplier_unpaid_balance",
  SUPPLIER_TOTAL_PURCHASE: "supplier_total_purchase",
  SUPPLIER_TOTAL_ORDER: "supplier_total_order",
  SUPPLIER_TOTAL_ORDER_COUNT_BY_R_ID: "supplier_total_order_count_by_r_id",
  SUPPLIER_TOTAL_ORDER_LIST_PAGE_RESET: "total_order_list_reset",
  SUPPLIER_OPEN_ORDER: "supplier_open_order",
  SUPPLIER_OPEN_ORDER_COUNT_BY_SUPPLIER_ID:
    "supplier_open_order_count_by_supplier_id",
  SUPPLIER_OPEN_ORDER_PAGE_RESET: "supplier_open_order_page_reset",
  SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER: "supplier_unpaid_invoices_by_supplier",
  SUPPLIER_UNPAID_INVOICES_COUNT_BY_SUPPLIER:
    "supplier_unpaid_invoices_count_by_supplier",
  SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER_RESET:
    "supplier_unpaid_invoices_by_supplier_reset",
  SUPPLIER_PAID_INVOICES_BY_SUPPLIER: "supplier_paid_invoices_by_supplier",
  SUPPLIER_PAID_INVOICES_COUNT_BY_SUPPLIER:
    "supplier_paid_invoices_count_by_supplier",
  SUPPLIER_PAID_INVOICES_BY_SUPPLIER_RESET:
    "supplier_paid_invoices_by_supplier_reset",
  SUPPLIER_FETCH_TRANSACTIONS: "supplier_fetch_transactions",
  SUPPLIER_FETCH_TRANSACTIONS_COUNT: "supplier_fetch_transactions_count",
  SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS:
    "supplier_fetch_purchase_order_transactions",
  SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS_COUNT:
    "supplier_fetch_purchase_order_transactions_count",
  SUPPLIER_FETCH_INVOICE_LIST: "supplier_fetch_invoice_list",
  SUPPLIER_FETCH_INVOICE_LIST_COUNT: "supplier_fetch_invoice_list_count",
  SUPPLIER_FETCH_DEBIT_MEMO_LIST: "supplier_fetch_debit_memo_list",
  SUPPLIER_FETCH_DEBIT_MEMO_LIST_COUNT: "supplier_fetch_debit_momo_list_count",
  SUPPLIER_FETCH_PAYMENT_LIST: "supplier_fetch_payment_list",
  SUPPLIER_FETCH_PAYMENT_LIST_COUNT: "supplier_fetch_payment_list_count",
  SUPPLIER_TRANSACTIONS_RESET: "supplier_transactions_reset",
  SUPPLIER_PAYMENT_TRANSACTIONS_RESET: "supplier_payments_reset",
  SUPPLIER_INVOICE_TRANSACTIONS_RESET: "supplier_invoice_reset",
  SUPPLIER_PURCHASE_ORDER_TRANSACTIONS_RESET: "supplier_purchase_order_reset",
  SUPPLIER_DEBIT_MEMO_TRANSACTIONS_RESET: "supplier_debit_memo_reset",
  SUPPLIER_FETCH_COMMUINACTIONS: "supplier_fetch_communications",
  SUPPLIER_ADD_UPDATE_COMMUINACTIONS: "supplier_add_update_communications",
  SUPPLIER_DELETE_COMMUINACTIONS: "delete_supplier_communications",
  SUPPLIER_FETCH_ACTIVITIES: "supplier_fetch_activities",
  SUPPLIER_ADD_UPDATE_ACTIVITIES: "supplier_add_update_activities",
  SUPPLIER_DELETE_ACTIVITIES: "delete_supplier_activities",
  SUPPLIER_FETCH_ADDRESSES: "supplier_fetch_addresses",
  SUPPLIER_FETCH_TASKS: "supplier_fetch_tasks",
  SUPPLIER_FETCH_CONTACTS: "supplier_fetch_contacts",
  SUPPLIER_ADD_CONTACTS: "supplier_add_contacts",
  SUPPLIER_DELETE_CONTACTS: "supplier_delete_contacts",
  ADD_UPDATE_TASK: "supplier_add_update_task",
  DELETE_TASK: "supplier_delete_task",
  ADD_SUPPLIER_BO_LOCATION: "add_supplier_bo_location",
  DELETE_SUPPLIER_BO_LOCATION: "delete_supplier_bo_location",
  SUPPLIER_FETCH_BILLING_ADDRESSES: "supplier_fetch_billing_address",
  SUPPLIER_FETCH_SHIPPING_ADDRESSES: "supplier_fetch_shipping_address",
  SUPPLIER_ADD_BILLING_ADDRESS: "supplier_add_billing_address",
  SUPPLIER_ADD_SHIPPING_ADDRESS: "supplier_add_shipping_address",
  SUPPLIER_UPDATE_BILLING_ADDRESS: "supplier_update_billing_address",
  SUPPLIER_UPDATE_SHIPPING_ADDRESS: "supplier_update_shipping_address",
  SUPPLIER_DELETE_BILLING_ADDRESS: "supplier_delete_billing_address",
  SUPPLIER_DELETE_SHIPPING_ADDRESS: "supplier_delete_shipping_address",
  ADD_SUPPLIER_BO_CONTACT: "add_supplier_bo_contact",
  DELETE_SUPPLIER_BO_CONTACT: "delete_supplier_bo_contact",
  SUPPLIER_LEDGER_LIST: "supplier_ledger_list",
  RESET_SUPPLIER_LEDGER_LIST: "reset_supplier_ledger_list",
  SUPPLIER_SUMMARY: "supplier_summary",
  SUPPLIER_INVOICE_LEDGER_SUMMARY_LIST: "supplier_invoice_ledger_summary_list",
  LANDED_COST_SETUP_LIST: "landed_cost_setup_list",
  LANDED_COST_LIST: "landed_cost_list",
  SUPPLIER_TOTAL_ORDER_COUNT_BY_SUPPLIER_ID:"supplier_total_order_count_by_supplier_id"
};

export const MODAL_ACTIONS = {
  SHOW_MODAL: "show_modal",
  HIDE_MODAL: "hide_modal",
  MODAL_FORM_DATA_UPDATE: "modal_form_data",
  PUSH_STACK_MODAL: "push_stack_modal",
  POP_STACK_MODAL: "pop_stack_modal",
  CLEAR_STACK_MODAL: "clear_stack_modal",
};

export const MODAL_TYPE = {
  PAYMENT_TERM: "Payment Term",
  CUSTOMER_SOURCE: "Customer Source",
  CUSTOMER_TYPE: "Customer Type",
  INDUSTRY: "Industry",
  SUPPLIER_SOURCE: "Supplier Source",
  SUPPLIER_TYPE: "Supplier Type",
  SO_SOURCE: "SO Source",
  UOM: "UOM",
  PRODUCT_CATEGORY: "Product Category",
  PRODUCT_BRAND: "Product Brand",
  BRAND_NAME: "Brand Name",
  PRODUCT_MANUFACTURER: "Product Manufacturer",
  DEPARTMENT: "Department",
  WAREHOUSE: "Warehouse",
  PROJECT: "Project",
  BILLING_ADDRESS: "Billing Address",
  SHIPPING_ADDRESS: "Shipping Address",
  RELATIONSHIP_BILLING_ADDRESS: "Relationship Billing Address",
  RELATIONSHIP_SHIPPING_ADDRESS: "Relationship Shipping Address",
  EMPLOYEE: "Employee",
  CONTACT: "Contact",
  CONTACT_PARENT: "Contact Parent",
  PRICE_LIST: "Price List",
  DOCUMENT_NAME: "Document Name",
  NEW_TAX: "New Tax",
  ATTRIBUTE: "Attribute",
  RFQ_STATUS: "RFQ Status",
  RFQ_PRICE_CODES: "RFQ Price Code",
  SALUTATION: "Salutation",
  UPDATE_STATUS: "update_status",
  ACTIVITY_STATUS: "Activity Status",
  ACTIVITY_PRIORITY: "Activity Priority",
  ACTIVITY_TYPE: "Activity Type",
  CARRIER_NAME: "Carrier Name",
  FREIGHT_TYPE: "Freight Type",
  EXPENSE_TYPE: "Expense Type",
  SHIPPING_INSTRUCTION: "Shipping Instruction",
  JOB_TITLE: "Job Title",
  TAX_IDENTIFICATION: "Tax Identification",
  NEW_TAX_IDENTIFICATION: "New Tax Identification",
  NEW_TAX_IDENTIFICATION_CUSTOMER: "New Tax Identification Customer",
  NEW_TAX_IDENTIFICATION_SUPPLIER: "New Tax Identification Supplier",
  DELEGATE_APPROVER: "Delegate Approver",
  REJECT_APPROVAL: "Reject Approval",
  QUALITY_CHECK: "Quality Check",
  USER_STATUS: "User Status",
  STATUS_NAME: "Status Name",
  ACTUAL_DELIVERY_DATE: "Actual Delivery Date",
  INCOTERM: "Incoterm",
  SALES_COMPLAINT_TYPE: "Sales Complaint Type",
  BUSINESS_TYPE: "Business Type",
  SELECT_VARIANT: "Select Variant",
  SHIPPING_LABEL: "Shipping Label",
  CUSTOM_NAME: "Custom Name",
  ADD_MARKETPLACE_COUNTRY: "Add Marketplace Country",
  ADD_MARKETPLACE_STATE_TAX: "Add Marketplace State Tax",
  SUBACCOUNT_PHONE_NUMBER: "Phone Number",
  COURSE_TERM: "Course Term",
  JOB_ROLE: "Job Role",
  SUBCOURSE: "subcourse",
  NEW_STATUS: "New Status",
  SCHOOL_SESSION: "School Session",
  CLASS_SCHEDULE: "Class Schedule",
  PARENT_INFO: "Parent Info",
  EMPLOYEE_TYPE: "Employee Type",
  ASSIGNMENTS_GROUP: "Assignment Group",
  SYSTEM_STATUS: "System Status",
  NEW_HSN: "New HSN",
  FINANCIAL_YEAR: "financial_year",
  FOOTER: "footer",
  CRM_STATUS: "crm_Status",
  ADD_DELIVERY_TIME: "Add Delivery Time",
  CRM_CAMPAIGN_TYPE: "Add Campaign Type",
  CRM_CAMPAIGN_STATUS: "Add Campaign Status",
  E_INVOICE: "E-Invoice",
  RETURN_REASON:"Return Reason",
  SALES_COMPLAINT_STATUS:"sales_complaint_status",
  SHIPPING_LABEL_SHIPROCKET: "shipping_label_shiprocket",
};

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  ACCESS_TOKEN_BACKUP: "_access_token",
  EXPIRE_TIME: "expires_in",
  LOGGEDIN_ENTITY_DATA: "logged_in_entity",
  PERMISSION_DATA: "permission_data",
  DEFAULT_SCHOOL_FORM: "default_school_form",
  EMAIL: "email",
  CRM_STATUS: "crm_status",
};

export const TRANSACTION_TYPES = {
  SALES_QUOTE: "sales_quote",
  SALES_ORDER: "sales_order",
  SALES_INVOICE: "sales_invoice",
  CUSTOMER_PAYMENT: "customer_payment",
  CREDIT_MEMO: "credit_memo",
  RFQ: "rfq",
  PURCHASE_REQUEST: "purchase_request",
  PURCHASE_ORDER: "purchase_order",
  PURCHASE_INVOICE: "purchase_invoice",
  SUPPLIER_PAYMENT: "supplier_payment",
  DEBIT_MEMO: "debit_memo",
  BIDDING_SUPPLIER: "bidding_supplier",
  INBOUND_DELIVERY: "inbound_delivery",
  SO_PACKAGE: "so_package",
  SHIPMENT: "shipment",
  INQUIRY: "inquiry",
  JOURNAL: "journal",
  PROFORMA_INVOICE: "propforma_invoice",
  PRICE_CHANGE: "price_change",
  STOCK_CHANGE: "stock_change",
  SUPPLIER_PARTY_LEDGER: "supplier_party_ledger",
  CUSTOMER_PARTY_LEDGER: "customer_party_ledger",
  BUSINESS_EXPENSE: "bussiness_expense",
};

export const STUDENT_REGISTRATION_ACTION_LIST = {
  STUDENT_REGISTRATION_LIST: "student_registration_list",
  STUDENT_REGISTRATION_COUNT: "student_registration_count",
  STUDENT_REGISTRATION_LIST_PAGE_RESET: "student_registration_list_page_reset",
  TOTAL_STUDENT_COUNT: "total_student_count",
  TOTAL_FEES_DUE: "total_fee_due",
  TOTAL_FEES_PAID: "total_fee_paid",
  TOTAL_STUDENT_COUNT_BY_GRADE: "total_student_count_by_grade",
  TOTAL_FINANCIAL_ASSISATANCE: "total_financial_assisatance",
  TOTAL_STUDENT_COUNT_BY_STATUS: "total_student_count_by_status",
  AVAILABLE_PAYMENT_GATEWAY: "available_payment_gateway",
  TOTAL_PARENTS_COUNT: "total_parents_count",
  All_FORM_NAME: "all_form_name",
  STUDENT_DETAIL: "student_detail",
  RESET_STUDENT_DETAIL: "reset_student_detail",
  REGISTER_PARENTS_DATA: "register_parents_data",
  RESET_REGISTER_PARENTS_DATA: "reset_register_parents_data",
  STUDENT_REGISTRATION_DETAIL: "student_registration_detail",
  RESET_STUDENT_REGISTRATION_DETAIL: "reset_student_registration_detail",
  TOTAL_REVENUE_FORECAST: "total_revenue_forecast",
  TOTAL_INVOICE_GENERATED: "total_invoice_generated",
  TOTAL_AMOUNT_RECEIVED: "total_amount_received",
  TOTAL_INVOICE_DUE: "total_invoice_due",
  TOTAL_EXPENSE_DUE: "total_expense_due",
  RECENT_SCHOOL_PAYMENT: "recent_school_payments",
  PAYMENT_REMINDER: "payment_reminder",

  UNPAID_INVOICES_BY_FORM_ID: "unpaid_invoices_by_formId",
  UNPAID_INVOICES_COUNT_BY_FORM_ID: "unpaid_invoices_count_by_formId",
  UNPAID_INVOICES_BY_FORM_ID_RESET: "unpaid_invoices_by_formId_reset",

  UNPAID_CUSTOMERS_BY_FORM_ID: "unpaid_customers_by_formId",
  UNPAID_CUSTOMERS_COUNT_BY_FORM_ID: "unpaid_customers_count_by_formId",
  UNPAID_CUSTOMERS_BY_FORM_ID_RESET: "unpaid_customers_by_formId_reset",
  CUSTOMER_REGISTRATION_LIST: "customer_registration_list",
  OTP_SENT_RES: "otp_sent_res",
  OTP_VERIFY_RES: "otp_verify_res",
  ORG_REGISTRATION_DATA: "org_registration_data",
  ORG_REGISTRATION_INVOICE_PROFORMA: "org_registration_invoice_proforma",
  EXISTING_ORG_REGISTRATION_INVOICE_PROFORMA: "existing_org_registration_invoice_proforma",
  STUDENT_LIMIT_COUNT: "student_limit_count",
};

export const DONATION_ACTION_LIST = {
  DONATION_SETTING: "donation_setting",
  KIOSK_DONATION_SETTING: "kiosk_donation_setting",
  RESET_DONATION_SETTING: "reset_donation_setting",
  DONATION_SETTING_LOADING: "donation_setting_loading",
  RESET_DONATION_LOADING: "reset_donation_loading",
};

export const GRID_ACTIONS = {
  DELETE: "delete",
  SAVE: "save",
  EMAIL: "email",
  DOWNLOAD_PDF: "download_pdf",
  EDIT: "edit",
  CANCEL: "cancle",
};

export const EXPENSE_ACTION_LIST = {
  RELATIONSHIP_EXPENSE: "relationship_expense",
};

export const SETTING_ACTION_LIST = {
  EMAIL_TEMPLATE_DATA: "email_template_data",
  RESET_EMAIL_TEMPLATE_DATA: "reset_email_template_data",
  REMARKS_TEMPLATE_DATA: "remarks_template_data",
  RESET_REMARKS_TEMPLATE_DATA: "reset_remarks_template_data",
  ALL_COMPANY_USERS: "all_company_users",
  DELETE_COMPANY_USERS: "delete_company_users",
  USER_FOR_ALPIDE_ACCESS: "users_for_alpide_access",
  DOCUMNET_NUMBERS: "document_number",
  RESET_DOCUMENT_NUMBERS: "reset_document_number",
  APPROVAL_SETTINGS: "approval_settings",
  QUALITY_CHECK_LIST_SETTING: "quality_check_list_setting",
  REGISTRATION_FORM_SETTING: "registration_form_setting",
  REGISTRATION_FORM_LIST: "registration_form_list",
  RESET_REGISTRATION_FORM_SETTING: "reset_registration_form_setting",
  RELATIONSHIP_DATA: "relationship_data",
  SENDER_EMAILS: "sender_emails",
  CLIENT_ONBOARDING_DATA: "client_onboarding_data",
  REGISTRATION_FORM_SETTING_DRAFT: "registration_form_setting_draft",
  DOCUMNET_PREFIXS: "document_prefix",
  REGISTRATION_FORM_DATA: "registartion_form_data"

};

export const AGED_STOCK_ACTION_LIST = {
  AGED_STOCK_LIST: "suppliers",
  AGED_STOCK_COUNT: "supplier_count",
  AGED_STOCK_PAGE_RESET: "suppliers_page_reset",
};

export const STOCK_SUMMARY_ACTION_LIST = {
  STOCK_SUMMARY_LIST: "suppliers",
  STOCK_SUMMARY_COUNT: "supplier_count",
  STOCK_SUMMARY_PAGE_RESET: "suppliers_page_reset",
};

export const REPORTS_ACTION_LIST = {
  AGED_DEBTORS_LIST: "aged_debtors_list",
  AGED_CREDITORS_LIST: "aged_creditors_list",
};

export const FINANCE_ACTION_LIST = {
  MATCHED_TX_LIST: "matched_tx_list",
  RESET_MATCHED_TX_LIST: "reset_matched_tx_list",
  BANK_TRANSACTIONS: "bank_transactions",
  RESET_BANK_TRANSACTIONS: "reset_bank_transactions",
  TRANSACTION_IN_ALPIDE: "transaction_in_alpide",
  TRANSACTION_COUNT_IN_ALPIDE: "transaction_count_in_alpide",
  RESET_TRANSACTION_IN_ALPIDE: "reset_transaction_in_alpide",
  FIND_TX_LIST: "find_tx_list",
  FIND_TX_COUNT: "find_tx_count",
  RESET_FIND_TX_LIST: "reset_find_tx_list",
  UNASSOCIATE_TX_LIST: "unassociate_tx_list",
  RESET_UNASSOCIATE_TX_LIST: "reset_unassociate_tx_list",
  STATEMENT_UPLOAD_DRAFT_LIST: "statement_upload_draft_list",
  STATEMENT_UPLOAD_DRAFT_COUNT: "statement_upload_draft_count",
  RESET_STATEMENT_UPLOAD_DRAFT: "reset_statement_upload_draft",
  HIDDEN_TRANSACTION_LIST: "hidden_transaction_list",
  HIDDEN_TRANSACTION_COUNT: "hidden_transaction_count",
  RESET_HIDDEN_TRANSACTION_LIST: "reset_hidden_transaction_",
  DAY_BOOK_LIST: "day_book_List",
  DAYBOOK_COUNT: "day_book_count",
  DAY_BOOK_LIST_PAGE_RESET: "book_page_reset",
  CASH_BOOK_LIST: "cash_book_List",
  CASH_BOOK_COUNT: "cash_book_count",
  CASH_BOOK_LIST_PAGE_RESET: "cash_book_page_reset",
  BANK_BOOK_LIST: "bank_book_List",
  BANK_BOOK_COUNT: "bank_book_count",
  BANK_BOOK_LIST_PAGE_RESET: "bank_book_page_reset",
  JOURNAL_REPORTS_LIST: "journal_report_List",
  JOURNAL_REPORTS_COUNT: "journal_report_count",
  JOURNAL_REPORTS_LIST_PAGE_RESET: "journal_report_page_reset",
};

export const MARKETPLACE_NAME = {
  AMAZON: "amazon",
  EBAY: "ebay",
  WALMART: "walmart",
  COSTCO: "costco",
  SHOPIFY: "shopify",
  AUTHORIZE_NET: "Authorize.net",
};

export const TRANSACTION_COLUMN_INDEX = {
  INQUIRY_FORM: ["product", "description", "quantity", "uomId"],
  INQUIRY_LISTING: [
    "inquiryNumber",
    "customerName",
    "inquiryDate",
    "expirationDate",
    "salesPersonFullName",
    "priority",
    "userStatus",
    "statusPRMessage",
    "referenceNumber",
    "totalQuantityOnQuote",
    "totalQuantityOnPR",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  SALES_QUOTE_FORM: [
    "product",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "specialDiscount",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "comment",
    "origin",
    "hsCode",
  ],
  SALES_QUOTE_LISTING: [
    "quotationNumber",
    "customerName",
    "salesQuoteDate",
    "salesQuoteTotalAmount",
    "referenceNumber",
    "userStatus",
    "status",
    "orderPriority",
    "rfqExpirationDate",
    "salesPersonFullName",
    "qtyOnSalesOrder",
    "isInquiryConversion",
    "remarksInternal",
    "remarksCustomer",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  SALES_ORDER_FORM: [
    "product",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "specialDiscount",
    "hsn",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "comment",
    "origin",
    "hsCode",
  ],
  SALES_ORDER_LISTING: [
    "soNumber",
    "salesOrderMasterId",
    "soSourceName",
    "quotationNumber",
    "customerName",
    "salesOrderTotalAmount",
    "salesOrderDate",
    "referenceNumber",
    "paymentTermName",
    "userStatus",
    "status",
    "statusPRMessage",
    "deliveryDate",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "totalQtyInvoiced",
    "totalQtyPacked",
    "totalQtyShipped",
    "totalQtyOnPR",
  ],

  PACKAGE_FORM: [
    "product",
    "description",
    "quantity",
    "quantityPacked",
    "qtyToPack",
    "uomId",
  ],
  PACKAGE_LISTING: [
    "packageNumber",
    "customerName",
    "soNumber",
    "packageDate",
    "referenceNumber",
    "qtyPacked",
    "userStatus",
    "status",
    "qualityChecklistStatus",
    "packed_by",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  SHIPMENT_FORM: [
    "itemName",
    "description",
    "salesOrderNumber",
    "packageNumber",
    "quantity",
    "uomId",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "warehouseName",
    "origin",
    "hsCode",
  ],
  SHIPMENT_LISTING: [
    "shipmentNumber",
    "customerName",
    "shipmentDate",
    "totalQtyShipped",
    "expectedDeliveryDate",
    "ref_number",
    "userStatus",
    "status",
    "consigneeName",
    "actualDeliveryDate",
    "trackingNumber",
    "carrierName",
    "freightType",
    "so_number",
    "inquiry_number",
    "sq_number",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "project",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  SALES_INVOICE_FORM: [
    "product",
    "student",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discountAmount",
    "specialDiscount",
    "salesOrderNumber",
    "shipmentNumber",
    "discount",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "comment",
    "origin",
    "hsCode",
  ],
  SALES_INVOICE_LISTING: [
    "isChecked",
    "isXeroUploaded",
    "invoiceNumber",
    "customerName",
    "inquiry_number",
    "quotationNumber",
    "soNumber",
    "invoiceDate",
    "invoiceDueDate",
    "dueDays",
    "past_due_days",
    "ref_number",
    "invoiceTotalAmount",
    "totalPaymentReceived",
    "creditApplied",
    "invoiceDueAmount",
    "userStatus",
    "status",
    "paymentTermName",
    "shipment_number",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "onlinePayment",
    "invoiceReminder",
    "recurringInvoice",
  ],

  SALES_PAYMENT_LISTING: [
    "paymentNumber",
    "customerName",
    "quotationNumber",
    "soNumber",
    "invoiceNumber",
    "paymentDate",
    "paymentAmount",
    "ref_number",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "paymentMode",
    "transactionId",
    "remarksCustomer",
  ],

  CREDIT_MEMO_FORM: [
    "product",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "specialDiscount",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "comment",
    "origin",
    "hsCode",
  ],
  CREDIT_MEMO_LISTING: [
    "creditMemoNumber",
    "customerName",
    "creditMemoDate",
    "expiration_date",
    "status",
    "creditMemoTotalAmount",
  ],

  SALES_SUPPORT_LISTING: [
    "complaint_number",
    "complaintDetails",
    "customer",
    "so_number",
    "priority",
    "status",
    "assign_to",
    "complaint_type",
    "ref_number",
    "date",
    "due_date",
    "complaint_closed_date",
  ],

  PURCHASE_REQUEST_FORM: ["product", "description", "quantity", "uomId"],
  PURCHASE_REQUEST_LISTING: [
    "purchaseRequestNumber",
    "purchaseRequestDate",
    "ref_number",
    "orderPriority",
    "prRequestedBy",
    "expirationDate",
    "reason",
    "userStatus",
    "status",
    "inquiry_number",
    "sq_number",
    "so_number",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "totalQuantityOnRFQ",
    "totalQuantityOnPO",
    "qty_on_po",
    "qty_on_rfq",
  ],

  RFQ_FORM: ["product", "description", "quantity", "uomId"],
  RFQ_LISTING: [
    "rfq_number",
    "rfq_date",
    "supplier",
    "ref_number",
    "priority",
    "bid_closing_date",
    "user-status",
    "sales_person",
    "project",
    "pr_number",
    "inquiry_number",
    "sq_number",
    "so_number",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "qty_on_po",
    "remarks_internal",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  PO_FORM: [
    "product",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "specialDiscount",
    "upc",
    "tax",
  ],
  PO_LISTING: [
    "poNumber",
    "supplierName",
    "totalPOAmount",
    "poDate",
    "reference",
    "paymentTermName",
    "userStatus",
    "status",
    "priority",
    "expectedDeliveryDate",
    "isRFQCconversion",
    "pr_conversion",
    "inq_number",
    "sq_number",
    "so_number",
    "pr_number",
    "rfqNumber",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "projectName",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "qty_invoiced",
    "totalQtyReceived",
    "totalQtyOnId",
    "remarks",
  ],

  ID_FORM: [
    "product",
    "description",
    "qty_ordered",
    "qty_received",
    "qty_to_received",
    "uomId",
  ],
  ID_LISTING: [
    "inboundDeliveryNumber",
    "poNumber",
    "supplierName",
    "inboundDeliveryDate",
    "project",
    "status",
    "totalQtyReceived",
    "inq_number",
    "sq_number",
    "so_number",
    "pr_number",
    "rfq_number",
    "po_number",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "qty_invoiced",
  ],

  PI_FORM: [
    "product",
    "description",
    "quantity",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "speciallDiscount",
    "tax",
  ],
  PI_LISTING: [
    "isChecked",
    "invoiceNumber",
    "supplierInvoiceNumber",
    "supplierName",
    "sq_number",
    "so_number",
    "pr_number",
    "rfqNumber",
    "invoiceTotalAmount",
    "totalPaymentMade",
    "debitApplied",
    "invoiceDueAmount",
    "invoiceDate",
    "invoiceDueDate",
    "userStatus",
    "status",
    "dueDays",
    "isCashInvoice",
    "paymentTermName",
    "supplierPoNumber",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "project",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
    "qty_invoiced",
    "qty_received",
    "remarks_internal",
    "remarks",
  ],

  PURCHASE_PAYMENT_LISTING: [
    "isXeroUploaded",
    "paymentNumber",
    "supplierName",
    "invoiceNumber",
    "invoiceAmount",
    "paymentDate",
    "paymentAmount",
    "refNumber",
    "inqNumber",
    "sqNumber",
    "soNumber",
    "prNumber",
    "rfqNumber",
    "poNumber",
    "projectName",
    "customer_inq_number",
    "customer_rfq_number",
    "customer_po_number",
    "customerPONumber",
    "customerRFQNumber",
    "customerInquiryNumber",
  ],

  DEBIT_MEMO_FORM: [
    "product",
    "description",
    "qty",
    "uomId",
    "rate",
    "basePrice",
    "discount",
    "specialDiscount",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "comment",
    "origin",
    "hsCode",
  ],
  DEBIT_MEMO_LISTING: [
    "debitMemoNumber",
    "supplierName",
    "debitMemoDate",
    "expiration_date",
    "status",
    "debitMemoTotalAmount",
  ],

  SHIPMENT_INVOICE_FORM: [
    "itemName",
    "description",
    "salesOrderNumber",
    "packageNumber",
    "quantity",
    "uomId",
    "rate",
    "discount",
    "tax",
    "materialNumber",
    "stockNumber",
    "partNumber",
    "warehouseName",
    "origin",
    "hsCode",
  ],
};

export const MEMBERSHIP_ACTION_LIST = {
  TERM_AND_FEES_LIST: "term_and_fees_list",
  TERM_AND_FEES_COUNT: "term_and_fees_count",
  TERM_AND_FEE_LIST_PAGE_RESET: "term_and_fee_list_page_reset",
  ALL_TERM_AND_FEES: "all_term_and_fees",

  MEMBERSHIP_OPTION_LIST: "membership_option_list",
  MEMBERSHIP_OPTION_COUNT: "membership_option_count",
  MEMBERSHIP_OPTION_LIST_PAGE_RESET: "membership_option_list_page_reset",

  MEMBERSHIP_FORM_LIST: "membership_form_list",
  NEWS_LETTER_TEMPLATE: "news_letter_template",

  MEMBERS_LIST: "members_list",
  MEMBERS_COUNT: "members_count",
  MEMBERS_PAGE_RESET: "members_page_reset",
  STUDENT_FORM_LIST: "student_form_list",
  SCHOOL_LIST_PAGE_RESET: "SCHOOL_list_page_reset",
  ORG_MEMBERSHIP_LIST: "org_membership_list",
  ORG_MEMBERSHIP_COUNT: "org_membership_count",
  STUDENT_FORM_DRAFT_LIST: "student_form_draft_list",
  DG_DETAILS:"dg_details"
};

export const SMS_ACTION_LIST = {
  SCHOOL_LIST: "school_list",
  SCHOOL_COUNT: "school_count",
  SCHOOL_STUDENT_CONTACT_LIST: "school_contact_list",
  SCHOOL_LIST_PAGE_RESET: "school_list_page_reset",
  SUBJECT_LIST: "subject_list",
  SUBJECT_COUNT: "subject_count",
  SUBJECT_LIST_PAGE_RESET: "subject_list_page_reset",
  SECTION_LIST: "section_list",
  SECTION_COUNT: "section_count",
  SECTION_LIST_PAGE_RESET: "section_list_page_reset",
  SCHOOL_COURSE_LIST: "school_course_list",
  SCHOOL_COURSE_COUNT: "school_course_count",
  SCHOOL_COURSE_LIST_PAGE_RESET: "school_course_list_page_reset",
  GRADE_LIST: "grade_list",
  GRADE_COUNT: "grade_count",
  GRADE_LIST_RESET: "grade_list_reset",
  CLASS_LIST: "class_list",
  CLASS_COUNT: "class_count",
  CLASS_LIST_RESET: "class_list_reset",
  DEPARTMENT_LIST: "department_list",
  DEPARTMENT_COUNT: "department_count",
  DEPARTMENT_LIST_RESET: "department_list_reset",
  COURSE_TERM_LIST: "course_term_list",
  COURSE_TERM_COUNT: "course_term_count",
  COURSE_TERM_LIST_RESET: "course_term_list_reset",
  COURSE_SUBJECT_LIST: "course_subject_list",
  COURSE_SUBJECT_LIST_RESET: "course_subject_list_reset",
  TEACHER_LIST: "teacher_list",
  TEACHER_COUNT: "teacher_count",
  TEACHER_LIST_PAGE_RESET: "teacher_list_page_reset",
  STUDENT_LIST: "student_list",
  STUDENT_COUNT: "student_count",
  STUDENT_LIST_PAGE_RESET: "student_list_page_reset",
  SCHOOL_ATTENDENCE_BY_CLASS_ID: "school_attendence_by_classId",
  SUBCOURSE_LIST: "subcourse_list",
  SUBCOURSE_COUNT: "subcourse_count",
  SUBCOURSE_LIST_RESET: "subcourse_list_reset",
  SUBCOURSE_LIST_ADDITION: "subcourse_list_addition",
  SUBCOURSE_LIST_DELETION: "subcourse_list_deletion",
  SCHOOL_COMMUNICATION_LIST: "school_communication_list",
  SCHOOL_COMMUNICATION_COUNT: "school_communication_count",
  SCHOOL_COMMUNICATION_LIST_PAGE_RESET: "school_communication_list_page_reset",
  CLASS_SCHEDULE_LIST: "class_schedule_list",
  CLASS_SCHEDULE_LIST_ADDITION: "class_schedule_list_addition",
  CLASS_SCHEDULE_LIST_DELETION: "class_schedule_listt_deletion",
  ONLINE_USER_LIST: "online_user_list",
  ONLINE_USER_COUNT: "online_user_count",
  ONLINE_USER_LIST_PAGE_RESET: "online_user_list_page_reset",
  PARENT_LIST: "parent_list",
  PARENT_COUNT: "parent_count",
  PARENT_LIST_PAGE_RESET: "parent_list_page_reset",
  SUBSCRIPTION_PAYMENT_LIST: "subscription_payment_list",
  SUBSCRIPTION_PAYMENT_COUNT: "subscription_payment_count",
  SUBSCRIPTION_PAYMENT_LIST_PAGE_RESET: "subscription_payment_list_page_reset",
  BROADCAST_MESSAGE_LIST: "broadcast_message_list",
  BROADCAST_MESSAGE_COUNT: "broadcast_message_count",
  BROADCAST_MESSAGE_LIST_PAGE_RESET: "broadcast_message_list_page_reset",

  FINANCIAL_REPORT_LIST: "finance_report_list",
  FINANCIAL_REPORT_COUNT: "finance_report_count",
  FINANCIAL_REPORT_LIST_PAGE_RESET: "finance_report_list_page_reset",

  ASSIGNMENT_LIST: "assignment_list",
  ASSIGNMENT_COUNT: "assignment_count",
  ASSIGNMENT_LIST_PAGE_RESET: "assignment_list_page_reset",

  ASSIGNMENT_RESPONSE_LIST: "assignment_response_list",
  ASSIGNMENT_RESPONSE_COUNT: "assignment_response_count",
  ASSIGNMENT_RESPONSE_LIST_PAGE_RESET: "assignment_response_list_page_reset",

  ASSIGNMENT_DATA: "assignment_data",

  FAMILY_LIST: "family_list",
  FAMILY_COUNT: "family_count",
  FAMILY_LIST_PAGE_RESET: "family_list_page_reset",

  TIME_TABLE_LIST: "time_table_list",
  SCHOOL_STUDENT_LIST: "school_student_list",
  SCHOOL_STUDENT_COUNT: "school_student_count",
  SCHOOL_FORM_DRAFT_LIST: "school_form_draft_list",
  SCHOOL_FORM_DRAFT_COUNT: "school_form_draft_count",
  SCHOOL_ATTENDENCE_BY_STUDENT_ID: "school_attendance_by_student_id",
  SCHOOL_COURSE: "school_course",
  SCHOOL_SUBJECT_HEADERS: "school_subject_headers",
  SCHOOL_COURSE_HEADERS: "school_course_headers",
  STUDENT_REGISTARTION_COUNT:"student_registation_count",
  FORM_REGISTRATION_LIST: "form_registration_list",
  FORM_REGISTRATION_COUNT: "form_registration_count",
  TEACHER_LIST_BY_CLASS:"teacher_list_by_class"

};

export const TAXES_SETTINGS_ACTION_LIST = {
  MARKETPLACE_COUNTRIES_LIST: "marketplace_countries_list",
  COUNTRIES_LIST: "countries_list",
  COUNTRY_STATES: "country_states",
  MARKETPLACE_COUNTRY_STATES: "marketplace_country_states",
};

export const MARKETPLACE_SHIPPING_ACTION_LIST = {
  SHIPPING_COUNTRIES_LIST: "shipping_countries_list",
  SHIPPING_CHOICES: "shipping_choices",
  COUNTRY_SHIPPING_RULE: "country_shipping_rule",
  SHIPPING_SERVICES: "shipping_services",
};

export const FUNDRAISING_ACTION_LIST = {
  CAMPAIGN_LIST: "campaign_list",
  TEAM_MASTER_LIST: "team-master-list",
  TEAM_LEAD_LIST: "team_lead_list",
  TEAM_LEAD_LIST_RESET: "team_lead_list_reset",
  ALL_CAMPAIGN_LIST: "all_campaign_list",
  RESET_ALL_CAMPAIGN_LIST: "reset_all_campaign_list",
  CAMPAIGN_LIST_COUNT: "campaign_list_count",
  CAMPAIGN_LIST_PAGE_RESET: "campaign_list_page_reset",

  PLEDGE_LIST: "pledge_list",
  PLEDGE_LIST_COUNT: "pledge_list_count",
  PLEDGE_LIST_PAGE_RESET: "pledge_list_page_reset",

  NEXT_CAMPAIGN_CODE: "next_campaign_code",
  NEXT_CAMPAIGN_CODE_RESET: "next_campaign_code_reset",
  DONATION_PAYMENT_LIST: "donation_payment_list",
  DONATION_PAYMENT_COUNT: "donation_payment_count",
  DONATION_PAYMENT_LIST_PAGE_RESET: "donation_payment_list_reset",

  SPONSORSHIP_LIST: "sponsorship_list",
  SPONSORSHIP_LIST_COUNT: "sponsorship_list_count",
  SPONSORSHIP_LIST_PAGE_RESET: "sponsorship_list_page_reset",
};

export const COMMUNICATION_ACTION_LIST = {
  DG_LIST: "dg_list",
  DG_DETAILS: "dg_details",
  CONTACT_LIST: "contact_list",
  CONTACT_LIST_COUNT: "contact_list_count",
  CONTACT_LIST_PAGE_RESET: "contact_list_reset",
  SUB_ACCOUNT: "sub_account",
  FORM_BUILDER_LIST: "form_builder_list",
  ACCOUNT_CONFIG_PH: "account_config_ph",
  ACCOUNT_CONFIG_WHATS_APP: "account_config_whats_app",
  NEWSLETTER_TEMPLATE_LIST: "newsletter_template_list",
  EVENT_TEMPLATE_LIST: "event_template_list",
  DG_COUNT: "dg_count",
  CAMPAIGN_LIST:"campaign_list",
  CAMPAIGN_COUNT: "campaign_count",
  CAMPAIGN_EMAIL_METRICS_DETAILS: "campaign_email_metrics_details",
  SEND_STATUS_DETAILS:"send_status_details",
  SEND_STATUS_COUNT:"send_status_count",
  DELIVERED_STATUS_DETAILS:"delivered_status_details",
  FAILED_STATUS_DETAILS:"failed_status_details",
  TOTAL_MAIL_CAMPAIGN_DETAILS:"total_mail_campaign_details",
  TOTAL_MAIL_CAMPAIGN_COUNT:"total_mail_campaign_count",
  FAILED_STATUS_COUNT:"failed_status_count",
  DELIVERED_STATUS_COUNT:"delivered_status_count",
  BREVO_EMAIL_AGGREGATE_REPORT:"brevo_email_aggregate_report",
  CAMPAIGN_EMAIL_STATISTICS: "campaign_email_statistics",
  COMMUNICATION_TEMPLATE_LIST: "template_settings"
};

export const MARKETPLACE_SALES_CHANNEL_ACTION_LIST = {
  AMAZON_PUBLISHED_QTY: "amazon_published_qty",
  AMAZON_UNPUBLISHED_QTY: "amazon_unpublished_qty",
  AMAZON_TODAY_LISTING: "amazon_today_listing",
  AMAZON_THIS_WEEK_LISTING: "amazon_this_week_listing",
  AMAZON_THIS_MONTH_LISTING: "amazon_this_month_listing",
  AMAZON_TOTAL_SALES: "amazon_total_sales",

  EBAY_PUBLISHED_QTY: "ebay_published_qty",
  EBAY_UNPUBLISHED_QTY: "ebay_unpublished_qty",
  EBAY_TODAY_LISTING: "ebay_today_listing",
  EBAY_THIS_WEEK_LISTING: "ebay_this_week_listing",
  EBAY_THIS_MONTH_LISTING: "ebay_this_month_listing",
  EBAY_TOTAL_SALES: "ebay_total_sales",

  WALMART_PUBLISHED_QTY: "walmart_published_qty",
  WALMART_UNPUBLISHED_QTY: "walmart_unpublished_qty",
  WALMART_TODAY_LISTING: "walmart_today_listing",
  WALMART_THIS_WEEK_LISTING: "walmart_this_week_listing",
  WALMART_THIS_MONTH_LISTING: "walmart_this_month_listing",
  WALMART_TOTAL_SALES: "walmart_total_sales",

  INVENTORY_ITEM_LISTING: "inventory_item_listing",
  INVENTORY_ITEMS_COUNT: "inventory_items_count",
  INVENTORY_LISTING_RESET: "inventory_listing_reset",
};

export const KIOSK_ACTION_LIST = {
  KIOSK_LIST: "kiosk_list",
};

export const PAYMENT_SOURCE_BY_ID = {
  1: "Text2Donate",
  2: "WhatsApp",
  3: "Event",
  4: "Online",
  5: "Web Donation",
  6: "SMS",
  7: "Kiosk",
  8: "Online",
};

export const PAYMENT_SOURCE_KEY_BY_ID = {
  0: "manual",
  1: "text2donate",
  2: "whatsapp",
  3: "event",
  4: "online",
  5: "webDonation",
  6: "sms",
  7: "kiosk",
  8: "online",
};

export const TX_FILE_UPLOAD_ACTION = {
  TRANSACTION_LIST: "transaction_list",
  TRANSACTION_COUNT: "transaction_count",
  TRANSACTION_LIST_CID: "transaction_list_cid",
  TRANSACTION_COUNT_CID: "transaction_count_cid",
  TRANSACTION_CUSTOMERS: "transaction_customers",
  TRANSACTION_SUPPLIER_LIST: "transaction_supplier_list",
};
export const FINANCIAL_YEAR_ACTIONS = {
  FINANCIAL_YEAR_LIST: "financial_year_list",
  UPDATE_FINANCIAL_YEAR: "update_financial_year",
  FINANCIAL_YEAR_LIST_DELETION: "financial_year_list_deletion",
};

export const GSTR_FILLING_ACTIONS = {
  E_INVOICE_LISTING: "e_invoice_list",
  VALIDATE_GSTR_INVOICE_LISTING: "validate_invoice_listing",
  SUMMARY_GSTR_INVOICE_LISTING: "summary_invoice_listing"
 


}

export const INVENTORY_VALUATION_ACTION_LIST = {
  ALL_INVENTORY_VALUATION_LIST: "all_inventory_valuation_list",
  DAILY_INVENTORY_VALUATION_LIST: "daily_valuation_list",
  MONTHLY_INVENTORY_VALUATION_LIST: "monthly_valuation_list",
};

export const LANDING_COST_ACTION_LIST = {
  LC_PURCHASE_INVOICE_LIST: "lc_purchase_invoice_list",
  LC_GRN_INVOICE_LIST: "lc_grn_invoice_list",
  LC_TRANSACTION_LIST: "lc_transaction_list",
  PURCHASE_INVOICE_DETAIL: "purchase_invoice_detail",
  GRN_INVOICE_DETAIL: "grn_invoice_detail",
};

export const CRM_COMPONENT_TYPES = {
  INPUT_TEXT: "text",
  INPUT_NUMBER: "number",
  DROPDOWN: "dropdown",
  MULTILINEINPUT: "multiline_Input",
  CHECKBOX: "checkbox",
  FILE_ATTACHMENT: "file_attachment",
  MULTIPLE_CHOICE: "multiple_choice",
};

export const CRM_ACTION_LIST = {
  CRM_FORM_LIST: "crm_form_list",
  CRM_FORM_LIST_COUNT: "crm_form_list_count",
  ALL_LEAD_LIST: "all_lead_list",
  ALL_LEAD_LIST_COUNT: "all_lead_count",
  CRM_LEAD_SOURCE_LIST: "crm_lead_source_list",
  CRM_LEAD_STATUS_LIST: "crm_lead_status_list",
  CRM_LEAD_NOTES_LIST: "crm_lead_notes_list",
  CRM_LEAD_REMINDER_LIST: "crm_lead_reminder_list",
  LEAD_ACTIVITY_TRACK_LIST: "lead_activity_track_list",
  LEAD_TASK_LIST: "lead_task_list",
  LEAD_CAMPAIGN_TYPE_LIST:"campaign_type_list",
  LEAD_ACTIVE_SOURCE:"active_source",
  LEAD_CAMPAIGN_STATUS:"campaign_status",
  LEAD_CAMPAIGN_LIST:"campaign_list",
  LEAD_CAMPAIGN_LIST_COUNT: "lead_campaign_list_count",
  LEAD_EMPLOYEE_BY_TEAMNAME: "lead_employee_by_teamname",
  LEAD_CALL_LOG: "lead_call_log",
  RESET_LEAD_CALL_LOG: "reset_lead_call_log",
  LEAD_CALL_LOGS_LIST: "lead_call_logs_list",
  LEAD_NOTES_LIST: "leads_notes_list",
  LEAD_TASK_LIST_PAGE: "lead_task_list_page",
  LEAD_REMINDER: "lead_reminder",
  LEAD_WORKFLOW: "lead_workflow",

};
export const COMPANY_ACTION_LIST = {
  EMPLOYEE_ATTENDANCE_LIST: "employee_attendance_list",
  DEPARTMENT_LIST: "department_list",
  DEPARTMENT_COUNT:"department_count",
  CREATE_DEPARTMENT_LIST:"create_department_list",
  JOB_ROLE_LIST:"job_role_list",
  JOB_ROLE_LIST_COUNT:"job_role_list_count",
  JOB_ROLE_LIST_RESET:"job_role_list_reset",
  DESIGNATION_LIST_PAGE: "designation_list_page",
  DESIGNATION_LIST_PAGE_COUNT:"designation_list_page_count",
  CREATE_DESIGNATION_LIST:"create_designation_list",
  EMPLOYEE_TYPE_LIST:"employee_type_list",
  EMPLOYEE_TYPE_COUNT:"employee_type_count",
  EMPLOYEES_LIST:"employees_list",
  EMPLOYEE_COUNT:"employee_count",
  TEAM_LIST_PAGE:"team_list_page",
  TEAM_LIST_PAGE_COUNT:"team_list_page_count",
  WORKSHIFT_LIST_PAGE: "workshift_list_page",
  WORKSHIFT_LIST_PAGE_COUNT:"workshift_list_page_count",
  ALLOWANCE_LIST_PAGE: "allowance_list_page",
  ALLOWANCE_LIST_PAGE_COUNT:"allowance_list_page_count",
  DEDUCTION_LIST_PAGE: "deduction_list_page",
  DEDUCTION_LIST_PAGE_COUNT:"deduction_list_page_count",
  ALLOWANCE_SEARCH_PAGE:"allowance_search_page",
  BRANCH_LIST:"branch_list",
  BRANCH_COUNT:"branch_count",
  EMPLOYEE_DETAIL:"employee_detail",
  TEAM_BY_DEPARTMENTID:"team_by_deprtmentId",

};

export const ICONS = {
  ASSIGN: "fi fi-rr-pen-field",
  PLUS: "fi fi-br-plus",
  ADD: "fi fi-br-plus",
  MINUS: "fi fi-rr-minus",
  EDIT: "fi fi-rr-edit",
  DELETE: "fi fi-rr-trash",
  UPDATE: "fi fi-br-refresh",
  SHARE: "fi fi-rr-share",
  SELECT_ALL: "fi fi-rs-check-circle",
  UNSELECT_ALL: "fi fi-rr-cross-circle",
  MAIL: "fi fi-rr-envelope",
  EXCEL: "fi fi-rs-file-excel",
  PDF: "fi fi-rs-file-pdf",
  CSV: "fi fi-rr-file-csv",
  MORE: "fi fi-rs-menu-dots-vertical",
  SORT: "fi fi-rr-sort-alt",
  DOWN_ARROW: "fi fi-rs-angle-down",
  UP_ARROW: "fi fi-rs-angle-up",
  LEFT_ARROW: "fi fi-rr-arrow-small-left",
  FILTER: "fi fi-rr-filter",
  HELP: "fi fi-rr-interrogation",
  COPY: "fi fi-rs-copy-alt",
  CLOSE: "fi fi-br-cross close",
  VIEW: "fi fi-rr-eye",
  ARCHIVE: "fi fi-rr-inbox",
  COLUMN: "fi fi-rr-columns-3",
  CALL: "fi fi-rr-phone-call",
  FOLDER: "fi fi-rr-folder-open",
  UPLOAD:"fi fi-rr-cloud-upload",
  FILEUPLOAD:"fi fi-rr-file-upload",
  FOLDERUPLOAD:"fi fi-rr-folder-upload",
  DOWNLOAD: "fi fi-rr-download",
  SCAN_QR: "fi fi-rs-qr pointer",
  LINK: "fi fi-rr-link-alt",

  ASSIGN_P: "fi fi-rr-pen-field pointer",
  PLUS_P: "fi fi-br-plus pointer",
  ADD_P: "fi fi-br-plus pointer",
  EDIT_P: "fi fi-rr-edit pointer",
  DELETE_P: "fi fi-rr-trash pointer",
  UPDATE_P: "fi fi-br-refresh pointer",
  SHARE_P: "fi fi-rr-share pointer",
  SELECT_ALL_P: "fi fi-rr-cross-circle pointer",
  UNSELECT_ALL_P: "fi fi-rs-check-circle pointer",
  MAIL_P: "fi fi-rr-envelope pointer",
  EXCEL_P: "fi fi-rs-file-excel pointer",
  PDF_P: "fi fi-rs-file-pdf pointer",
  CSV_P: "fi fi-ts-file-csv pointer",
  MORE_P: "fi fi-rs-menu-dots-vertical pointer",
  SORT_P: "fi fi-rr-sort-alt pointer",
  DOWN_ARROW_P: "fi fi-rs-angle-down pointer",
  UP_ARROW_P: "fi fi-rs-angle-up pointer",
  LEFT_ARROW_P: "fi fi-rr-arrow-small-left pointer",
  FILTER_P: "fi fi-rr-filter pointer",
  HELP_P: "fi fi-rr-interrogation pointer",
  COPY_P: "fi fi-rs-copy-alt pointer",
  CLOSE_P: "fi fi-br-cross close pointer",
  DOWNLOAD_P: "fi fi-rr-download pointer",
  PLAY: "fi fi-br-play",
  Replicate:"fi fi-rr-duplicate",
  ADD_ICON: "fi fi-rs-add",
  REMOVE_ICON: "fi fi-rs-minus-circle",
  SMS_ICON:"fi fi-rs-comment-sms",
  WHATSAPP_ICON:"fi fi-brands-whatsapp",
  PLUS_CIRCLE: "fi fi-rr-add",
  MINUS_CIRCLE: "fi fi-rr-minus-circle",
};

export const TEXT_CONSTANTS = {
  REGISTRATION_FORM: "Registration Form",
  ENROLEMENT_FORM: "Enrolment Form",
  ENROLLED: "Enrolled",
  REGISTERED: "Registered",
  PENDING: "Pending",
};

export const MONTH_NAME_CONSTANT = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sept",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const E_STORE = {
  PUBLISHED_PAGE_DATA: "published_page",
  ALL_PAGE_LIST: " all_page_list",
  POLICY_PAGE_DATA: " policy_page_data",
  ECOMSUBDOMAIN_ID: "ecomsub_domainId",
  GET_COUPON_LIST : "get_coupon_list",
  GET_COUPON_COUNT: "get_coupon_count",
  COUPON_USAGE_LIST: "coupon_usage_list",
  COUPON_USAGE_COUNT: "coupon_usage_count",
  BLOG_LIST: "blog_list",
  BLOG_CATEGORY_LIST: "blog_category_list",
  BLOG_COUNT: "blog_list_count",
  CATEGORY_COUNT: "category_list_count",
  TESTIMONIAL_LIST:"testimonial_list",
  TESTIMONIAL_COUNT:"testimonial_count",
  
};

export const LIST_CONSTANTS = {
  EDUCATION_TYPES : ["Pre-School/School", "College", "Institution", "Weekend School"],
  WEIGHT_UNITS: [{"label": "Ounces (oz)", "value": "ounces"}, {"label": "Pounds (lb)", "value": "pounds"},
   {"label": "Kilograms (kg)", "value": "kilograms"}, {"label": "Grams (g)", "value": "grams"}],
  DIMENSION_UNITS: [{"label": "Inch (in)", "value": "inch"}, {"label": "Centimeter (cm)", "value": "centimeter"}],
  GENDER_LIST:["Female","Male"],
  RELIGION_LIST:[
    "Bahá'í",
    "Buddhism",
    "Christianity",
    "Hinduism",
    "Islam",
    "Jainism",
    "Judaism",
    "Paganism",
    "Shinto",
    "Sikhism",
    "Taoism",
    "Wicca",
    "Zoroastrianism"
  ],
  MARITAL_STATUS_LIST:[
    "Divorced",
    "Married",
    "Separated",
    "Single",
    "Widowed"
  ],
  CLOUD_STORAGE_LIST: [
    {label: '5 GB', value: 5},
    {label: '10 GB', value: 10},
    {label: '15 GB', value: 15},
    {label: '20 GB', value: 20},
    {label: '25 GB', value: 25},
    {label: '30 GB', value: 30}
  ],
  SUPPORT_MONTHS: [
    {label: '2 Months', value: 2},
    {label: '4 Months', value: 4},
    {label: '6 Months', value: 6},
    {label: '8 Months', value: 8},
    {label: '12 Months', value: 12}
  ],
  DISTRIBUTION_NAME_LIST : ["Customer", "Suppliers","Subscriber","Team","Student"],
  COUPON_TYPE_LIST: [
    {
      name : 'Percentage',
      value : 'PERCENTAGE',
    },
    {
      name : 'Flat Discount', 
      value : 'FLAT',
    },
    {
      name : 'Buy X and Get Y', 
      value : 'BOGO',
    },
    {
      name : 'Free Shipping', 
      value : 'FREE_SHIPPING',
    },
    {
      name : 'Free Product', 
      value : 'FREEBIE',
    }
  ],
  COUPON_ODER_TYPE_LIST:[
    {
      name : 'Amount', 
      value : 'Amount',
    },
    {
      name : 'Quantity', 
      value : 'Quantity',
    }
  ],
  COUPON_APPLICABLE_LIST: [
    {
      name : 'New Customers', 
      value : 'NEW_CUSTOMERS',
    },
    {
      name : 'All Customers', 
      value : 'ALL_CUSTOMERS',
    },
    {
      name : 'Online Payments', 
      value : 'ONLINE_PAYMENTS',
    }
  ],
  COUPON_APPLY_ON_LIST:[
    {
      name : 'All', 
      value : 'All',
    },
    {
      name : 'Category', 
      value : 'Category',
    },
    {
      name : 'Product', 
      value : 'Product',
    }
  ],
  ATTENDANCE_TYPE_LIST: [
    "Present",
    "Absent",
    "Tardy",
    "Leave",
    "Holiday",
  ],
  CAMPAIGN_EMAIL_STATUS: [
    { name: "Sent", value: "request" },
    { name: "Delivered", value: "delivered" },
    { name: "Bounced", value: "hard_bounce" },
    { name: "Bounced", value: "soft_bounce" },
    { name: "Opened", value: "opened" },
    { name: "First Time Opened", value: "unique_opened" },
    { name: "Link Clicked", value: "clicked" },
    { name: "First Time Link Clicked", value: "unique_clicked" },
    { name: "Unsubscribed", value: "unsubscribed" },
    { name: "Marked as Spam", value: "complaint" },
    { name: "Blocked", value: "blocked" },
    { name: "Invalid Email", value: "invalid_email" },
    { name: "Deferred", value: "deferred" },
    { name: "Error", value: "error" }
  ],
  REGISTRATION_RELATIONSHIPS: [
    { name: "Father", id: "father" },
    { name: "Mother", id: "mother" },
    // { name: "Self", id: "self" },
  ]

}

export const EMPLOYEE_META_CONSTANTS = {
  ALLOWANCE_TYPES : ["Percentage", "Fixed"]
}

export const PRICING_PLAN_CONTANTS = {
  CLOUD_ERP : "cloud-erp",
  E_COMMERCE: "e-commerce",
  EDUCATION_ERP: "education-erp",
}

export const SUBSCRIPTION_ACTIONS = {
  CLOUD_ERP : "cloud-erp",
  E_COMMERCE: "e-commerce",
  EDUCATION_ERP: "education-erp",
}
export const COUPON_MANAGEMENT_CONSTANTS = {

}
export const CAMPAIGN_LISTING_PAGE = {
  TOTAL_MAIL_CAMPAIGN: "total_mail_campaign",
  TOTAL_SUCCESS: "total_success",
  TOTAL_SENT: "total_sent",
  TOTAL_FAILED: "total_failed",

};

export const RETURN_SETTINGS = {
  RETURN_DETAILS: "return_details"

};

export const SALES_COMPLAINT_CONSTANTS = {
  STATUS_TYPES :['Open', 'In Progress', 'On Hold', 'Closed']
}

export const PROJECT_TYPE ={
  FUNDRAISING:'fundRaising',
  CRM_PROJECT:"crmProject"
}

export const INTEGRATION_CONSTANTS = {
  PAYMENT_GATEWAYS: {
    AUTHORIZE_NET: "Authorize.net",
    STRIPE: "Stripe",
    CARDPOINT: "Cardpoint",
    RAZORPAY: "Razorpay"
  },
  MARKETPLACES: {
    AMAZON: "Amazon",
    EBAY: "Ebay",
    WALMART: "Walmart",
    SHOPIFY: "Shopify"
  },
  SHIPPING: {
    EASYPOST: "Easypost",
    SHIPROCKET: "Shiprocket"
  },
  ACCOUNTING: {
    XERO: "Xero",
    QUICKBOOKS: "Quickbooks"
  }
};

export const LOGISTIC_CONSTANTS = {
  EASYPOST: "easypost",
  SHIPROCKET: "shiprocket"
}

