import React, {Fragment} from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import './../finance.scss';
import { CONSTANTS } from "../../../static/constants";
import BalanceSheetNotes from '../../../containers/modal/modalBody/finance/balanceSheet/BalanceSheetNotes';
import { getFinancialYear, getMomentDateForUIReadOnly } from "../../../utils";
import { FormattedMessage } from 'react-intl';
const ProfitAndLoss = (props) =>  {
    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: 'Finance',
        }, {
            name: 'Profit & Loss'
        }
    ];

    const { allLedgerAccounts, companyInfo } = props;
    const financialYear = getFinancialYear(companyInfo);

    const getAmountByLedgerGroup = (groupName, category, type, toReduceType) =>{
        let randomTotalAmount = 0;
        
        for(var i=0;i < allLedgerAccounts.length;i++){
            if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  (allLedgerAccounts[i].nature||"").toUpperCase()) ||  (allLedgerAccounts[i].accountingEntry||"").toUpperCase()) !== toReduceType){
                randomTotalAmount = randomTotalAmount+ allLedgerAccounts[i].amountDifference;
                
            }else if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  (allLedgerAccounts[i].nature||"").toUpperCase()) ||  (allLedgerAccounts[i].accountingEntry||"").toUpperCase()) === toReduceType){
                randomTotalAmount = randomTotalAmount- allLedgerAccounts[i].amountDifference;
            }
        }

         return parseFloat((randomTotalAmount || 0).toFixed(2));
        
    }


    

    const _openBalancesheetNotes = (tabType, notesName, number, toReduceType, heading) => {
        const payload = {};
        payload.tabType = tabType;
        payload.keyName = 'ledgerAccountGroupName';
        payload.notesNumber = number;
        payload.notesName = notesName;
        payload.toReduceType = toReduceType;
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'>
                        ({number}) {heading || tabType}
                    </div>
                </Fragment>,
                modalBody: <BalanceSheetNotes {...props} {...payload} />,
                width: 600,
                hideFooter: true
            }
        );
    }

    return (
        <>
         <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.profitLoss"
                defaultMessage=""
              />
            </div>
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
            </div>
          
        </div>
        <div className="view-container">
            <table className="finance-table">
                <thead>
                <tr>
                        <th colSpan="5">
                            <div className="bold-text text-center pb15">
                                {(companyInfo || {}).storeName}
                        </div>

                            <div className="text-center">
                            Statement of Profit and Loss as on {((financialYear || {}).fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                        </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan="2" className="bold-text text-center">
                        Particulars
                    </td>
                    <td className="bold-text">
                        Notes
                    </td>
                    <td className="bold-text">
                    {((financialYear || {}).fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                    </td>
                    <td>
                        &nbsp;
                    </td>
                </tr>

                <tr>
                    <td className="bold-text">
                        A
                    </td>
                    <td className="bold-text">
                        CONTINUING OPERATIONS
                    </td>

                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">1</td>
                    <td>Revenue from Operations(Net)</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Sales', 'Revenue from Operations(Net)', 1, 'DEBIT')
                        }}>1</td>
                    <td>{getAmountByLedgerGroup("Income/Gain", "Revenue from Operations", "amount", 'DEBIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">2</td>
                    <td>Other Income</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Other Income', 'Other Income', 2, 'DEBIT')
                        }}>2</td>
                    <td>{getAmountByLedgerGroup("Income/Gain", "Other Income", "amount", 'DEBIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30" >3</td>
                    <td className="bold-text">Total Revenue(1 +2)</td>
                    <td>&nbsp;</td>
                    <td>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{((getAmountByLedgerGroup("Income/Gain", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerGroup("Income/Gain", "Other Income", "amount", 'DEBIT')) || 0).toFixed(2)}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">4</td>
                    <td className="bold-text">Expenses</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(a)</td>
                    <td>Cost of Material Consumed</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Purchases', 'Cost of Material Consumed', 3, 'CREDIT')
                        }}>3</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(b)</td>
                    <td>Purchases of Stock in Trade</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Purchases', 'Expenses', 4, 'CREDIT', 'Purchases of Stock in Trade')
                        }}>4</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(c)</td>
                    <td>Employee Benefit Expenses</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Salary & Wages', 'Employee Benefit Expenses', 5, 'CREDIT')
                        }}>5</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Employee Benefit Expense", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(d)</td>
                    <td>Finance Cost</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Interest', 'Finance Cost', 6, 'CREDIT')
                        }}>6</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Finance Cost", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(e)</td>
                    <td>Depreciation & Amortisation</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Depreciation & Amortisation', 'Depreciation & Amortisation', 7, 'CREDIT')
                        }}>7</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Depreciation & Amortisation", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl50">(f)</td>
                    <td>Other Expenses</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Other Expenses', 'Other Expenses', 8, 'CREDIT')
                        }}>8</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Other Expenses", "amount", 'CREDIT')}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td>&nbsp;</td>
                    <td className="bold-text">Total Expenses</td>
                    <td>&nbsp;</td>
                    <td>
                    {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{((getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Purchases", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Other Expenses", "amount", 'CREDIT')) || 0).toFixed(2)}
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">5</td>
                    <td className="bold-text">Profit / (Loss) before exceptional and extraordinary items & Tax (3-4)</td>
                    <td>&nbsp;</td>
                    <td>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(((getAmountByLedgerGroup("Income/Gain", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerGroup("Income/Gain", "Other Income", "amount", 'DEBIT'))-(getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Purchases", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Other Expenses", "amount", 'CREDIT'))) || 0).toFixed(2)}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">6</td>
                    <td>Exceptional Items</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">7</td>
                    <td>Extraordinary Items</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">8</td>
                    <td className="bold-text">Profit / (Loss) before Tax (5 - 6 + 7)</td>
                    <td>&nbsp;</td>
                    <td>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(((getAmountByLedgerGroup("Income/Gain", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerGroup("Income/Gain", "Other Income", "amount", 'DEBIT'))-(getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Purchases", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Other Expenses", "amount", 'CREDIT')) - 0 + 0) || 0).toFixed(2)}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">9</td>
                    <td>Tax Expenses</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Tax Expense', 'Tax Expenses', 9,'', 'Tax Expenses')
                        }}>9</td>
                    <td>{getAmountByLedgerGroup("Expenses/Loss", "Taxes", "amount")}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">10</td>
                    <td>Current Tax Expenses</td>
                    <td className="link-color" onClick={() => {
                            _openBalancesheetNotes('Deferred Tax Liability', 'Deferred Tax Liability Net', 10, '', 'Current Tax Expenses')
                        }}>10</td>
                    <td>{getAmountByLedgerGroup("Equity & Liabilities", "Non Current Liabilities", "amount")}</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">11</td>
                    <td>Deferred Tax</td>
                    <td className="link-color"onClick={() => {
                            _openBalancesheetNotes('Deferred Tax Liability', 'Deferred Tax Liability Net', 11, '', 'Deferred Tax')
                        }} >11</td>
                    <td>{getAmountByLedgerGroup("Equity & Liabilities", "Non Current Liabilities", "amount") }</td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td className="pl30">12</td>
                    <td className="bold-text">Profit / (Loss) for Continuing Operations (8-9)</td>
                    <td>&nbsp;</td>
                    <td>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(((getAmountByLedgerGroup("Income/Gain", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerGroup("Income/Gain", "Other Income", "amount", 'DEBIT'))-(getAmountByLedgerGroup("Expenses/Loss", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Purchases", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerGroup("Expenses/Loss", "Other Expenses", "amount", 'CREDIT')) - getAmountByLedgerGroup("Expenses/Loss", "Taxes", "amount")) || 0).toFixed(2)}</td>
                    <td>&nbsp;</td>
                </tr>

                </tbody>
            </table>
            {/* <div className="footer">
                <div className="f-left"></div>
                <div className="f-right"></div>
            </div> */}
        </div>
        </>
    )
};

export default ProfitAndLoss;