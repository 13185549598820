import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Empty, Checkbox, Dropdown, Menu, Skeleton, Switch, Pagination } from "antd";
import { ICONS } from "../../../static/constants";
import find from "lodash.find";
import RatingPreview from "../../modal/modalBody/product/RatingPreview";
import { getAWSFileURL, showToasterMessage } from "../../../utils";
import ReactHtmlParser from 'react-html-parser';


const UnPublishedListing = (props) => {
  const { pageNumber, companyInfo ,selectedItems,publishedList} = props;

  const publishProductReview = () => {

    if (props.selectedItems.length) {

    }
    else {
      showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "common.record.notSelected.proceeding",
        }),
      });
    }

  }
  const actionMenu = (data) => {
    return (
      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          key="1"
          value=""
          onClick={() => {
            props.updateState({
              feedbackDrawer: true,
              feedbackData: data,
            })
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>
        <Menu.Item
          key="2"
          value=""

        >
          <i className={ICONS.UPLOAD} />
          Publish
        </Menu.Item>
        <Menu.Item
          key="3"
          value=""

        >
          <i class="fi fi-rr-folder-download"></i>
          Archieve
        </Menu.Item>

        {/* <Menu.Item
          key="1"
          value=""
          onClick={() =>
            props.showModal({
              title: (<div className="ant-modal-title">Product Preview</div>),
              modalBody: <RatingPreview {...props} product={data} />,
              width: 700,
              hideFooter: true,

            })
          }
        >
          <i class="fi fi-sr-preview"></i>
          Preview
        </Menu.Item> */}


      </Menu>
    );
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < publishedList[pageNumber].length; i++) {
      let item = publishedList[pageNumber][i];
      let selectItem = find(selectedItems, {
        feedbackId: Number(item.feedbackId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !publishedList ||
      !publishedList[pageNumber] ||
      publishedList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < publishedList[pageNumber].length; i++) {
      let usr = publishedList[pageNumber][i];
      let selectItem = find(selectedItems, {
        feedbackId: Number(usr.feedbackId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
        feedbackId: Number(item.feedbackId),
    });
    let updatedItems = [];
    if (selectItem) {
        updatedItems = selectedItems.filter(
            (obj) => obj.feedbackId !== item.feedbackId
        );
    } else {
        updatedItems = JSON.parse(JSON.stringify(selectedItems));
        updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
};
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({...payload,isPublished:0});
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({...payload,isPublished:0});
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  function removeTags(html) {
    return html.replace(/<[^>]+>/g, '');
  }
  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: <div style={{ maxHeight: '550px', overflow: 'auto' }}>{ReactHtmlParser(Text)}</div>,
      hideFooter: true,
      width: '70%'
    })
  }
  return (
    <>
      <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="productList.review.table.heading"
                defaultMessage=""
              />
            </div>

            <>
              <div className="vertical-sep" />
              <div></div>
            </>

          </div>
          <div className="right-actions">

            <button className="upload-button" onClick={() => {
              publishProductReview()
            }}>

              <i className={ICONS.UPLOAD} />{" "}
              Publish</button>
          </div>
        </Skeleton>
      </div>
      <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table>
            <thead>
              <tr>
                <th width='6%'>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />

                  </div>
                </th>
                <th>SO #</th>
                <th>Product Name</th>
                <th>Rating</th>

                <th>Review</th>
                <th>Reviewed By</th>

                <th>Status</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {(props.publishedList[pageNumber] || []).length
                ? props.publishedList[pageNumber].map((item, i) => {
                  const image = getAWSFileURL(item?.image, props.companyInfo.bucketName, item?.folderName)

                  return (
                    <tr>
                      <td>
                        <Checkbox
                           onClick={() => {
                            itemSelection(item);
                        }}
                        checked={
                            find(selectedItems, {
                                feedbackId: Number(item.feedbackId),
                            })
                                ? true
                                : false
                        }
                        />
                      </td>
                      <td>{item.soNumber}</td>
                      <td>{item.itemName}</td>
                      <td>

                        <span className="link" onClick={() =>
                          props.showModal({
                            title: (<div className="ant-modal-title">Product Preview</div>),
                            modalBody: <RatingPreview {...props} product={item} />,
                            width: 700,
                            hideFooter: true,

                          })
                        }>

                          {item.rating}
                        </span>
                      </td>

                      <td>

                        {
                          item.message ? (<div>
                            {
                              (removeTags(item.message) || '')?.length < 20 ?
                                (<pre

                                >{ReactHtmlParser(item.message)}</pre>) : (<div><pre

                                >{removeTags(item.message).slice(0, 20)}</pre><span className="more-details-btn" onClick={() => { openLongtText('Review', item.message) }}>Show More</span></div>)
                            }


                          </div>) : <div className="empty-data"></div>
                        }
                      </td>
                      <td>{item.customerName}</td>
                      <td>
                        <div className="status-toggle">

                          <Switch
                            checked={item.isPublished}
                            onChange={(checked) => {
                              const data = {
                                title: "Confirmation",
                                modalBody: <div>Are you sure you want change the publish?</div>,
                                handleSubmit: () => {
                                  const payload = {
                                    ...item,
                                    relationshipId: props.companyInfo.relationshipId,
                                    itemName: item.itemName,
                                    rating: item.rating,
                                    message: item.message,
                                    customerName: item.customerName,
                                    updatedByUserId: item.updatedByUserId,
                                    feedbackId: item.feedbackId,
                                   
                                  };
                                  props.updateFeedback({ ...payload, isPublished: checked ? 1 : 0 }, image, props);
                                },
                              };

                              props.pushModalToStack(data)
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <Dropdown
                          overlay={actionMenu(item)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE} />
                        </Dropdown>
                      </td>
                    </tr>

                  )
                }) :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </Skeleton>
      </div>

      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            current={props.pageNumber || 1}
            size="small"
            total={props.publishCount}
            showTotal={showTotal}
            defaultPageSize={100}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </>
  )
}
export default injectIntl(UnPublishedListing);