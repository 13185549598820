import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const updateFeedback = (payload, files, props) => {
  return dispatch => {
    let formData = new FormData();
    if (files) {
      formData.append('files', files)
    }

    formData.append('ecomFeedback', JSON.stringify(payload || {}));
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecomFeedback/createFeedback `,
      formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    )
      .then(response => {

        const val = {
          pageNumber: props.pageNumber,
          pageSize: props.pageSize,
          relationshipId: payload.relationshipId,
          isPublished: payload.isPublished
        }

        props.activeKey === "1" ? props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 }) : props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 })

        showToasterMessage({
          messageType: "success",
          description: "Updated Successfully.",
        });

      })
      .catch(err => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
  }
}
export const deleteFeedbackImgaes = (payload,props)=>{

  return dispatch => {
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/feedback/deleteFeedbackImage`,payload
    )
        .then(response => {

           return showToasterMessage({
             messageType: "Deleted Succesfully",
             description: response?.message,
           });

         
        })
        .catch(err => {
          showToasterMessage({
              messageType: "error",
              description: "Some error occured",
            });
          //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
  }
}