import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageUserComponent from "../../../../../components/modal/modalBody/settings/ManageUser";
import { fetchSalutation, deleteEmployee, showModal, hideModal } from "../../../../../actions/commonActions";
import { createOrUpdteUser, fetchAllEmployees } from "./action"
import find from 'lodash.find';
// import Administration from '../../../../../components/modal/modalBody/settings/ManageUser/Administration';
import { getAllStudentForm } from './../../../../Student/studentFormList/action';

class ManageUser extends Component {
    constructor(props) {
        super(props);
   //   this is add to check if we need to show all tabs or not according to the data coming
        let tabsToshow={
            Sales:true,
            purchase:true,
            Inventory:true,
            finance:true,
            administraction:true,
            integration:true,
            school:true,
            crm:true,
            hrms:true,
            eStore:true
        }

        let salesDataSource = [
            {
                key: 'cust-1',
                title: 'Customers',
                moduleCode: "G",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-2',
                title: 'Customers Billing Address',
                moduleCode: "U",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-3',
                title: 'Customers Shipping Address',
                moduleCode: "BH",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-4',
                title: 'Customers Activity',
                moduleCode: "BJ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-5',
                title: 'Customers Communication',
                moduleCode: "BK",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-6',
                title: 'Sales Inquiry',
                moduleCode: "DP",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-7',
                title: 'Sales Quote',
                moduleCode: "I",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-8',
                title: 'Sales Quote Multicurrency',
                view: false,
                moduleCode: "AU",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-9',
                title: 'Sales Order',
                moduleCode: "J",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-9',
                title: 'Sales Order Multicurrency',
                view: false,
                moduleCode: "AV",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-10',
                title: 'Sales Order Package',
                view: false,
                moduleCode: "AZ",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-11',
                title: 'Sales Order Shipment',
                view: false,
                moduleCode: "BA",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-12',
                title: 'Sales Invoice',
                moduleCode: "K",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-13',
                title: 'Sales Invoice Multicurrency',
                view: false,
                moduleCode: "AR",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-14',
                title: 'Sales Payment',
                view: false,
                moduleCode: "L",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-15',
                title: 'Credit Memo',
                view: false,
                moduleCode: "M",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'cust-16',
                title: 'Quality Check',
                view: false,
                moduleCode: "MQ",
                create: false,
                update: false,
                delete: false,
                sendEmail: false,
                fullControl: false,
            },
            {
                key: 'cust-18',
                title: 'Sales Support',
                moduleCode: "DQ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-19',
                title: 'Sales Report',
                moduleCode: "EF",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: 'cust-20',
                title: 'Sales Return/Replace',
                moduleCode: "FC",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: false,
                fullControl: false,
            }
        ];

        let purchaseDataSource = [
            {
                key: '1',
                title: 'Suppliers',
                moduleCode: "A",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '2',
                title: 'Suppliers Billing Address',
                moduleCode: "V",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '3',
                title: 'Suppliers Shipping Address',
                moduleCode: "BL",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '4',
                title: 'Suppliers Activity',
                moduleCode: "BM",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '5',
                title: 'Suppliers Communication',
                moduleCode: "BN",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '6',
                title: 'Purchase Request',
                moduleCode: "B",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '7',
                title: 'Purchase Order',
                view: false,
                moduleCode: "C",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '8',
                title: 'RFQ',
                moduleCode: "H",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '9',
                title: 'Purchase Order Multicurrency',
                view: false,
                moduleCode: "AX",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '10',
                title: 'Purchase Invoice',
                moduleCode: "D",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '11',
                title: 'Purchase Invoice Multicurrency',
                view: false,
                moduleCode: "AS",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '15',
                title: 'Purchase GRN',
                view: false,
                moduleCode: "DR",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: '5',
            //     title: 'Payment',
            //     view: true,
            //     moduleCode: "E",
            //     create: true,
            //     update: true,
            //     delete: true,
            //     sendEmail: true,
            //     fullControl: true,
            // },
            {
                key: '12',
                title: 'Purchase Payment',
                view: false,
                moduleCode: "BF",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: '5',
            //     title: 'Recieve Payment',
            //     view: true,
            //     moduleCode: "BE",
            //     create: true,
            //     update: true,
            //     delete: true,
            //     sendEmail: true,
            //     fullControl: true,
            // },
            {
                key: '13',
                title: 'Debit Memo',
                view: false,
                moduleCode: "F",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '14',
                title: 'Landed Cost',
                view: false,
                moduleCode: "DT",
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let inventoryDataSource = [
            {
                key: 'inv-1',
                title: 'Product',
                moduleCode: "N",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-2',
                title: 'Product Category',
                moduleCode: "BB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'inv-3',
                title: 'Product Manufacturer',
                moduleCode: "BC",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-4',
                title: 'Product Brand',
                moduleCode: "BD",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-5',
                title: 'Recieve Payment',
                moduleCode: "BE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-6',
                title: 'Warehouse',
                moduleCode: "O",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-7',
                title: 'Stock Summary',
                moduleCode: "P",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-8',
                title: 'Stock Adjustment',
                moduleCode: "Q",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'inv-9',
                title: 'Aged Stock',
                moduleCode: "R",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-10',
                moduleCode: "S",
                title: 'Product Price',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-11',
                moduleCode: "T",
                title: 'Price List',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'inv-12',
            //     title: 'Extras',
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'inv-13',
                title: 'UOM',
                moduleCode: "W",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-14',
                title: 'HSN',
                moduleCode: "DW",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-15',
                title: 'Stock Transfer',
                moduleCode: "DX",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-16',
                title: 'Inventory Reports',
                moduleCode: "EG",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let financeDataSource = [
            {
                key: 'fin-x1',
                title: 'Banking',
                moduleCode: "CB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-x2',
                title: 'Business Expense',
                moduleCode: "EP",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-1',
                title: 'Ledger Accounts',
                moduleCode: "X",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-2',
                title: 'Balance Sheet',
                moduleCode: "Y",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-3',
                title: 'Profit & loss',
                moduleCode: "Z",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-4',
                title: 'Trial Balance',
                moduleCode: "AA",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-5',
                title: 'Opening Balance',
                moduleCode: "AB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-6',
                title: 'Journals',
                moduleCode: "AC",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-7',
                title: 'Business Expense',
                moduleCode: "AD",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-8',
                title: 'Bank Reconciliation',
                moduleCode: "AE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-9',
                title: 'Taxes',
                moduleCode: "AF",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-10',
                title: 'GST Fillings',
                moduleCode: "AG",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-11',
                title: 'Financial Years',
                moduleCode: "AH",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-12',
                moduleCode: "AI",
                title: 'Cost Centers',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-13',
                title: 'Finance Books',
                moduleCode: "EP",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-14',
                moduleCode: "AJ",
                title: 'Financial Reports',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let adminDataSource = [
            {
                key: 'sett-0',
                title: 'Dashboard',
                moduleCode: "CW",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-1',
                title: 'Settings',
                moduleCode: "AK",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

            {
                key: 'sett-2',
                title: 'Document Number',
                moduleCode: "BR",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-3',
                title: 'Email Template',
                moduleCode: "BS",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-4',
                title: 'Remarks',
                moduleCode: "BT",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-5',
                title: 'Document Name',
                moduleCode: "BU",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-6',
                title: 'Document Approoval',
                moduleCode: "BW",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-7',
            //     title: 'Registration Form',
            //     moduleCode: "BX",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-8',
                title: 'Communication',
                moduleCode: "CE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-9',
                title: 'Newsletter',
                moduleCode: "CF",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-10',
                title: 'Events',
                moduleCode: "CG",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-11',
            //     title: 'Online Stores',
            //     moduleCode: "CH",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-12',
                title: 'Kiosk',
                moduleCode: "CI",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-13',
                title: 'Donation',
                moduleCode: "BY",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-14',
            //     title: 'Company Information',
            //     moduleCode: "BP",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-15',
                title: 'User Management',
                moduleCode: "BQ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-16',
            //     title: 'Membership',
            //     moduleCode: "CD",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-17',
                title: 'Fundraising',
                moduleCode: "CE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let integrationDataSource = [
            {
                key: 'ids-1',
                title: 'Payment Gateway',
                moduleCode: "AO",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'ids-2',
                title: 'Marketplace',
                moduleCode: "AM",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'ids-3',
                title: 'Shipping',
                moduleCode: "AP",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'ids-4',
                title: 'Accounting',
                moduleCode: "AQ",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
        ]

        let schoolDataSource = [
            {
                key: '1',
                title: 'Dashboard',
                moduleCode: "CM",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: '2',
                title: 'Department',
                moduleCode: "CN",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '3',
                title: 'Course Detail',
                moduleCode: "CO",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '4',
                title: 'Class Detail',
                moduleCode: "CP",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '5',
                title: 'Attendance',
                moduleCode: "CQ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '6',
                title: 'Student',
                moduleCode: "CR",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '7',
                title: 'Teacher',
                moduleCode: "CS",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '8',
                title: 'School Invoice',
                moduleCode: "CT",
                //moduleCode: "K",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '16',
                title: 'Invoice Pay',
                moduleCode: "DD",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '9',
                title: 'Registration Form',
                moduleCode: "CU",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '10',
                title: 'Communication',
                moduleCode: "CV",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '11',
                title: 'Broadcast Message',
                moduleCode: "CX",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '12',
                title: 'Registration',
                moduleCode: "CY",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '13',
                title: 'Online User',
                moduleCode: "CZ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '14',
                title: 'Documents',
                moduleCode: "DA",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '15',
                title: 'Recurring Payments',
                moduleCode: "DB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '16',
                title: 'Assignments',
                moduleCode: "DC",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '17',
                title: 'Familes',
                moduleCode: "DE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-17',
                title: 'Student Invoice',
                moduleCode: "BZ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

        ]

        let crmDataSource = [
            {
                key: 'cds-1',
                title: 'All Leads',
                moduleCode: "DJ",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'cds-2',
                title: 'My Leads',
                moduleCode: "DK",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'cds-3',
                title: 'Lead Form Setting',
                moduleCode: "DL",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-4',
                title: 'Customers',
                moduleCode: "DM",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-5',
                title: 'Lead Reports',
                moduleCode: "EH",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-6',
                title: 'Crm Reports',
                moduleCode: "EI",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },




            {
                key: 'cds-7',
                title: 'Crm All Reminder',
                moduleCode: "FE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
              {
                key: 'cds-8',
                title: 'Crm Lead Notes',
                moduleCode: "FF",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-9',
                title: 'Crm Opportunity',
                moduleCode: "EW",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-10',
                title: 'Crm All Lead Task',
                moduleCode: "EX",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-11',
                title: 'Crm All Call Logs',
                moduleCode: "EY",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-12',
                title: 'Crm Lead Transfer',
                moduleCode: "EZ",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-13',
                title: 'Crm Lead Workflows',
                moduleCode: "FA",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
              {
                key: 'cds-14',
                title: 'Crm All Lead Stage',
                moduleCode: "FB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
        ]

        let hrmsDataSource = [
            {
                key: 'hds-1',
                title: 'HRMS Employee',
                moduleCode: "DY",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'hds-2',
                title: 'Company Hierarcy',
                moduleCode: "CK",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-3',
                title: 'Attendance Management',
                moduleCode: "DZ",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'hds-4',
                title: 'Leave Management',
                moduleCode: "EA",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-5',
                title: 'Payroll Management',
                moduleCode: "EB",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-6',
                title: 'Employee Services',
                moduleCode: "EC",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-7',
                title: 'Learning Management System',
                moduleCode: "ED",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-8',
                title: 'Asset Management',
                moduleCode: "EE",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ]

        let eStoreDataSource = [
            {
                key: 'eds-1',
                title: 'Appearance',
                moduleCode: "EJ",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'eds-2',
                title: 'Product Managment',
                moduleCode: "EK",
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'eds-3',
                title: 'Store Settings',
                moduleCode: "EL",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'eds-4',
                title: 'Blog Management',
                moduleCode: "PS",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ]


//       making this code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)

//         let dashboardDataSource = [
           
// ,
//             {
//                 key: 'dash-2',
//                 title: 'School Dashboard',
//                 moduleCode: "CM",
//                 view: false,
//                 create: false,
//                 update: false,
//                 delete: false,
//                 fullControl: false,
//             }
//         ]

        this.state = {
            notifyUser: true,
            purchaseDataSource,
            salesDataSource,
            inventoryDataSource,
            financeDataSource,
            adminDataSource,
            integrationDataSource,
            schoolDataSource,
            crmDataSource,
            hrmsDataSource,
            eStoreDataSource,tabsToshow
            // alifAppDataSource: this.props.isAlifApp ? [
            //     {
            //         key: '1',
            //         title: 'Dashboard',
            //         moduleCode: "CW",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '2',
            //         title: 'Membership',
            //         moduleCode: "CC",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '3',
            //         title: 'Fundraising',
            //         moduleCode: "CD",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '4',
            //         title: 'Communication',
            //         moduleCode: "CE",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     // {
            //     //     key: '4',
            //     //     title: 'School',
            //     //     moduleCode: "CA",
            //     //     view: true,
            //     //     create: true,
            //     //     update: true,
            //     //     delete: true,
            //     //     sendEmail: true,
            //     //     fullControl: true,
            //     // },
            //     {
            //         key: '5',
            //         title: 'Finance',
            //         moduleCode: "AQ",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '6',
            //         title: 'Org Information',
            //         moduleCode: "BP",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '7',
            //         title: 'Report',
            //         moduleCode: "AJ",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     // {
            //     //     key: '8',
            //     //     title: 'Administration',
            //     //     moduleCode: "SCHOOL_ASSIGNMENTS",
            //     //     view: true,
            //     //     create: true,
            //     //     update: true,
            //     //     delete: true,
            //     //     sendEmail: true,
            //     //     fullControl: true,
            //     // },

            // ] : [],
            // alifAppAdministrationDataSource: this.props.isAlifApp ? [
            //     {
            //         key: '1',
            //         title: 'Settings',
            //         moduleCode: "AK",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },

            //     {
            //         key: '4',
            //         title: 'Document Number',
            //         moduleCode: "BR",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '5',
            //         title: 'Email Template',
            //         moduleCode: "BS",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '6',
            //         title: 'Remarks',
            //         moduleCode: "BT",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '7',
            //         title: 'Document Name',
            //         moduleCode: "BU",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },

            //     {
            //         key: '9',
            //         title: 'Registration Form',
            //         moduleCode: "BX",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     }, {
            //         key: '11',
            //         title: 'Banking',
            //         moduleCode: "CB",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },

            //     {
            //         key: '14',
            //         title: 'Communication',
            //         moduleCode: "CF",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '15',
            //         title: 'Newsletter',
            //         moduleCode: "CG",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '16',
            //         title: 'Events',
            //         moduleCode: "CH",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '17',
            //         title: 'Online Stores',
            //         moduleCode: "CI",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '18',
            //         title: 'Kiosk',
            //         moduleCode: "CJ",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '20',
            //         title: 'Company Hierarcy',
            //         moduleCode: "CL",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     // {
            //     //     key: '2',
            //     //     title: 'Company Information',
            //     //     moduleCode: "BP",
            //     //     view: true,
            //     //     create: true,
            //     //     update: true,
            //     //     delete: true,
            //     //     sendEmail: true,
            //     //     fullControl: true,
            //     // },
            //     {
            //         key: '21',
            //         title: 'Donation',
            //         moduleCode: "BY",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },
            //     {
            //         key: '22',
            //         title: 'User Management',
            //         moduleCode: "BQ",
            //         view: true,
            //         create: true,
            //         update: true,
            //         delete: true,
            //         sendEmail: true,
            //         fullControl: true,
            //     },

            // ] : [],
        }

        if (props.userData) {

            const currentUserPermission = find(props.userData.boUserPermissionList, { clientUserAccountId: props.userData.userId });
            const { purchaseDataSource, salesDataSource, inventoryDataSource, financeDataSource, adminDataSource, 
                integrationDataSource, schoolDataSource, eStoreDataSource, crmDataSource, hrmsDataSource, tabsToshow,permissions} = this.state;
     
            // purchaseDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // salesDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // inventoryDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // financeDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // adminDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // integrationDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // schoolDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // eStoreDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });

            // alifAppDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // alifAppAdministrationDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });


//             currentUserPermission.boUserPrimaryPermission.forEach((permission) => {
//                 const supplierCode = find(purchaseDataSource, { moduleCode: permission.moduleCode });
//                 const customerCode = find(salesDataSource, { moduleCode: permission.moduleCode });
//                 const inventoryCode = find(inventoryDataSource, { moduleCode: permission.moduleCode });
//                 const financeCode = find(financeDataSource, { moduleCode: permission.moduleCode });
//                 const adminCode = find(adminDataSource, { moduleCode: permission.moduleCode });
//                 const integrationCode = find(integrationDataSource, { moduleCode: permission.moduleCode });
//                 const schoolCode = find(schoolDataSource, { moduleCode: permission.moduleCode });
//                 const crmCode = find(crmDataSource, { moduleCode: permission.moduleCode });
//                 const hrmsCode = find(hrmsDataSource, { moduleCode: permission.moduleCode });
//                 const eStoreCode = find(eStoreDataSource, { moduleCode: permission.moduleCode });

// //        code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)
//                 // const dashboardCode = find(dashboardDataSource, { moduleCode: permission.moduleCode });

//                 // const alifAppCode = find(alifAppDataSource, { moduleCode: permission.moduleCode });
//                 // const alifAppAdministrationCode = find(alifAppAdministrationDataSource, { moduleCode: permission.moduleCode });

//                 if (supplierCode) {
//                     supplierCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     supplierCode.view = (permission.permisssionValue & 1) ? true : false;
//                     supplierCode.create = (permission.permisssionValue & 2) ? true : false;
//                     supplierCode.update = (permission.permisssionValue & 4) ? true : false;
//                     supplierCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     supplierCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     supplierCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     supplierCode.version = permission.version
//                 }
//                 if (customerCode) {
//                     customerCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     customerCode.view = (permission.permisssionValue & 1) ? true : false;
//                     customerCode.create = (permission.permisssionValue & 2) ? true : false;
//                     customerCode.update = (permission.permisssionValue & 4) ? true : false;
//                     customerCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     customerCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     customerCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     customerCode.version = permission.version
//                 } 
//                 if (inventoryCode) {
//                     inventoryCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     inventoryCode.view = (permission.permisssionValue & 1) ? true : false;
//                     inventoryCode.create = (permission.permisssionValue & 2) ? true : false;
//                     inventoryCode.update = (permission.permisssionValue & 4) ? true : false;
//                     inventoryCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     inventoryCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     inventoryCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     inventoryCode.version = permission.version
//                 }
//                 if (financeCode) {
//                     financeCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     financeCode.view = (permission.permisssionValue & 1) ? true : false;
//                     financeCode.create = (permission.permisssionValue & 2) ? true : false;
//                     financeCode.update = (permission.permisssionValue & 4) ? true : false;
//                     financeCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     financeCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     financeCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     financeCode.version = permission.version
//                 }
//                 if (adminCode) {
//                     adminCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     adminCode.view = (permission.permisssionValue & 1) ? true : false;
//                     adminCode.create = (permission.permisssionValue & 2) ? true : false;
//                     adminCode.update = (permission.permisssionValue & 4) ? true : false;
//                     adminCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     adminCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     adminCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     adminCode.version = permission.version
//                 }
//                 if (integrationCode) {
//                     integrationCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     integrationCode.view = (permission.permisssionValue & 1) ? true : false;
//                     integrationCode.create = (permission.permisssionValue & 2) ? true : false;
//                     integrationCode.update = (permission.permisssionValue & 4) ? true : false;
//                     integrationCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     integrationCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     integrationCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     integrationCode.version = permission.version
//                 }
//                 if (schoolCode) {
//                     schoolCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     schoolCode.view = (permission.permisssionValue & 1) ? true : false;
//                     schoolCode.create = (permission.permisssionValue & 2) ? true : false;
//                     schoolCode.update = (permission.permisssionValue & 4) ? true : false;
//                     schoolCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     schoolCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     schoolCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     schoolCode.version = permission.version
//                 }
//                 if (crmCode) {
//                     crmCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     crmCode.view = (permission.permisssionValue & 1) ? true : false;
//                     crmCode.create = (permission.permisssionValue & 2) ? true : false;
//                     crmCode.update = (permission.permisssionValue & 4) ? true : false;
//                     crmCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     crmCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     crmCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     crmCode.version = permission.version
//                 }
//                 if (hrmsCode) {
//                     hrmsCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     hrmsCode.view = (permission.permisssionValue & 1) ? true : false;
//                     hrmsCode.create = (permission.permisssionValue & 2) ? true : false;
//                     hrmsCode.update = (permission.permisssionValue & 4) ? true : false;
//                     hrmsCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     hrmsCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     hrmsCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     hrmsCode.version = permission.version
//                 }
//                 if (eStoreCode) {
//                     eStoreCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     eStoreCode.view = (permission.permisssionValue & 1) ? true : false;
//                     eStoreCode.create = (permission.permisssionValue & 2) ? true : false;
//                     eStoreCode.update = (permission.permisssionValue & 4) ? true : false;
//                     eStoreCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     eStoreCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     eStoreCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     eStoreCode.version = permission.version
//                 }
// //       code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)
//                 // if (dashboardCode) {
//                 //     dashboardCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     dashboardCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     dashboardCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     dashboardCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     dashboardCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     dashboardCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     dashboardCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     dashboardCode.version = permission.version
//                 // }

//                 // if (alifAppCode) {
//                 //     alifAppCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     alifAppCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     alifAppCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     alifAppCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     alifAppCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     alifAppCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     alifAppCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     alifAppCode.version = permission.version
//                 // }
//                 // if (alifAppAdministrationCode) {
//                 //     alifAppAdministrationCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     alifAppAdministrationCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     alifAppAdministrationCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     alifAppAdministrationCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     alifAppAdministrationCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     alifAppAdministrationCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     alifAppAdministrationCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     alifAppAdministrationCode.version = permission.version
//                 // }
//             });

// Helper function to assign permissions
                    const assignPermissions = (code, permission) => {
                        if (code) {
                            code.userPrimaryPermissionId = permission.userPrimaryPermissionId;
                            code.view = (permission.permisssionValue & 1) ? true : false;
                            code.create = (permission.permisssionValue & 2) ? true : false;
                            code.update = (permission.permisssionValue & 4) ? true : false;
                            code.delete = (permission.permisssionValue & 8) ? true : false;
                            code.fullControl = (permission.permisssionValue & 15) ? true : false;
                            code.sendEmail = (permission.permisssionValue & 16) ? true : false;
                            code.version = permission.version;
                        }
                    };

                currentUserPermission.boUserPrimaryPermission.forEach((permission) => {
                    const supplierCode = find(purchaseDataSource, { moduleCode: permission.moduleCode });
                    const customerCode = find(salesDataSource, { moduleCode: permission.moduleCode });
                    const inventoryCode = find(inventoryDataSource, { moduleCode: permission.moduleCode });
                    const financeCode = find(financeDataSource, { moduleCode: permission.moduleCode });
                    const adminCode = find(adminDataSource, { moduleCode: permission.moduleCode });
                    const integrationCode = find(integrationDataSource, { moduleCode: permission.moduleCode });
                    const schoolCode = find(schoolDataSource, { moduleCode: permission.moduleCode });
                    const crmCode = find(crmDataSource, { moduleCode: permission.moduleCode });
                    const hrmsCode = find(hrmsDataSource, { moduleCode: permission.moduleCode });
                    const eStoreCode = find(eStoreDataSource, { moduleCode: permission.moduleCode });

                    // Assign permissions using the helper function
                    assignPermissions(supplierCode, permission);
                    assignPermissions(customerCode, permission);
                    assignPermissions(inventoryCode, permission);
                    assignPermissions(financeCode, permission);
                    assignPermissions(adminCode, permission);
                    assignPermissions(integrationCode, permission);
                    assignPermissions(schoolCode, permission);
                    assignPermissions(crmCode, permission);
                    assignPermissions(hrmsCode, permission);
                    assignPermissions(eStoreCode, permission);

                    // Code commented if needed in future uncomment it and use it for separate dashboard permission (if needed)
                    // const dashboardCode = find(dashboardDataSource, { moduleCode: permission.moduleCode });


                    // assignPermissions(dashboardCode, permission);


                    // const alifAppCode = find(alifAppDataSource, { moduleCode: permission.moduleCode });
                    // const alifAppAdministrationCode = find(alifAppAdministrationDataSource, { moduleCode: permission.moduleCode });
                    // assignPermissions(alifAppCode, permission);
                    // assignPermissions(alifAppAdministrationCode, permission);
                });

    //    checking for the available record to set the user view what they can see what they cant see 
                    function hasPermission(dataSource,permision) {
                        return dataSource.some(item => 
                            permision[item.moduleCode] > 0
                        );
                    }
                    
                    tabsToshow.Sales = hasPermission(salesDataSource,props.permissions?.primary);
                    tabsToshow.purchase = hasPermission(purchaseDataSource,props.permissions?.primary);
                    tabsToshow.Inventory = hasPermission(inventoryDataSource,props.permissions?.primary);
                    tabsToshow.finance = hasPermission(financeDataSource,props.permissions?.primary);
                    tabsToshow.administraction = hasPermission(adminDataSource,props.permissions?.primary);
                    tabsToshow.integration = hasPermission(integrationDataSource,props.permissions?.primary);
                    tabsToshow.school = hasPermission(schoolDataSource,props.permissions?.primary);
                    tabsToshow.crm = hasPermission(crmDataSource,props.permissions?.primary);
                    tabsToshow.hrms = hasPermission(hrmsDataSource,props.permissions?.primary);
                    tabsToshow.eStore = hasPermission(eStoreDataSource,props.permissions?.primary);
                    
                    console.log("Permission check result:", tabsToshow);
//  -------------------------------  ends  here

            this.state = {
                isUserUpdate: true,
                notifyUser: props.userData.notifyUser ? true : false,
                relationshipEmployeeId: props.userData.relationshipEmployeeId,
                password: props.userData.password,
                repeatPassword: props.userData.password,
                email: props.userData.email,
                selectedEmployee: {
                    firstName: props.userData.firstName,
                    lastName: props.userData.lastName,
                    emailAddress: props.userData.email,
                    middleName: props.userData.middleName
                },
                userData: props.userData,
                salesDataSource,
                purchaseDataSource,
                inventoryDataSource,
                financeDataSource,
                adminDataSource,
                integrationDataSource,
                schoolDataSource,
                crmDataSource,
                hrmsDataSource,
                eStoreDataSource,
                tabsToshow,
                // dashboardDataSource,

                // isSalesVisible: salesDataSource.some(item => item.view),
                // isPurchaseVisible: purchaseDataSource.some(item => item.view),
                // isInventoryVisible: inventoryDataSource.some(item => item.view),
                // isFianceVisible: financeDataSource.some(item => item.view),
                // isIntegrationVisible: integrationDataSource.some(item => item.view),
                // isSchoolVisible: schoolDataSource.some(item => item.view),
                // isCrmVisible: crmDataSource.some(item => item.view),
                // isHrmsVisible: hrmsDataSource.some(item => item.view),
                // isEstoreVisible: eStoreDataSource.some(item => item.view),

                // alifAppDataSource,
                // alifAppAdministrationDataSource,
                
                version: currentUserPermission.version,
                userPermissionId: currentUserPermission.userPermissionId
            }
        }
    }

    componentDidMount() {

        const userInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 200,
        };
        this.props.fetchSalutation(payload);
        this.props.fetchAllEmployees(payload);
        // this.props.getAllStudentForm(payload);
    }


    render() {
        return (
            <ManageUserComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        salutations: state.common.salutations,
        allEmployee: state.settings.userForAlpideAccess,
        // isAlifApp: state.common.isAlifApp,
        // studentFormList: state.membershipReducer.studentFormList,
        permissions: state.auth.permissions,
        listLoading: state.common.listLoading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalutation,
    createOrUpdteUser,
    fetchAllEmployees,
    deleteEmployee,
    showModal,
    hideModal,
    getAllStudentForm
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
