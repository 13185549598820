import React, {useState} from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import "./index.scss";
import {Row,Col, Button, Divider} from "antd";
import Icon1 from "../../../assets/images/icon/eazypostIcon.png";
import Icon2 from "../../../assets/images/icon/shipRocketIcon.png";
import EasyPost from "../../../containers/shipping/easyPost";
import { TextBox } from '../../general/TextBox';
import { ErrorMsg } from '../../general/ErrorMessage';

function ShippingComp(props) {



  const ShipRocketModal = (props) =>{
    const [carrierName, setCarrierName] = useState(props.carrierName || '');
    const [baseUrl, setBaseUrl] = useState(props.baseUrl || '');
    const [apiKey, setApiKey] = useState(props.apiKey || '');
    const [apiKeySecret, setApiKeySecret] = useState(props.apiKeySecret || '');
    const [submittedOnce, setSubmittedOnce] = useState(false);
  
    const handleConnect = () => {
      setSubmittedOnce(true);
  
      if (!carrierName || !baseUrl || !apiKey || !apiKeySecret) {
        return;
      }
      const payload = {
        relationshipId: props.companyInfo?.relationshipId,
        apiKey: apiKey,
        carrierName: carrierName,
        secretKey: apiKeySecret,
      };
  
      props.saveShipStationConfiguration(payload);
    };

    return (
      <>
      <Row gutter={[16, 16]}>

        <Col span={12}>
          <div className="i-label">Carrier Name</div>
          <TextBox
            type="text"
            className={submittedOnce && !carrierName ? 'input-text-error' : ''}
            placeholder="Carrier Name"
            value={carrierName}
            onChange={(e) => setCarrierName(e.target.value)}
          />
          <ErrorMsg
            validator={() => !submittedOnce || carrierName}
            message="This field is required"
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Base Url</div>
          <TextBox
            type="text"
            className={submittedOnce && !baseUrl ? 'input-text-error' : ''}
            placeholder="Base URL"
            value={baseUrl}
            onChange={(e) => setBaseUrl(e.target.value)}
          />
          <ErrorMsg
            validator={() => !submittedOnce || baseUrl}
            message="This field is required"
          />
        </Col>

        <Col span={12}>
          <div className="i-label">API Key</div>
          <TextBox
            type="text"
            placeholder="API Key"
            className={submittedOnce && !apiKey ? 'input-text-error' : ''}
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
          <ErrorMsg
            validator={() => !submittedOnce || apiKey}
            message="This field is required"
          />
        </Col>

        <Col span={12}>
          <div className="i-label">API Secret Key</div>
          <TextBox
            type="text"
            className={submittedOnce && !apiKeySecret ? 'input-text-error' : ''}
            value={apiKeySecret}
            onChange={(e) => setApiKeySecret(e.target.value)}
            placeholder="API Secret Key"
          />
          <ErrorMsg
            validator={() => !submittedOnce || apiKeySecret}
            message="This field is required"
          />
        </Col>
      </Row>
      <Divider className='mt10 mb10' />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => props.popModalFromStack()}>Cancel</Button>
        <Button type="primary" onClick={handleConnect}>
          Connect
        </Button>
      </div>
    </>
    )
  }


    return (
      <div className="container">
        <Row>
          <Col span={24}>
            <div className="header">
              <h2>Shipping</h2>
              <p>Manage your company shipping</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
            <div className="paymentOptionArea">
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon1} alt="" />
                  <h2>Easypost</h2>
                </div>
                <div className='buttonArea'>
                  <Button 
                    type='primary'
                    onClick={()=>{
                      props.pushModalToStack({
                        title:"Shipping Channel EasyPost",
                        modalBody: <EasyPost {...props} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'new-transaction-wrapper'
                      })
                    }}
                    // className='notActiveButton'
                  >
                    Connect
                  </Button>
                </div>
                {/* <div className='notActive'></div> */}
              </div>

              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon2} alt="" />
                  <h2>Shiprocket</h2>
                </div>
                <div className='buttonArea'>
                  <Button 
                    type='primary'
                    onClick={()=>{
                      props.pushModalToStack({
                        title:"Shipping Channel ShipRocket",
                        modalBody: <ShipRocketModal {...props} />,
                        width: '60%',
                        hideFooter: true,
                      })
                    }}
                  >
                    Connect
                  </Button>
                </div>
                {/* <div className='notActive'></div> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );

}

export default injectIntl(ShippingComp);