import React, { useState } from "react";
import PageBreadcrumb from "../PageBreadcrumb";
import "./integration.scss";
import { FormattedMessage } from "react-intl";

import MenuIcon1 from "../../assets/images/icon/paymentGateWayIcon.png";
import MenuIcon2 from "../../assets/images/icon/marketPlaceIcon.png";
import MenuIcon3 from "../../assets/images/icon/foodIcon.png";
import MenuIcon4 from "../../assets/images/icon/shipingIcon.png";
import MenuIcon5 from "../../assets/images/icon/accountingIcon.png";
// import Enquiry from "../page/Enquiry";
import MarketPlace from "../../containers/integrations/MarketPlaceTab";
import Food from "../../containers/integrations/FoodTab";
import Shipping from "../../containers/integrations/ShippingTab";
import Accounting from "../../containers/integrations/AccountingTab";
import Payment from "../../containers/integrations/Payment";
import EmailIcon from "../../assets/images/email-icon.png";
import Email from "../../containers/integrations/Email";
import queryString from 'query-string';


const Integrations = (props) => {

  const linkProps = queryString.parse(decodeURIComponent(props.location.search)) || {};
  console.log(`vvv`, linkProps)

  const [componentToDisplay, setcomponentToDisplay] = useState(linkProps.active);
  
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage
          id="integrations.pageName.integration"
          defaultMessage=""
        />
      ),
    },
    {
      name: componentToDisplay,
    },
  ];

  const inlineStyle = {
    width: "26.947px",
    height: "26.947px",
  };

  return (
    <div className="StoreSettings-container">
      <div className="page-title-bar">
        <div className="page-title">Integration</div>
        <div className="sep" />
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>
      <div className="profile-container">
        <div className="sidenav">
          <div className="settings-body">
            <div className="settings-body-child">
              <div className="child-body">
                {/* <div className="lead-tile" onClick={() => openPreferences(props)}> */}
                <div
                  className={`lead-tile ${
                    componentToDisplay === "Payment Gateway" ? "lead-back" : ""
                    } ${componentToDisplay === "" ? "lead-back" : ""}`}
                    onClick={() => {
                      setcomponentToDisplay("Payment Gateway");
                      props.history.push({
                        search: '?active=Payment Gateway'
                      });
                    }
                  }
                >
                  <div className="tile-icon1">
                    <img src={MenuIcon1} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="integrations.menulabel.paymentGateWay"
                      defaultMessage=""
                    />
                  </div>

                  <div className="tile-description1">
                    You are powered with creating multiple users for your
                    business.
                  </div>
                </div>

                {/* <div className="lead-tile" onClick={() => openSetTimings(props)}> */}
                <div
                  className={`lead-tile ${
                    componentToDisplay === "Marketplace" ? "lead-back" : ""
                  } `}
                  onClick={() =>{
                    setcomponentToDisplay("Marketplace");
                    props.history.push({
                      search: '?active=Marketplace'
                    })
                  }
                  }
                >
                  <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="integrations.menulabel.marketPlace"
                      defaultMessage=""
                    />
                  </div>
                  <div className="tile-description1">
                    You are powered with creating multiple users for your
                    business.
                  </div>
                </div>

                {/* <div className="lead-tile" onClick={() => openDomains(props)}> */}
                {/* <div className={`lead-tile ${componentToDisplay==='Food' ? 'lead-back' : ''} `}
                onClick={()=>setcomponentToDisplay('Food')}>
                  <div className="tile-icon1">
                  <img src={MenuIcon3} style={inlineStyle}  alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="integrations.menulabel.food"
                      defaultMessage=""
                    />
                  </div>
                  <div className="tile-description1">
                  You are powered with creating multiple users for your business. 
                  </div>
                </div> */}

                {/* <div className="lead-tile" onClick={() => openSupportAndSocial(props)}> */}
                <div
                  className={`lead-tile ${
                    componentToDisplay === "Shipping" ? "lead-back" : ""
                  }`}
                  onClick={() => {
                    setcomponentToDisplay("Shipping");
                    props.history.push({
                      search: '?active=Shipping'
                    })
                  }
                  }
                >
                  <div className="tile-icon1">
                    <img src={MenuIcon4} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="integrations.menulabel.shipping"
                      defaultMessage=""
                    />
                  </div>
                  <div className="tile-description1">
                    You are powered with creating multiple users for your
                    business.
                  </div>
                </div>

                <div
                  className={`lead-tile ${
                    componentToDisplay === "Accounting" ? "lead-back" : ""
                  }`}
                  onClick={() => {
                    setcomponentToDisplay("Accounting");
                    props.history.push({
                      search: '?active=Accounting'
                    })
                  }
                  }
                >
                  <div className="tile-icon1">
                    <img src={MenuIcon5} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="integrations.menulabel.accounting"
                      defaultMessage=""
                    />
                  </div>
                  <div className="tile-description1">
                    You are powered with creating multiple users for your
                    business.
                  </div>
                </div>

                <div
                  className={`lead-tile ${
                    componentToDisplay === "Email" ? "lead-back" : ""
                  }`}
                  onClick={() => {
                    setcomponentToDisplay("Email");
                    props.history.push({
                      search: '?active=Email'
                    })
                  }
                  }
                >
                  <div className="tile-icon1">
                    <img src={EmailIcon} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">Email</div>
                  <div className="tile-description1">
                    You are powered with creating multiple users for your
                    business.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-body" style={{ padding: "20px" }}>
          {componentToDisplay === "Payment Gateway" && (
            <Payment key="paymentGateway" />
          )}
          {componentToDisplay === "Marketplace" && (
            <MarketPlace key="marketplace" />
          )}
          {/* {componentToDisplay === "Food" && <Food />} */}

          {componentToDisplay === "Shipping" && <Shipping />}
          {componentToDisplay === "Accounting" && <Accounting />}
          {componentToDisplay === "Email" && <Email />}

          {/* {`lead-tile ${componentToDisplay==='Policies' ? 'lead-back' : ''} ${componentToDisplay==='' ? 'lead-back' : ''}`} */}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
