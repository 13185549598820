import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProducts, pushModalToStack,  } from '../../../../actions/commonActions';
import FeedbackDrawer from '../../../../components/drawer/inventory/FeedbackDrawer';
import { fetchPublishAndUnpublishList } from '../../../product/RatingAndReview/action';
import { updateFeedback,deleteFeedbackImgaes } from './action';


class FeedbackDrawerCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      activeKey:"1",
      selectedItems:[],
      ...(this.props.feedbackData || {})

    };
  }

  componentWillReceiveProps(props) {
  
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
      this.props.fetchProducts(payload)
  }

  render() {
    return (
      <>
       <FeedbackDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
 
    products: state.salesInvoice.products,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPublishAndUnpublishList,
    fetchProducts,
    updateFeedback,
    pushModalToStack,
    deleteFeedbackImgaes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDrawerCon);
