import React, { Component } from "react";
import { Steps, Button, Col, Row, Divider, Empty, Skeleton } from 'antd';
import './style.scss';
import { FormattedMessage, } from 'react-intl';
import { CONSTANTS } from '../../../../../static/constants';
import {
    USPSLOGO,
    CareemLogo,
    UpsLogo,
    FedexLogo,
    ImagePlaceholder,
    AustraliaPostLogo,
    CanadaPostLogo,
    ChinaPostLogo,
    DeutschePost,
    DhlLogo,
    JapanPostLogo,
    RoyalMailLogo,
    Accurate,
    Amazon,
    Apc,
    Asendia,
    BetterTrucks,
    Canpar,
    Cdl,
    Chronopost,
    CirroEcom,
    CloudSort,
    LSO,
    CourierExpress,
    CourierPlease,
    DaiPost,
    DeliverIt,
    DhlEcom,
    DhlExpress,
    DpdLogo,
    EpostGlobal,
    Estafeta,
    DhlPacket,
    EvriLogo,
    Fastway,
    FirstChoice,
    FirstMile,
    Flexport,
    GIO,
    GSO,
    Hailify,
    InterlinkExpress,
    JetLogo,
    JitsuLogo,
    JpPost,
    KuronekoYamato,
    LaPoste,
    Lasership,
    LoomisExpress,
    Maergo,
    Newgistics,
    Omniparcel,
    Ontrac,
    Optima,
    OsmWorldwide,
    Pandion,
    ParcelForce,
    Purolator,
    RoyalMail,
    Sendle,
    Passport,
    Postnl,
    TforceLogistics,
    SfExpress,
    Smartkargo,
    Speedee,
    SwyftLogo,
    TollLogo,
    UdsLogo,
    UpsIParcel,
    UpsMailInnovations,
    Ups,
    Veho,
    Yanwen
} from '../../../../../assets/images';
import { fixedDecimalAndFormateNumber, getCurrencySymbol, getMomentDateForUIReadOnly, showToasterMessage, } from '../../../../../utils';
import * as find from 'lodash.find';
import moment from "moment-timezone";
import { Dropdown } from "../../../../general/Dropdown";

const GenerateShippingLabelShiprocket = (props) => {

    const { carrierDetails, shiprocketCarrierDetails={}, current=0 } = props;
    const {txData={}} = props.data;

    const  { toAddress={}, fromAddress={}} = carrierDetails || {}
    const { data: { available_courier_companies = [] } = {} } = shiprocketCarrierDetails || {};

    const getIconForCarrier = (carrierName) => {
        const imgStyle = { maxWidth: 100, maxHeight: 40 }
        const cName = (carrierName || '').toLowerCase().replace(/\s/g, "");
        switch (true) {
            case cName.includes('usps'):
                return <img src={USPSLOGO} alt="source-logo" style={imgStyle} />
            case cName.includes('careem'):
                return <img src={CareemLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fedex'):
                return <img src={FedexLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={UpsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('australiapost'):
                return <img src={AustraliaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('canadapost'):
                return <img src={CanadaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('chinapost'):
                return <img src={ChinaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('deutschepost'):
                return <img src={DeutschePost} alt="source-logo" style={imgStyle} />
            case cName.includes('dhl'):
                return <img src={DhlLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('japanpost'):
                return <img src={JapanPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMailLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('accurate'):
                return <img src={Accurate} alt="source-logo" style={imgStyle} />
            case cName.includes('amazon'):
                return <img src={Amazon} alt="source-logo" style={imgStyle} />
            case cName.includes('apc'):
                return <img src={Apc} alt="source-logo" style={imgStyle} />
            case cName.includes('asendiausa'):
                return <img src={Asendia} alt="source-logo" style={imgStyle} />
            case cName.includes('bettertrucks'):
                return <img src={BetterTrucks} alt="source-logo" style={imgStyle} />
            case cName.includes('canpar'):
                return <img src={Canpar} alt="source-logo" style={imgStyle} />
            case cName.includes('cdl'):
                return <img src={Cdl} alt="source-logo" style={imgStyle} />
            case cName.includes('chronopost'):
                return <img src={Chronopost} alt="source-logo" style={imgStyle} />
            case cName.includes('cirroecom'):
                return <img src={CirroEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('cloudsort'):
                return <img src={CloudSort} alt="source-logo" style={imgStyle} />
            case cName.includes('lso'):
                return <img src={LSO} alt="source-logo" style={imgStyle} />
            case cName.includes('courierexpress'):
                return <img src={CourierExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('courierplease'):
                return <img src={CourierPlease} alt="source-logo" style={imgStyle} />
            case cName.includes('daipost'):
                return <img src={DaiPost} alt="source-logo" style={imgStyle} />
            case cName.includes('deliverit'):
                return <img src={DeliverIt} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlecom'):
                return <img src={DhlEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlexpress'):
                return <img src={DhlExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlpacket'):
                return <img src={DhlPacket} alt="source-logo" style={imgStyle} />
            case cName.includes('dpdlogo'):
                return <img src={DpdLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('epostglobal'):
                return <img src={EpostGlobal} alt="source-logo" style={imgStyle} />
            case cName.includes('estafeta'):
                return <img src={Estafeta} alt="source-logo" style={imgStyle} />
            case cName.includes('evrilogo'):
                return <img src={EvriLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fastway'):
                return <img src={Fastway} alt="source-logo" style={imgStyle} />
            case cName.includes('firstchoice'):
                return <img src={FirstChoice} alt="source-logo" style={imgStyle} />
            case cName.includes('firstmile'):
                return <img src={FirstMile} alt="source-logo" style={imgStyle} />
            case cName.includes('flexport'):
                return <img src={Flexport} alt="source-logo" style={imgStyle} />
            case cName.includes('gio'):
                return <img src={GIO} alt="source-logo" style={imgStyle} />
            case cName.includes('gso'):
                return <img src={GSO} alt="source-logo" style={imgStyle} />
            case cName.includes('hailify'):
                return <img src={Hailify} alt="source-logo" style={imgStyle} />
            case cName.includes('interlinkexpress'):
                return <img src={InterlinkExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('jetlogo'):
                return <img src={JetLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jitsulogo'):
                return <img src={JitsuLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jppost'):
                return <img src={JpPost} alt="source-logo" style={imgStyle} />
            case cName.includes('kuronekoyamato'):
                return <img src={KuronekoYamato} alt="source-logo" style={imgStyle} />
            case cName.includes('laposte'):
                return <img src={LaPoste} alt="source-logo" style={imgStyle} />
            case cName.includes('lasership'):
                return <img src={Lasership} alt="source-logo" style={imgStyle} />
            case cName.includes('loomisexpress'):
                return <img src={LoomisExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('maergo'):
                return <img src={Maergo} alt="source-logo" style={imgStyle} />
            case cName.includes('newgistics'):
                return <img src={Newgistics} alt="source-logo" style={imgStyle} />
            case cName.includes('omniparcel'):
                return <img src={Omniparcel} alt="source-logo" style={imgStyle} />
            case cName.includes('ontrac'):
                return <img src={Ontrac} alt="source-logo" style={imgStyle} />
            case cName.includes('pptima'):
                return <img src={Optima} alt="source-logo" style={imgStyle} />
            case cName.includes('osmworldwide'):
                return <img src={OsmWorldwide} alt="source-logo" style={imgStyle} />
            case cName.includes('pandion'):
                return <img src={Pandion} alt="source-logo" style={imgStyle} />
            case cName.includes('parcelforce'):
                return <img src={ParcelForce} alt="source-logo" style={imgStyle} />
            case cName.includes('passport'):
                return <img src={Passport} alt="source-logo" style={imgStyle} />
            case cName.includes('postnl'):
                return <img src={Postnl} alt="source-logo" style={imgStyle} />
            case cName.includes('purolator'):
                return <img src={Purolator} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMail} alt="source-logo" style={imgStyle} />
            case cName.includes('sendle'):
                return <img src={Sendle} alt="source-logo" style={imgStyle} />
            case cName.includes('tforcelogistics'):
                return <img src={TforceLogistics} alt="source-logo" style={imgStyle} />
            case cName.includes('sfexpress'):
                return <img src={SfExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('smartkargo'):
                return <img src={Smartkargo} alt="source-logo" style={imgStyle} />
            case cName.includes('speedee'):
                return <img src={Speedee} alt="source-logo" style={imgStyle} />
            case cName.includes('swyftlogo'):
                return <img src={SwyftLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('tolllogo'):
                return <img src={TollLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('udslogo'):
                return <img src={UdsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('upsiparcel'):
                return <img src={UpsIParcel} alt="source-logo" style={imgStyle} />
            case cName.includes('upsmailinnovations'):
                return <img src={UpsMailInnovations} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={Ups} alt="source-logo" style={imgStyle} />
            case cName.includes('veho'):
                return <img src={Veho} alt="source-logo" style={imgStyle} />
            case cName.includes('yanwen'):
                return <img src={Yanwen} alt="source-logo" style={imgStyle} />
            default:
                return <span style={{ fontSize: '18px', fontWeight: 700 }}>{carrierName}</span>
        }
    }
    
    const getShippingAddress = (obj={}) => {
        return <>
            {
                obj.street1
                    ? <span className='billing-address'>{obj.street1}
                    </span>
                    : ''
            }
            {
                obj.street2
                    ? <span className='billing-address'> {obj.street2}
                    </span>
                    : ''
            }
            {
                obj.city
                    ? <div className='billing-address'>
                        {
                            `${obj.city || ''}${obj.state ? `,  ${obj.state}` : ''}${obj.zip ? `, ${obj.zip}` : ''}`
                        }
                    </div>
                    : ''
            }
            {
                obj.country
                    ? <div className='billing-address'>
                        {
                            `${obj.country || ''}`
                        }
                    </div>
                    : ''
            }
        </>
    }

    const getRatesForCarriers = () =>{

        const {deliveyNotesData} = props;
        const relationshipAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const shippingAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

        const payloadShipRocket = {
          relationshipId: props.companyInfo?.relationshipId || 0,
          pickup_postcode: "227817" || relationshipAddress.zipCode,
          delivery_postcode: "201301" || shippingAddress.zipCode,
          cod: 0,
          weight: deliveyNotesData.weight,
          length: deliveyNotesData.length,
          breadth: deliveyNotesData.width,
          height: deliveyNotesData.height,
        }

        props.getCarrierPricesShiprocket(payloadShipRocket);
    }

    const Step = Steps.Step;

    const steps = [
        {
            title: "Select Channel",
            key: 0
        },
        {
            title: <FormattedMessage id='carriarsPrice.text' />,
            key: 1
        },
        {
            title: <FormattedMessage id='downloadShippingLabel.text' />,
            key: 2
        }
    ];

    return (<>
        <Row>
            <Col span={8}>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                    <Row>
                        <Col span={12}>
                            Customer Name
                        </Col>
                        <Col span={12}>
                            {txData.customerName || '-'}
                        </Col>

                        <Col span={12}>
                            Shipment Size
                        </Col>
                        <Col span={12}>
                            {txData?.length && txData?.width && txData?.height ? `${txData?.length} x ${txData?.width} x ${txData?.height}` : '-'} {txData?.dimensionUnit ? txData.dimensionUnit : 'Inches'}
                        </Col>

                        <Col span={12}>
                            Shipment Weight
                        </Col>
                        <Col span={12}>
                            {txData.weight || 0} {txData.weightUnit || 'Ounces'}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>

            <Col span={8}>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                    <Row>
                        <Col span={12}>
                            Order Number
                        </Col>
                        <Col span={12}>
                            {txData.shipmentNumber || '-'}
                        </Col>

                        <Col span={12}>
                            Order Date
                        </Col>
                        <Col span={12}>
                            {txData?.dateCreated ? getMomentDateForUIReadOnly(txData.dateCreated) : "-"}
                        </Col>

                        <Col span={12}>
                            Order Amount
                        </Col>
                        <Col span={12}>
                            {getCurrencySymbol(props?.companyInfo?.currencyCode)}{fixedDecimalAndFormateNumber(props.soDetail?.salesOrderTotalAmount || 0)}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>

            <Col span={8}>
                <Skeleton loading={props.detailLoading || props.isLoadingCarriarList} paragraph={{ rows: 2 }} active>
                    <Row gutter={[0, 8]}>
                        <Col span={12}>
                            Ship from
                        </Col>
                        <Col span={12} style={{lineHeight: '1.3em'}}>
                            {getShippingAddress(current === 1 ? props.buyShipmentDetails?.fromAddress : fromAddress)}
                        </Col>
                        <Col span={12}>
                           Ship to
                        </Col>
                        <Col span={12} style={{lineHeight: '1.3em'}}>
                            {getShippingAddress(current === 1 ? props.buyShipmentDetails?.toAddress : toAddress)}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>
        </Row>

        <Divider className="mt10 mb20" />

        <Steps current={current} status={props.stepError ? 'error' : 'process'}>
            {steps.map(item => <Step key={item.key} title={item.title} />)}
        </Steps>

        {props.stepError ? <div className="required">{props.stepError}</div> : ''}

        {
            current === 0 ? <>
                <Row className="mt30">
                    <Col span={12}>
                        Select Channel
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={props.shiprocketIntgratedChannels || []}
                            valueKeyName='name'
                            optionKeyName='id'
                            value={''}
                            placeholder='Select Channel'
                            onSelect={(optionValue, option) => {
                                let selectedChannel = find(props.shiprocketIntgratedChannels || [], { channel_id: option.value }) || {};
                                props.updateState({
                                    selected: selectedChannel,
                                })
                            }}
                        />
                    </Col>
                </Row>
                <div className="fot-fix" style={{ maxWidth: '100%' }}>
                    <div className="text-center">
                        <Button 
                            type="primary"
                            onClick={()=>{
                                const billingAddress = props.deliveyNotesData?.boLocationShipmentList?.find(obj=>obj.locationType === CONSTANTS.BILLING_ADDRESS)
                                const payload = {
                                    order_id: props.deliveyNotesData.shipmentMasterId,
                                    order_date: moment().format("YYYY-MM-DD"),
                                    pickup_location: "Primary",
                                    comment: "Handle with care",
                                    billing_customer_name: txData.customerName,
                                    billing_last_name: "",
                                    billing_address: billingAddress?.streetAddress1 || "123 MG Road",
                                    billing_address_2: billingAddress?.streetAddress2 || "Apt 4B",
                                    billing_city: billingAddress?.cityName || "Bengaluru",
                                    billing_pincode: billingAddress?.zipCode || "560001",
                                    billing_state: billingAddress?.stateName || "KA",
                                    billing_country: billingAddress?.countryName || "India",
                                    billing_email: "john.doe@example.com",
                                    billing_phone: "9876543210",
                                    shipping_is_billing: 1,
                                    shipping_customer_name: "John",
                                    shipping_last_name: "Doe",
                                    shipping_address: "123 MG Road",
                                    shipping_address_2: "Apt 4B",
                                    shipping_city: "Bengaluru",
                                    shipping_pincode: "560001",
                                    shipping_country: "India",
                                    shipping_state: "KA",
                                    shipping_email: "john.doe@example.com",
                                    shipping_phone: "9876543210",
                                    order_items: [
                                        {
                                            name: "Product 1",
                                            sku: "SKU001",
                                            units: 2,
                                            selling_price: 500.00,
                                            discount: 0,
                                            tax: "18.00",
                                            hsn: "123456"
                                        }
                                    ],
                                    payment_method: "COD",
                                    shipping_charges: "10.00",
                                    giftwrap_charges: "5.00",
                                    transaction_charges: "2.00",
                                    total_discount: "15.00",
                                    sub_total: "135.00",
                                    length: "30",
                                    breadth: "20",
                                    height: "15",
                                    weight: "1.5"
                                }

                                props.createShiprocketShipmentOrder(payload, props, getRatesForCarriers);
                            }}
                        > 
                            Create Order 
                        </Button>
                    </div>
                </div>
            </>
            :
            ''
        }
        
        {
            current === 1 ?
                <Skeleton loading={props.listLoading} paragraph={{ rows: 5 }} active>
                    <>
                        <table style={{ width: '100%' }} className='mt20'>
                            <thead style={{ borderBottom: '1px solid gray' }}>
                                <tr>
                                    <th style={{ width: '50px' }}></th>
                                    <th>Carrier & Service</th>
                                    <th>Delivery Date</th>
                                    <th>Delivery in (days)</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    available_courier_companies?.length ?
                                        available_courier_companies.map((item, i) => {
                                            return <tr key={'carr' + i} style={{ height: '40px' }}>
                                                <td>
                                                    <input type="radio" id='regular' name="optradio" onClick={() => {
                                                            props.updateState({ selectedCarrierItem: item });
                                                        }}
                                                    />
                                                </td>
                                                <td>{getIconForCarrier(item.courier_name)}</td>
                                                <td>{item.etd}</td>
                                                <td>{item.estimated_delivery_days}</td>
                                                <td>{getCurrencySymbol(shiprocketCarrierDetails?.currency || 'USD')}{item.rate}</td>
                                            </tr>
                                        })
                                        :
                                        <tr key="empty-data-box">
                                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                                <Empty />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        <div className="fot-fix" style={{ maxWidth: '100%' }}>
                            <div className="text-center">
                                <Button
                                    disabled={props.stepError}
                                    type="primary"
                                    onClick={() => {
                                        if (!(Object.keys(props?.selectedCarrierItem || {}).length)) {
                                            return showToasterMessage({ description: 'Please select carrier service' });
                                        } else {
                                            const payload = {
                                                shipment_id: props.shiprocketOrderDetails?.shipment_id,
                                                courier_id: props.selectedCarrierItem?.courier_company_id,
                                                status: props.shiprocketOrderDetails.status || ""
                                            }
                                            props.generateAwbForShipment(payload, props, getRatesForCarriers );
                                        }

                                    }}
                                >
                                    <FormattedMessage id='shippingLabel.buyShipping.button' defaultMessage=' ' />
                                    {props.selectedCarrierItem?.rate ? `(${getCurrencySymbol(shiprocketCarrierDetails?.currency)}${props.selectedCarrierItem?.rate})` : ''}
                                </Button>
                            </div>
                        </div>
                    </>
                </Skeleton>
                : 
                ''
        }

        {
            current === 2 ? 
                <div className='mt20 text-center'>
                    <img src={props.buyShipmentDetails?.postageLabel?.labelUrl} alt='Shipping Label' style={{ height: '300px', width: '200px' }}></img><br />
                    <a
                        href={props.buyShipmentDetails?.tracker?.publicUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Track your shipment
                    </a>
                </div>
                : 
                ''
        }

        <div className="inner-wrapper" style={{ paddingBottom: '40px' }} />
    </>
    );
}

export default GenerateShippingLabelShiprocket;