import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd'
import * as find from 'lodash.find';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, CONSTANTS_TRANSACTIONS, ICONS } from '../../../../../static/constants';
import CertificateOfConformity from '../../../../../containers/modal/modalBody/customer/CertificateOfConformity';
import { getCurrentDateForBackend, showToasterMessage } from '../../../../../utils'
import StandardTemplate from "./standard";
import Template2 from "./template2";
import { Dropdown as CustomDropDown } from '../../../../general/Dropdown';
import ShippingInvoiceDetail from '../../../../../containers/modal/modalBody/customer/ShippingInvoiceDetail';
import Shipment from '../../../../../containers/customer/salesOrder/shipment';

//import { Link } from 'react-router-dom';

const ShipmentDetails = (props) => {
    const { shipmentData, soData = {}, companyInfo, deliveyNotesData, templateName = 'Professional' } = props;
    // const relationshipAddress = find(companyInfo.boLocationList, { locationId: parseInt(shipmentData.billingLocationId) }) || {};
    // const shippingAddress = find(customerData.boLocationList, {locationId: parseInt(shipmentData.shippingLocationId)}) || {};

    const relationshipAddress = find((deliveyNotesData || {}).boLocationShipmentList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find((deliveyNotesData || {}).boLocationShipmentList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find((deliveyNotesData || {}).boLocationShipmentList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    const additionalInfo = ((deliveyNotesData || {}).additionalInfoList || [])[0] || {}
    // let customerSOPackageDetailsList = [];

    // deliveyNotesData.forEach((e) => {
    //     e.customerSOPackageDetailsList.forEach((ele) => {
    //         ele.salesOrderNumber = e.salesOrderNumber;
    //         ele.packageNumber = e.packageNumber;
    //     });
    //     customerSOPackageDetailsList = customerSOPackageDetailsList.concat(e.customerSOPackageDetailsList);
    // });
    const [showComp, setShowComp] = useState(false);
    useEffect(()=>{
      const delay = setTimeout(()=>{
        setShowComp(true);
      }, 500);
      return ()=> clearTimeout(delay);
    }, []);
    const getPayloadForPdf = () => {
        // deliveyNotesData.forEach((ele)=>{
        //     ele.customerSOPackageDetailsList.forEach((ele2)=>{
        //         ele2.packageObj = {};
        //     });
        // });
        // const _customerSOPackageDetailsList = [];
        // customerSOPackageDetailsList.forEach((d)=> {
        //     _customerSOPackageDetailsList.push({...d, packageObj: {}})
        // });

        return {
            templateName: templateName,
            fileName: (shipmentData.shipmentNumber || 'Shipment') + '.pdf',
            transactionName: 'deliveryNotes',
            // documentName: shipmentData.documentName || 'Shipment',
            documentName: 'Shipment',
            relationshipAddress: relationshipAddress,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
            subTotal: "",
            soData: soData,
            companyInfo: companyInfo,
            shipmentData: shipmentData,
            deliveyNotesData:  (deliveyNotesData || {}).customerSOShipmentDetailsList,
            additionalInfo: additionalInfo,
            isColumnEmpty: {}
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () => {
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.customerId = shipmentData.customerId;
        payload.invoiceMasterId = shipmentData.invoiceMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SHIPMENT,
        });
    };

    const openShippingInvoiceDetails = (data) => {
        const shipmentPayload = {
            customerId: data.customerId,
            shipmentMasterId: data.shipmentMasterId
        }
        props.pushModalToStack({
            modalBody: <ShippingInvoiceDetail {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail',
            handleSubmit: () => {
            }
        });
    }

    const openCertifcateOfConformity = (data) => {
        const shipmentPayload = {
            customerId: data.customerId,
            shipmentMasterId: data.shipmentMasterId
        }
        props.pushModalToStack({
            modalBody: <CertificateOfConformity {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail',
            handleSubmit: () => {
            }
        });
    }

    const addItemConfirmationModal = (props, text, color, txData) => {
        const { showModal, companyInfo } = props;
       
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
           
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            shipmentMasterId: txData.shipmentMasterId,
            customerId: txData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }



    const updateUserStatus = (props, txData) => {
        let payload = { formData: {}, txData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, txData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SHIPMENT },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, txData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const generateShippingLabel = (props, txData) => {
        let payload = { formData: {}, txData, boughtShipment: props.boughtShipment };
        let formData = payload.formData;
        formData.pageNumber = props.pageNumber;
        formData.pageSize = props.pageSize;
        const { pushModalToStack, boughtShipment } = props;

        const data = {
            title: (boughtShipment && boughtShipment.purchasedShipmnetEasyPostId) ? <FormattedMessage id='viewShippingLabel.text' /> :<FormattedMessage id='createShippingLabel.text' />,
            formData,
            hideFooter: true,
            style:{height: '800px'},
            // modalData: { modalType: MODAL_TYPE.SHIPPING_LABEL, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SHIPPING_LABEL },
            modalData: { modalType: MODAL_TYPE.SHIPPING_LABEL_SHIPROCKET, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SHIPPING_LABEL },
            handleSubmit: (formData = {}) => { 
                alert("hello");
            },
            width: '75%',
            wrapClassName: 'back-blur m_touch_top'
        };
        pushModalToStack(data);
    }

    const actionMenu = (param) => {
        return (
            <Menu className="cell-action-dropdown">
                {shipmentData.status === "delivered" ?
                    <Menu.Item key="0"
                        onClick={() => {
                            props.showModal({
                                modalBody: "Are you sure you want to change the status?",
                                handleSubmit: () => {
                                    props.markAsDelivered({
                                        relationshipId: (props.companyInfo || {}).relationshipId,
                                        shipmentMasterId: shipmentData.shipmentMasterId,
                                        status: "shipped",
                                        lableColor: 'shipped',
                                        customerId: shipmentData.customerId,
                                        createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                    }, props);
                                    props.popModalFromStack();
                                }
                            })
                        }}
                    >
                        <FormattedMessage id='modal.txDetails.shipment.markAsShipped' defaultMessage='' />
                    </Menu.Item> 
                    :
                    <Menu.Item key="0"
                        onClick={() => {
                            props.showModal({
                                modalBody: "Are you sure you want to change the status?",
                                handleSubmit: () => {
                                    props.markAsDelivered({
                                        relationshipId: (props.companyInfo || {}).relationshipId,
                                        shipmentMasterId: shipmentData.shipmentMasterId,
                                        status: "delivered",
                                        lableColor: 'delivered',
                                        customerId: shipmentData.customerId,
                                        createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                    }, props);
                                    props.popModalFromStack();
                                }
                            })
                        }}
                    >
                        <FormattedMessage id='modal.txDetails.shipment.markAsDelivered' defaultMessage='' />
                    </Menu.Item>
                }
                <Menu.Item key="1" onClick={() => {
                    let soMasterIdlist = [];
                    (deliveyNotesData.customerSOShipmentDetailsList || []).forEach((ele) => {
                        if (soMasterIdlist.indexOf(ele.salesOrderMasterId) < 0) {
                            soMasterIdlist.push(ele.salesOrderMasterId);
                        }
                    });
                    
                    props.canInvoiceBeGenerated(deliveyNotesData, props, soMasterIdlist);
                    // props.history.push({
                    //     pathname: shipmentData.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
                    //     state: {
                    //         customerId: shipmentData.customerId,
                    //         shipmentMasterId: shipmentData.shipmentMasterId
                    //     }
                    // })
                }}>
                    <FormattedMessage id='modal.txDetails.shipment.convertToInvoice' defaultMessage='' />
                </Menu.Item>
                <Menu.Item key="2" onClick={() => {
                    openShippingInvoiceDetails(shipmentData);
                }}>
                    <FormattedMessage id='modal.txDetails.shipment.shippingInvoice' defaultMessage='' />
                </Menu.Item>
                <Menu.Item key="3" onClick={() => {
                    openCertifcateOfConformity(deliveyNotesData);
                }}>
                    <FormattedMessage id='modal.certificateOfConformity.title' defaultMessage='' />
                </Menu.Item>
                <Menu.Item key="4" onClick={() => {
                    updateActualDeliveryDate(props, shipmentData);
                }}><FormattedMessage id='modal.txDetails.shipment.updateDeliveryDate' defaultMessage='' />
                </Menu.Item>
                <Menu.Item key="5" onClick={() => {
                    updateUserStatus(props, deliveyNotesData);
                }}><FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />
                </Menu.Item>
                {/* <Menu.Item key="6" onClick={() => {
                    generateShippingLabel(props, deliveyNotesData);
                }}><FormattedMessage id='generateShippingLabel.text' defaultMessage='' />
                </Menu.Item> */}
            </Menu >)
    }

    const updateActualDeliveryDate = (props, shipmentData) => {
        let payload = { formData: { textBoxValue: (shipmentData.actualDeliveryDate ? new Date(shipmentData.actualDeliveryDate) : new Date()) } };
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.actualDeliveryDate' defaultMessage='' />,
            formData,
            modalData: {
                modalType: MODAL_TYPE.ACTUAL_DELIVERY_DATE,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
                props.updateActualDeliveryDate({
                    actualDeliveryDate: getCurrentDateForBackend(formData.textBoxValue),
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    shipmentMasterId: shipmentData.shipmentMasterId
                }, props);
            }
        };
        showModal(data);
    }

    const updateShipment = (props, shipmentData, isUpdate) => {
        let packageMasterIds = [];
       
        if(props.boughtShipment?.purchasedShipmnetEasyPostId){
            return   showToasterMessage({
                description:
                  "Already shipped cannot be edited",
              });
           }
        (shipmentData.customerSOShipmentDetailsList || []).map((ele) => {
            if (packageMasterIds.indexOf(ele.packageMasterId) < 0) {
                packageMasterIds.push(ele.packageMasterId)
            }
            
            return ele;
        });
        props.popModalFromStack();
        props.pushModalToStack({
            modalBody: <Shipment {...props }
            customerId= {shipmentData.customerId}
                soMasterId= {shipmentData.soMasterId}
                shipmentMasterId= {shipmentData.shipmentMasterId}
                update= {isUpdate}
                packageMasterIds= {packageMasterIds.toString()}
            />,
            width: '100%',
            hideTitle: true,
            keyboard:false,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper',
  
        })
        // props.history.push({
        //     pathname: '/admin/so/shipment',
        //     state: {
        //         customerId: shipmentData.customerId,
        //         soMasterId: shipmentData.soMasterId,
        //         shipmentMasterId: shipmentData.shipmentMasterId,
        //         update: isUpdate,
        //         packageMasterIds: packageMasterIds.toString()
        //     }
        // })
    }

    return (
        <div className={"custom-modal show"}>

            {templateName === 'Standard' && <StandardTemplate {...props} />}
            {templateName === 'Professional' && <Template2 {...props} />}

            <div className="modal-dialog">
                <div className="modal-content full-width">
{ showComp ?
                    <div className=" button-group-action header-control">

                        <div className="small-btn-group">
                       
                            <Button style={{height:'38px'}} title={props.init?.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    updateShipment(props, deliveyNotesData, true);
                                }}
                            >
                            </Button>
                            
                            {/* <Button
                                title='Clone'
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    updateShipment(props, shipmentData, false);
                                }}
                            >
                            </Button> */}
                            <Button style={{height:'38px'}}
                                title={props.init?.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    props.pushModalToStack({
                                        modalBody: "Are you sure you want to delete this transaction?",
                                        handleSubmit: () => {
                                            props.deleteShipment({
                                                shipmentMasterId: shipmentData.shipmentMasterId,
                                                customerId: shipmentData.customerId,
                                                relationshipId: companyInfo.relationshipId
                                            }, props);
                                            props.popModalFromStack();
                                            props.hideModal();
                                        }
                                    })
                                }}
                            >
                            </Button>
                        </div>
                        <div className="small-btn-group">

                            <Button style={{height:'38px'}}
                                title={props.init?.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                icon={<MailOutlined />}
                                onClick={() => {
                                    openEmailDrawer(props);

                                }}
                            >
                            </Button>

                            <Button style={{height:'38px'}}
                                title={props.init?.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    generatePDF();
                                }}
                            >
                            </Button>
                            <Button style={{height:'38px'}}
                                title={props.init?.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                    printPdf();
                                }}
                            >
                            </Button>
                        </div>
                        <Button style={{height:'38px'}} type="primary" className="ml10"
                            onClick={() => {
                                generateShippingLabel(props, deliveyNotesData);
                            }}
                        >
                         <span>{  (props.boughtShipment && props.boughtShipment.purchasedShipmnetEasyPostId) ? <FormattedMessage id='viewShippingLabel.text' /> :  <FormattedMessage id='createShippingLabel.text' defaultMessage='' />}</span>
                        </Button>

                        <div className="">
                            <CustomDropDown style={{ 'width': '120px' }}
                                placeholder="Template"
                                value={props.templateName}
                                items={['Standard', 'Professional']}
                                onSelect={(selectedValue) => {
                                    props.updateState({
                                        templateName: selectedValue,
                                    });
                                }}
                            />
                        </div>
                        <div className="">
                            <Dropdown overlay={actionMenu()} trigger={['click']}>
                                <Button style={{height:'38px'}} className="action-btn-readonly action-btn small">
                                    <i className={ICONS.MORE_P} />
                                </Button>
                            </Dropdown>
                        </div>


                    </div>
                    :""}
                </div>
            </div>
        </div>
    );

}


export default ShipmentDetails;
