import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Menu, Checkbox, Skeleton, Empty } from "antd";
import { getMomentDateForUIReadOnly, sortTable, showToasterMessage, capitalizeFirstLetter, getUserDefinedName } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
import EnrollStudents from "../../../containers/modal/modalBody/schoolManagement/EnrollStudent";
import * as find from 'lodash.find';
import './index.scss';
import DropdownAction from "antd/lib/dropdown";
import { DownloadOutlined } from '@ant-design/icons';
import { Dropdown } from "../../general/Dropdown";
import { ICONS } from "../../../static/constants";
import FormDetails from '../../../containers/modal/modalBody/schoolManagement/FormDetails';
import PageHeader from "../../Common/pageHeader";
import { exportExcel } from "../../../utils";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  

const FormRegistrationListComp = (props) => {

  const { selectedItems=[], updateState, companyInfo, registrationCount, pageNumber, dir=0, registrationList, registrationForms=[] } = props;

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.registrations' defaultMessage='' />,
    },
  ];

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      formId:  0
    };
    props.getFormRegistrations(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      formId:  0
    };
    
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getFormRegistrations(payload);
    // props.getRegisteredMemberCount(payload);
  };

  const enrollAndAssignForm = (data) => {
   
    props.pushModalToStack({
      title: 'Enroll & Assign Form',
      modalBody: <EnrollStudents {...props} regStudentsToEnroll={data} />,
      width: '60%',
      hideFooter: true,
      keyboard: true,
    })
  }

  const openCustomerRegistrationModal = (data) => {
    props.showModal(
      {
        title: 'Registration Details',
        modalBody: <FormDetails {...props} formDetailsData={data}/>,
        width: '100%',
        hideFooter: true,
        wrapClassName: 'modal-custom-registration'
      }
    )
  }

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="1" value="view" onClick={()=>openCustomerRegistrationModal(item)}>
          <i className={ICONS.VIEW} />View
        </Menu.Item>

        {/* <Menu.Item key="3" value="archive">
          <i className={ICONS.ARCHIVE} />Archive
        </Menu.Item> */}

        <Menu.Item key="4" value="delete" onClick={()=>{
          props.showModal({
            modalBody: "Are you sure you want to delete?",
            handleSubmit: () => {
              props.deleteRegistration(item, props);
            }
          })
        }}>
          <i className={ICONS.DELETE} />Delete
        </Menu.Item>
      </Menu >
    )
  };
    
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, { orgRegistrationId: Number(item.orgRegistrationId) });
    let updatedItems = [];
    if (selectItem) {
        updatedItems = selectedItems.filter(obj => obj.orgRegistrationId !== item.orgRegistrationId);
    } else {
        updatedItems = JSON.parse(JSON.stringify(selectedItems));
        updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  }

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < registrationList[pageNumber].length; i++) {
      let item = registrationList[pageNumber][i];
      let selectItem = find(selectedItems, { orgRegistrationId: Number(item.orgRegistrationId) });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  }

  const isCheckedAll = () => {
    if(!props.registrationList || !props.registrationList[pageNumber] || props.registrationList[pageNumber].length === 0) {
      return false;
    }
    for (let i = 0; i < props.registrationList[pageNumber].length; i++) {
      let usr = props.registrationList[pageNumber][i];
      let selectItem = find(selectedItems, { orgRegistrationId: Number(usr.orgRegistrationId) });
      if (!selectItem) {
          return false;
      }
    }
    return true;
  }

  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    let cellIndex = e.target.cellIndex;
    if (!cellIndex){ cellIndex =e.target.parentElement.cellIndex};
    if (!cellIndex){ cellIndex =e.target.parentElement.parentElement.cellIndex};
    sortTable(document.getElementById('registration-table'), cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" value="excel" onClick={()=>{
          if(!selectedItems?.length){
            showToasterMessage({
              messageType: "error",
              description: "Please select some registrations",
            });
          }
          exportRegistrationData(selectedItems);
          }}
        >
          <i className={ICONS.EXCEL} />Excel File
        </Menu.Item>
      </Menu >
    )
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <i className={ICONS.SELECT_ALL} />Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <i className={ICONS.UNSELECT_ALL} />Unselect all
        </Menu.Item>

        {/* <Menu.Item
          key="3"
          onClick={() => {
            if(!(props.selectedItems || []).length){
              return showToasterMessage({
                messageType: 'error',
                description: 'No students selected!',
              })
            }
            let tempFilteredList = [];
            for(let stu of props.selectedItems){
              if(stu.studentStatus === 'Registered'){
                tempFilteredList.push(stu);
              }
            }
            enrollAndAssignForm(tempFilteredList);
            // if(tempFilteredList.length !== (props.selectedItems || []).length){
            //   props.showModal({
            //     modalBody: <>
            //       You have selected some pending students, would you like to enroll them?
            //       <Divider className="mb10"/>
            //       <Row justify='space-between'>
            //         <Button onClick={()=>{
            //           enrollAndAssignForm(tempFilteredList);
            //           props.hideModal();
            //           }}>No</Button>
            //         <Button type="primary" onClick={()=>{
            //           enrollAndAssignForm(props.selectedItems);
            //           props.hideModal();
            //           }}>Yes</Button>
            //       </Row>
            //     </>,
            //     hideFooter: true,
            //   })
            // }
            // else{
            //   enrollAndAssignForm(tempFilteredList);
            // }
          }}
        >
          <i className={ICONS.ASSIGN} />Enroll Selected
        </Menu.Item> */}
      </Menu>
    )
  };

  const exportRegistrationData = (registrationList =[]) => {
    if(!registrationList?.length){
      return
    }
    let dataList = [];
    (registrationList || []).map((data, i) => {
      
      let questionList= JSON.parse(data.questionList || '[]');
    
      for(const student of data.studentList){
        // let student = data.studentList[0];
        let rowData = {
          '#': i + 1,
          'Registration Id': data.orgRegistrationId || '',
          'Form Name': registrationForms.find(obj=>obj.registrationFormSettingId === data.registrationFormSettingId)?.programName || '',
          'Student Name': `${student?.firstName ? student?.firstName : ''} ${student?.lastName ? student?.lastName : ''}`,
          'Gender': student.gender || '',
          'Status': student.studentStatus || '',
          'Father Name': (student.fatherFirstName || '') + (student.fatherLastName ? ' ' + student.fatherLastName : ''),
          'Father Phone': student.fatherMobile || '',
          'Father Email': student.fatherEmail|| '',
          'Mother Name': (student.motherFirstName || '') + (student.motherLastName ? ' ' + student.motherLastName : ''),
          'Mother Phone': student.motherMobile || '',
          'Mother Email': student.motherEmail || '',
          'Student DOB/Age': student.dob ? getMomentDateForUIReadOnly({ date: new Date(student.dob), format }) : '',
          [getUserDefinedName('Class', props)]: student.className || '',
          'Allergies': student.allergies || '',
          'Emergency Contact': (data.emergencyContactName || '') + (data.emergencyPhone ? ': ' + data.emergencyPhone : ''),
          'Date Created': data.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(data.dateCreated), format }) : '',
          'Member': data.isOrgMember ? 'Yes': 'No',
          'Financial Assistance': data.isFinancialAssistanceNeeded ? 'Yes': 'No'
        }
        if(Array.isArray(questionList)){
          (questionList || []).forEach(function (qaObj, i) {
            rowData[qaObj.questionText] = typeof qaObj.answers === 'object'? (qaObj.answers || []).join(', '): qaObj.answers ;
          });

        }
        dataList.push(rowData);
      }
        return '';
      })
    exportExcel(dataList, `registration_list${`_${props.companyInfo.storeName}` || ''}`);
  }

  return (
    <>
      <PageHeader {...props}
        pageName="sidebar.menuItem.registrations"
        breadCrumbList={breadCrumbList}
      />
      <div className="view-container">
        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='schoolManagement.registrationList.table.heading' defaultMessage='' />
              </div>
              <div className="vertical-sep" />
              {
                registrationCount ? <>
                  <div>{registrationCount}</div>
                </> : 0
              }
            </div>
            <div className="right-actions">
              <Dropdown
                items={(props.registrationForms || [])}
                placeholder={'Select Form'}
                optionKeyName='registrationFormSettingId'
                valueKeyName='programName'
                onSelect={(selectedValue, options) => {
                  const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    pageNumber: 1,
                    pageSize: props.pageSize || 25,
                    formId: selectedValue
                  }
                  props.getFormRegistrations(payload);
                  props.getFormRegistrationsCount(payload);
                }}
              />

              {/* <button
                onClick={() => {
                  props.updateState({
                    studentListFilterDrawerVisible: true
                  })
                }}
              >
                <FilterOutlined /> &nbsp;
                <FormattedMessage id="filter.text" defaultMessage="" />
              </button> */}

              <DropdownAction overlay={downloadMenu()} trigger={['click']}>
                <span><DownloadOutlined /> &nbsp; Download as</span>
              </DropdownAction>
            </div>
          </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

          <div className="table-container">
            <table id='registration-table'>
              <thead>
                <tr>
                  <th width='5%'>
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={['click']}>
                      <i className={`${ICONS.DOWN_ARROW} ml5`} />
                    </DropdownAction>
                  </th>
                  <th onClick={sortColumn}>Reg. Id <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Form Name <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Student Name <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('Class Name', props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Father Info <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Mother Info <i className={ICONS.SORT} /></th>
                  {/* <th onClick={sortColumn}>{getUserDefinedName("school.student.profile.list",props)} <i className={ICONS.SORT} /></th> */}
                  <th onClick={sortColumn}>Date Created <i className={ICONS.SORT} /></th>
                  {/* <th onClick={sortColumn}>Status <i className={ICONS.SORT} /></th> */}
                  <th width='5%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                </tr>
              </thead>
              <tbody>
                { (props.registrationList[pageNumber] || [])?.length ? ((props.registrationList[pageNumber] || [])?.map((e, i) => {
   
                  
                  return <tr key={'std' + i}>
                    <td>
                      <Checkbox
                        onClick={() => {
                          itemSelection(e);
                        }}
                        checked={find(selectedItems, { orgRegistrationId: Number(e.orgRegistrationId) })}
                      />
                    </td>
                    <td>
                      {e.orgRegistrationId}
                    </td>

                    <td>{e.formName || <div className="empty-data-box"/>}</td>
                    <td>
                      {e.studentList.map((obj)=>{return `${obj.firstName || ''} ${obj.lastName || ''}`}).join(', ')}
                      {/* <div className="line-view">{e.age ? ` Age-${e.age}`: (e.dob ? `DOB - ${getMomentDateForUIReadOnly({ date: e.dob, format:CONSTANTS.TABLE_DATE_FORMAT })}` : <div className="empty-data-box"/> )}</div>
                      <div>{e.gender ? e.gender : <div className="empty-data-box"/>}</div> */}
                    </td>

                    <td>
                      {e.studentList.map((obj)=>{return `${obj.className || ''}`}).join(', ')}
                    </td>
            
                    <td>
                      <b>
                        {`${capitalizeFirstLetter(e.studentList[0]?.fatherFirstName || '')} ${e.studentList[0]?.fatherLastName || ''}`}
                      </b>
                      <div>{e.studentList[0]?.fatherEmail || <div className="empty-data-box" />}</div>
                      <div>{e.studentList[0]?.fatherMobile || <div className="empty-data-box mt5" />}</div>
                    </td>
                    <td>
                      <b>
                        {`${capitalizeFirstLetter(e.studentList[0]?.motherFirstName || '')} ${e.studentList[0]?.motherLastName || ''}`}
                      </b>
                      <div>{e.studentList[0]?.motherEmail || <div className="empty-data-box" />}</div>
                      <div>{e.studentList[0]?.motherMobile || <div className="empty-data-box mt5" />}</div>
                    </td>

                    {/* <td>{e.className||<div className="empty-data-box"/>}</td> */}
                    <td className="one-line-view">{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format: CONSTANTS.TABLE_DATE_FORMAT }) : <div className="empty-data-box"/>}</td>

                    {/* <td className="one-line-view">
                      {e.studentStatus||<div className="empty-data-box"/>}
                    </td> */}
                                                      
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                        <i className={ICONS.MORE_P} />
                      </DropdownAction>
                    </td>
                  </tr>
                  // })
                })) : ( <tr key="empty">
                  <td colSpan={"100%"}>
                    <Empty />
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              current={props.pageNumber || 1}
              total={registrationCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200, 500, 1000]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </>
  );
};

export default injectIntl(FormRegistrationListComp);
