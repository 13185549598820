import React from "react";
import { Tabs } from "antd";
import { injectIntl } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import Published from "./published";
import Unpublished from "./unpublished";
import PageHeader from "../../Common/pageHeader";

const { TabPane } = Tabs;

function callback(key, props) {
  const payload = {
    companyInfo: props.companyInfo,
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
  };
  if (key === "1") {
    props.updateState({ activeKey: "1", isPublished: 1 });
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 })
    props.countPublishAndUnpublish({ ...payload, isPublished: 1 })


  } else if (key === "2") {
    props.updateState({ activeKey: "2", isPublished: 0 });
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 })
    props.countPublishAndUnpublish({ ...payload, isPublished: 0 })

  }

}
const ProductListingComp = (props) => {

  return (
    <>

      <PageHeader {...props}
        pageName="product.review.page.heading"
        breadCrumbList={""}
        canCreate={false}

      />
      <div className="view-container">

        <Tabs
          activeKey={props.activeKey || "1"}
          onChange={(key) => {
            callback(key, props);

          }}
          type="line"
        >
          <TabPane tab="Publish" key="1">
            <Published {...props} />
          </TabPane>
          <TabPane tab="Unpublish" key="2">
            <Unpublished {...props} />

          </TabPane>

        </Tabs>


      </div>
    </>
  );
};

export default injectIntl(ProductListingComp);
