import React, { Fragment, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Skeleton, Empty } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import { CONSTANTS, ICONS } from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import "./index.scss";
import { getMomentDateForUIReadOnly } from "../../../utils";

const CommunicationTemplateComp = (props) => {
  const breadCrumbList = [
    {
      name: <FormattedMessage id='breadcrum.communications' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='breadcrum.communications.communicationTemplate' defaultMessage='' />,
    }
  ];

  const sortColumn = (e) => {
    // sortTable(document.getElementById('newsletter-table'), e.target.cellIndex, 1 - dir);
    // updateState({ dir: 1 - dir })
  }
  const [showNextRow, setShowNextRow] = useState(true);
 

  
  const handleClick1 = (moduleName) => {
    setShowNextRow(moduleName)
   
  };

  

  const actionMenu = (data) => {
   
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" 
          onClick={()=>{
            props.updateDrawerState({
              communicationTemplateDrawerVisiable: true,
              data
              
          })
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          <Menu.Item key="2" 
          onClick={()=>{

            const modalData = {
              title: (
                <FormattedMessage
                  id="drawer.inventory.confirmation.title"
                  defaultMessage=""
                />
              ),
              modalBody: <Fragment>
              <p>
                <FormattedMessage
                  id="commons.delete.popup.confimation.msg"
                  defaultMessage=""
                />
              </p>
            </Fragment>,
              handleSubmit: () => {
                props.deleteCommunicationTemplate({relationshipId: props.companyInfo?.relationshipId , communicationsTemplateSettingId: data.communicationsTemplateSettingId} , props);
                props.hideModal();
              },
            };
            props.showModal(modalData);
           
          }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>

        </Menu >
    )
  };

  const groupByModuleName = (data) => {
    return data.reduce((result, item) => {
      // Use moduleName as the key
      const key = item.moduleName;
  
      // Initialize the array if it doesn't exist
      if (!result[key]) {
        result[key] = [];
      }
  
      // Push the item to the corresponding moduleName array
      result[key].push(item);
  
      return result;
    }, {});
  };
  

  const groupedData = groupByModuleName(props.communicationTemplate||[]); 
  return (
    <Fragment >
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.communications.communicationTemplate' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
              props.updateDrawerState({
                communicationTemplateDrawerVisiable: true,
                data:{},
                
            })
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='heading.table.communicationTemplate' defaultMessage='' />
            </div>
          </div>
          <div className="right-actions">
          </div>
        </div>
        <div className="table-container">
          <table id='communicationTemplate-table' className="communicationTemplateTable">
            <thead>
              <tr>
              
                <th onClick={sortColumn}><FormattedMessage id="communicationTemplate.table-heading-name.module" defaultMessage="" /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.transaction' defaultMessage='' /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.event-type' defaultMessage='' /></th>
                <th ><FormattedMessage id='communicationTemplate.table-heading-name.communication' defaultMessage='' /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.date' defaultMessage='' /></th>
                {/* <th className="text-center"><FormattedMessage id='communicationTemplate.table-heading-name.status' defaultMessage="" /></th> */}
                <th className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(groupedData).map((moduleName ,i) => (
        <Fragment key={moduleName}>
          <tr>

                <td>
                  <div className="flexItem action-delete" onClick={()=>handleClick1(moduleName)}> 
                  <i className={(showNextRow === moduleName) ? ICONS.MINUS_CIRCLE : ICONS.PLUS_CIRCLE}></i> {moduleName}
                  </div>
                </td>
                <td></td>
               <td></td>
               <td>

               </td>
               <td></td>
               <td></td>
               <td></td>
              </tr>
              {(showNextRow === moduleName) && (
                <>
                {
                  groupedData[moduleName].map((item,i) => (
                    <tr>
                    <td>
                        <div className={`${(showNextRow === moduleName) ? 'mrL30' : 'mrL0'}`}>{item?.moduleName}</div>
                      </td>
                    <td>{item?.transactionName}</td>
                    <td>{item?.eventName}</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className={`${item?.isEmailAutoTrigger ? 'activeMail':''}`}><i className={ICONS.MAIL}></i></span>
                        <span className={`${item?.isSMSAutoTrigger ? 'activeSMS':''}`}><i className={ICONS.SMS_ICON}></i></span>
                        <span className={`${item?.isWhatsAppAutoTrigger ? 'activeWhatsapp':''}`}><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>{item.dateCreated
                          ? getMomentDateForUIReadOnly({
                            date: item.dateCreated,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                          : "-"}</td>
                    {/* <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code ready">Active</span></td> */}
                     {/* <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" checked onChange={onChange} /></td> */}
                    <td className="text-center">
                    <DropdownAction overlay={actionMenu(item)} trigger={['click']}>
                     <i className={ICONS.MORE} />
                   </DropdownAction>
                    </td>
                  </tr>
                  ))
                }
                 
                  
                </>
              )}
         
        </Fragment>
      ))}
             
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(CommunicationTemplateComp);
