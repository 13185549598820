import React from 'react';
import './index.scss';
import { Form } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import '@ant-design/compatible/assets/index.css';
import { Tooltip } from 'antd';



const DocumentNumberComponent = (props) => {
    let gridApi = {};
    const columnDefs = [
        {
              headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.documentnumber.transaction' defaultMessage='' />;
              },
            width: 167,
            field: 'label',
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div> Prefix
                </div>
            },
            field: 'txNamePefix',
            editable: true,
            resizable: true,
            width: 200,
          cellEditor: 'customTextEditor',
      
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            cellEditorParams: (params) => {
                return {
                  value: params.node.data.txNamePefix || "",
                  onChange: (value) => {
                    params.node.data.txNamePefix = value
                  
                  },
                };
              },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.documentnumber.numberExample' defaultMessage='' />;
              },
            width: 150,
            field: 'numberExample',
            resizable: true, 
        },
        // {
        //     headerComponentFramework: () => {
        //         return <FormattedMessage id='modal.settings.documentnumber.numberInfo' defaultMessage='' />;
        //       },
        //     width: 240,
        //     field: 'numberInfo',
        //     resizable: true,
        // },
        {
            headerComponentFramework: () => {
                return <div> <FormattedMessage id='modal.settings.documentnumber.nextSequence' defaultMessage='' /> <Tooltip placement="top" title={<FormattedMessage id='modal.settings.documentnumber.nextSequence.tooltip' defaultMessage='Your next document will be generated with the sequence you specify' />} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                </Tooltip>
                </div>
            },
            field: 'number',
            editable: true,
            resizable: true,
            width: 200,
            cellEditor: 'customNumberEditor',
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            cellRendererFramework: (params) =>
            <div>{ Number((params.node.data.number) || 0)} </div>
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.documentnumber.action' defaultMessage='' />;
              },
            resizable: true,
            field: 'action',  
            lockPosition: true,
            pinned: 'right',
            width: 100,
            cellRendererFramework: (params) =>
            <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    <Link onClick={() => {
                                        gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (props.companyInfo || {}).relationshipId
                                        saveDocumentNumber(
                                            {
                                                 txName: params.data.txName,
                                                 txType : params.data.txType,
                                                 txNamePrefix:params.data.txNamePefix,
                                                 number: params.data.number,
                                                 relationshipId: (props.companyInfo || {}).relationshipId
                                            },props
                                            );
                                        gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-save" />
                                    </Link>
                                    <Link onClick={() => {
                                        params.api.stopEditing(true);
                                        gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-ban" />
                                    </Link>
                                </div>
                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    <Link onClick={() => {
                                       (gridApi && gridApi.forEachNode) && gridApi.forEachNode((rowNode, index) =>{
                                            gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        });
                                        gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        gridApi.refreshCells({ columns: ["action"], force: true });
                                        var startEditingParams = {
                                            rowIndex: params.node.rowIndex,
                                            // colKey:'txNamePefix',
                                          };
                                        params.api.startEditingCell(startEditingParams);
                                        
                                    }}>
                                        <i className="fa fa-edit" />
                                    </Link>
                                    
                                </div>}
                        </div>
        },
    ];


    const saveDocumentNumber = (payload,props) => {
        const modalData = {
            modalBody: "Are you sure you want to Submit?",
            handleSubmit: () => {
                props.saveDocumentNumber(payload,props);
                props.popModalFromStack();
            }
        };
        props.pushModalToStack(modalData);

    }

    const getRowHeight = (params) => {
        return 35;
    }
    const onGridReady = (params) => {
         gridApi = params.api;
        params.api.sizeColumnsToFit();
    }
    return (
        <div>
            <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <div className="inner-content">
            <Form className="pr20" layout="vertical" hideRequiredMark>
                        <Form.Item>
                                <CustomAgGrid
                                    onGridReady={onGridReady}
                                    columnDefs={columnDefs}
                                    rowData={props.tabDocumentOptionsList || []}
                                    getRowHeight={getRowHeight}
                                    gridStyle={{
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: '10px'
                                    }} />

                            </Form.Item>
                        </Form>
                        </div>
            </div>
                
            </div>
        </div>
    );

}


export default DocumentNumberComponent;
