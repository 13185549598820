import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
// import moment from 'moment-timezone';
//import * as find from 'lodash.find';
import { CONSTANTS, PERMISSION_VALUES, MODULE_CODED_VALUES, ICONS } from '../../../../static/constants';
import LogCommunication from '../../../../containers/supplier/Listing/LogCommunication';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { checkACLPermission, getMomentDateForUIReadOnly } from '../../../../utils';
// import ReactHtmlParser from 'react-html-parser';
import { Menu, Dropdown, Empty, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

// import { createUpdateCommunication } from '../../../../containers/customer/Listing/LogCommunication/action';

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class Communication extends Component {
  render() {
    const commuincationList = this.props.commuincationList || [];
    //let gridApi;
    const updateCommunication = (payload) => {

        const modalBody =
            <FormattedMessage
                id='save.confirmation'
                defaultMessage={``}
            />;
        const modalData = {
            modalBody,
            handleSubmit: () => { this.props.createUpdateCommunication(payload) },
        };
        this.props.showModal(modalData);
    }

    const deleteCommunication = (payload) => {
      const modalBody =
        <FormattedMessage
          id='common.delete.confirmation'
          defaultMessage={``}
        />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteCommunication(payload);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    }

    // const columnDefs = [
    //     // {
    //     //     headerName: "#",
    //     //     resizable: true,
    //     //     rowDrag: true,
    //     //     cellRendererFramework: function (link) {
    //     //         return <div>
    //     //             {link.rowIndex + 1}
    //     //         </div>
    //     //     }
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.type' defaultMessage='' />;
    //         },
    //         field: "communicationMode",
    //         resizable: true,
    //         editable: true,
    //         cellEditor: 'customTextEditor',
    //         width: 100, 
    //         cellRendererFramework: (link) => <div>
    //         {(link.data.origination !== 'System') ? <div>
    //             {link.data.communicationMode}
    //         </div> : <div className="cursor-pointer" onClick={() => {
    //             this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: link.data });
    //         }}>
    //                 {link.data.communicationMode}
    //             </div>}
    //     </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.description' defaultMessage='' />;
    //         },
    //         field: "description",
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return ReactHtmlParser(params.data.description)
    //         },
    //         width: 350,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.communicatedTo' defaultMessage='' />;
    //         },
    //         field: "communiatedTo",
    //         resizable: true,
    //         width: 200, 
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return <span>{params.data.communiatedTo}</span>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.transaction' defaultMessage='' />;
    //         },
    //         field: "transactionType",
    //         resizable: true,
    //         width: 120,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.transactionType ? <FormattedMessage id={link.data.transactionType} /> : ''}
    //         </div>
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.date' defaultMessage='' />;
    //         },
    //         field: "communicationDate",
    //         resizable: true,
    //         width: 130,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.communicationDate ? moment(new Date(link.data.communicationDate)).format(format) : '-'}
    //         </div>,

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.origination' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "origination",
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.detail.communication.gridHeader.communicatedBy' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: 'relationshipEmployeeName',
    //         editable: true,
    //         cellEditor: 'customDropDownEditor',
    //         width: 150,
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 items: this.props.allEmployee,
    //                 optionKeyName: 'relationshipEmployeeId',
    //                 valueKeyName: 'fullName',
    //                 onSelect: (selectedValue, params) => {
    //                     //const employee = find((this.props.allEmployee || []), { relationshipEmployeeId: Number(params.key || 0) }) || {};
    //                     //obj.node.setDataValue(obj.colDef.field, employee.fullName);
    //                 },
    //             }
    //         },
    //     },
    //     {
    //         field: 'action', lockPosition: true, pinned: 'right',
    //         width: 100,
    //         cellRendererFramework: (params) => <div>
    //             {(params.data.origination !== 'System') ? <div>
    //                 <div>
    //                     {params.node.data.isToEdit &&
    //                         <div className="table-cell-action">
    //                             <div className='cursor-pointer' onClick={() => {
    //                                 updateCommunication(params.data);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-save" />
    //                             </div>
    //                             <div className='cursor-pointer' onClick={() => {
    //                                 params.api.stopEditing(true);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-ban" />
    //                             </div>
    //                         </div>
    //                     }

    //                     {!params.node.data.isToEdit &&
    //                         <div className="table-cell-action" >
    //                             {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION, PERMISSION_VALUES.UPDATE) && <span className='cursor-pointer' onClick={() => {
    //                                 // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
    //                                 // this.gridApi.refreshCells({ columns: ["action"], force: true });
    //                                 // var startEditingParams = {
    //                                 //     rowIndex: params.node.rowIndex,
    //                                 //     colKey: params.column.getId(),
    //                                 // };
    //                                 // params.api.startEditingCell(startEditingParams);
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='modal-title'>
    //                                         <FormattedMessage id='newCommunication.text' defaultMessage='' />
    //                                             </div>
    //                                         <div className="modal-sub-title">
    //                                             {this.props.supplierName}
    //                                         </div>
    //                                     </React.Fragment>,
    //                                     modalBody: <LogCommunication
    //                                         supplierId={this.props.supplierId}
    //                                         supplierName={this.props.supplierName}
    //                                         communicationData={params.node.data}
    //                                     />,
    //                                     width: 500,
    //                                     hideFooter: true,
    //                                     wrapClassName: 'log-communication-modal',
    //                                 };
    //                                 this.props.pushModalToStack(modalData);
    //                             }}>
    //                                 <i className="fa fa-edit" />
    //                             </span>}
    //                             {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION, PERMISSION_VALUES.DELETE) && <span className='cursor-pointer' onClick={() => deleteCommunication(params.data)}>
    //                                 <i className="fa fa-remove" />
    //                             </span>}
    //                         </div>}
    //                 </div>
    //             </div>
    //                 : <div><i className="fa fa-lock" /></div>
    //             }
    //         </div>
    //     }
    // ]

    const createUpdateCommunication = (payload) =>{
        const modalData = {
            title: <React.Fragment>
              <div style={{display:'flex',flexDirection:'column'}}>
                <div className='modal-title'>
                    { payload.communicationMode ? <FormattedMessage id='updateCommunication.text' defaultMessage="" /> : <FormattedMessage id='newCommunication.text' defaultMessage="" /> }
                </div>
                {/* <div className="modal-sub-title">
                    {this.props.supplierName}
                </div> */}
              </div>
            </React.Fragment>,
            modalBody: <LogCommunication
                supplierId={this.props.supplierId}
                supplierName={this.props.supplierName}
                formData = {payload}
            />,
            width: 500,
            hideFooter: true,
            wrapClassName: 'log-communication-modal',
        };
        this.props.pushModalToStack(modalData);
    }

    // const createUpdateCommunication = (communication) => {
    //   this.props.updateHeaderState({
    //     communication: {
    //       commDrawer: true,
    //       formData:{
    //         isUpdate: true,
    //         ...communication,
    //         supplierId: this.props.supplierId,
          
    //      } },
    //   })
    // }


    // const columnDef =[
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.type' defaultMessage='' />,
    //         index: 'communicationMode',
    //         key: 'communicationMode',
    //         render: (text, record, index)=>(
    //             (record.origination !== 'System') ? <div>
    //                 {record.communicationMode}
    //             </div>: <Link onClick={(record)=>{
    //                 this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: record });
    //             }}>
    //                 {record.communicationMode}
    //             </Link>
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.description' defaultMessage='' />,
    //         index: 'description',
    //         key: 'description',
    //         width: '300px',
    //         render: (text, record, index) =>(
    //             record.description
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.communicatedTo' defaultMessage='' />,
    //         index: 'communiatedTo',
    //         key: 'communiatedTo',
    //         render: (text, record, index) =>(
    //             <div>{record.communiatedTo}</div>
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.transaction' defaultMessage='' />,
    //         index: 'transactionType',
    //         key: 'transactionType',
    //         render: (text, record, index)=><div>
    //             { record.transactionType ? <FormattedMessage id={record.transactionType} /> : ''}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.date' defaultMessage='' />,
    //         index: 'communicationDate',
    //         key: 'communicationDate',
    //         render: (text, record, index)=>(
    //             record.communicationDate ? getMomentDateForUIReadOnly({date: new Date(record.communicationDate), format}) : '-'
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.origination' defaultMessage='' />,
    //         index: 'origination',
    //         key: 'origination',
    //         render: (text, record, index)=><div>
    //             {record.origination}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.detail.communication.gridHeader.communicatedBy' defaultMessage='' />,
    //         index: 'relationshipEmployeeName',
    //         key: 'relationshipEmployeeName',
    //         render: (text, record, index)=><div>
    //             {record.relationshipEmployeeName}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='customer.listing.header.action' defaultMessage="" />,
    //         index: 'action',
    //         key: 'action',
    //         render: (text, record, index) => <div>
    //             {record.origination !== 'System' ? <div className="table-cell-action">
    //                 <span className='cursor-pointer' onClick={() => createUpdateCommunication(record)}>
    //                     <i className="fa fa-edit" />
    //                 </span>
    //                 <span className='cursor-pointer' onClick={() => deleteCommunication(record)}>
    //                     <i className="fa fa-remove" />
    //                 </span>
    //             </div>:
    //                 <i className="fa fa-lock" />
    //             }
    //         </div>
    //     },
    // ] 


    // const onGridReady = (params) => {
    //     this.gridApi = params.api;
    //     params.api.sizeColumnsToFit();
    // }


    // const createUpdateCommunication = (communication) => {
    //   this.props.updateHeaderState({
    //     communication: {
    //       commDrawer: true,
    //       formData: {
    //         isUpdate: true,
    //         ...communication,
    //         supplierId: this.props.supplierId,
    //       }
    //     },
    //   })
    // };



    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          {e.origination !== "System" ? (
            <Menu.Item
              key="1"
              value="edit"
              onClick={() => {
                createUpdateCommunication(e);
              }}
            >
              <EditOutlined />
              Edit
            </Menu.Item>
          ) : (
            <div>
              <i className="fa fa-lock" />
            </div>
          )}

          {e.origination !== "System" ? (
            <Menu.Item
              key="2"
              onClick={() => {
                deleteCommunication(e);
              }}
            >
              <DeleteOutlined />
              Delete
            </Menu.Item>
          ) : (
            <div>
              <i className="fa fa-lock" />
            </div>
          )}
        </Menu>
      );
    };
    return (
      <Fragment>
        {/* <div className="view-top-bar">
                <div className="top-view-left">
                <div className="page-heading">
                    Communication
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
                <div className="top-view-right">
               
                {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION, PERMISSION_VALUES.CREATE) && 
                        <button className="create-action-btn" type="button" onClick={() => {
                            createUpdateCommunication({});
                        }}>
                            <i className="fa fa-plus-square" />
                            <FormattedMessage id='newCommunication.text' defaultMessage='' />
                        </button>
                    }    
                  
                </div>
            </div> */}


        {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={commuincationList || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridSizeChanged={onFirstDataRendered}
                                getRowHeight={getRowHeight}
                                editType={'fullRow'}
                                onGridReady={onGridReady}
                            >
                            </CustomAgGrid>
                        </div>
                    </div> */}
        {/* <Table dataSource={commuincationList} columns={columnDef} style={{ width: '100%' }} /> */}
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                Communication List
              </div>


              <div className="vertical-sep" />
              <div>{commuincationList?.length}</div>


            </div>
            <div className="right-actions">
              <button
                    className="create-action-btn"
              style={{ backgroundColor: "#1cb961", color: "#ffffff", fontSize: "14px" }}
                onClick={() => {
                  createUpdateCommunication({});
                }}>
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>

            </div>
          </div>
          <Skeleton loading={this.props.isLoading}>
            <div className="table-container">
              <table >
                <thead>
                  <tr>
                    <th><FormattedMessage id='common.type' defaultMessage="" /></th>
                    <th><FormattedMessage id='common.description' defaultMessage="" /></th>
                    <th><FormattedMessage id='communicationTo.text' defaultMessage="" /></th>
                    <th><FormattedMessage id='transaction.text' defaultMessage="" /></th>
                    <th><FormattedMessage id='common.date.text' defaultMessage="" /></th>
                    <th><FormattedMessage id='origination.text' defaultMessage="" /></th>
                    <th><FormattedMessage id='communicatedBy.text' defaultMessage="" /></th>
                    <th style={{textAlign:'center'}}><FormattedMessage id='customer.listing.header.action' defaultMessage="" /></th>
                  </tr>
                </thead>
                <tbody>
                  {(commuincationList || []).length > 0 ? commuincationList.map((rowData, index) => (
                    <tr key={index}>
                      <td>
                        {/* {
                          (rowData.origination !== 'System') ? 
                          <div>
                            {rowData.communicationMode}
                          </div> : <Link onClick={() => {
                            this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: rowData });
                          }}>
                            {rowData.communicationMode}
                          </Link>
                        } */}
                        {
                         
                          <div className="link line-view" onClick={() => {
                            this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: rowData });
                          }}>
                            {rowData.communicationMode}
                          </div>
                        }
                      </td>
                      <td>{rowData.description?rowData.description:<div className="empty-data-box"></div>}</td>
                      <td>{rowData.communiatedTo?rowData.communiatedTo:<div className="empty-data-box"></div>}</td>
                      <td>{rowData.transactionType?rowData.transactionType:<div className="empty-data-box"></div>}</td>
                      <td>{rowData.communicationDate ? getMomentDateForUIReadOnly({ date: new Date(rowData.communicationDate), format }) : <div className="empty-data-box"></div>}</td>
                      <td>{rowData.origination?rowData.origination:<div className="empty-data-box"></div>}</td>
                      <td>{rowData.relationshipEmployeeName?rowData.relationshipEmployeeName:<div className="empty-data-box"></div>}</td>
                      <td style={{textAlign:'center'}}>
                        <div className="action-icon">
                          <Dropdown overlay={actionMenu(rowData)} trigger={['click']}>
                            <MoreOutlined />
                          </Dropdown>
                        </div>

                        {/* {rowData.origination !== 'System' ? <div className="table-cell-action">
                                              <span className='cursor-pointer' onClick={() => createUpdateCommunication(rowData)}>
                                                  <i className="fa fa-edit" />
                                              </span>
                                              <span className='cursor-pointer' onClick={() => deleteCommunication(rowData)}>
                                                  <i className="fa fa-remove" />
                                              </span>
                                          </div>:
                                              <div><i className="fa fa-lock" /></div>
                                          } */}

                      </td>
                    </tr>
                  )) :
                  <tr key="empty-data">
                  <td colSpan={"100%"} style={{borderBottom:"none"}}>
                    <Empty />
                  </td>
                </tr>

                  }
                </tbody>
              </table>

            </div>
          </Skeleton>
        </div>



      </Fragment>
    )
  }
}

export default Communication;
