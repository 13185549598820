import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row, Skeleton, Card, Upload, Button, message } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { getAWSFileURL } from "../../../../utils";
import { ICONS } from "../../../../static/constants";

const { Meta } = Card;

const FeedbackDrawer = (props) => {

  const closeDrawer = () => {
    props.updateState({
      feedbackDrawer: false,
    });
  };
  // const imageVal = props?.feedbackData.ecomFeedbackImages?.map((val) => {
  //   return getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName)
  // })
console.log(props.feedbackData,"feedbackData::::::::::::")

  const handleSubmit = () => {

    const payload = {
      ...props.feedbackData,
      relationshipId: props.companyInfo.relationshipId,
      itemName: props.itemName,
      rating: props.rating,
      message: props.message,
      customerName: props.customerName,
      updatedByUserId: props.updatedByUserId,
      feedbackId: props.feedbackId,
    }

    const modalData = {
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
      handleSubmit: () => {
        props.updateFeedback(payload, imageVal, props);
        closeDrawer();
      },
    };
    props.pushModalToStack(modalData);
  }

  const getCreateConfirmationModalBody = (payload) => {
    return (
      <>
        <p>
          <FormattedMessage
            id="drawer.inventory.confirmation.text"
            defaultMessage=""
          />
        </p>
      </>
    );
  };

  const handleDeleteImage = (imageId) => {
    const updatedImages = props.feedbackData.ecomFeedbackImages.filter(
      (val) => val.ecomFeedBackImageId !== imageId
    );
  console.log(updatedImages,"updatedImages::::::::::::")
    props.updateState({
      ...props.feedbackData,
      ecomFeedbackImages: updatedImages,
    });
  };
  
  const imageVal = props?.feedbackData.ecomFeedbackImages?.map((val) => {
    return {
      url: getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName),
      id: val.ecomFeedBackImageId,
    };
  });
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.feedbackDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }
            }
            key={1}
          />
        </>
      }

    >

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Product Name
            <span className="required">*</span>

          </div>
          <TextBox
            disabled
            value={props.itemName}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">Rating
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Enter rating"
            type="text"
            maxLength={30}
            value={props.rating}
            onChange={(e) => {
              props.updateDrawerState({ rating: e.target.value });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Review
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Enter review"
            type="text"
            maxLength={30}
            value={props.message}
            onChange={(e) => {
              props.updateDrawerState({ message: e.target.value });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Reviewed By
            <span className="required">*</span>

          </div>
          <TextBox
            disabled
            type="text"
            value={props.customerName}

          />
        </Col>

        <Col span={24}>

          <div className="i-label">Feedback Images</div>
          {/* <div style={{ display: "flex", gap: "10px" }}>
            {imageVal && imageVal.length > 0 ? (
              imageVal.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt="" height={50} width={50} />
              ))
            ) : (
              ''
            )}

          </div> */}

<div style={{ display: "flex", gap: "10px" }}>
    {imageVal && imageVal.length > 0 ? (
      imageVal.map((image, index) => (
        <Card
                  className="card-img-prev ht-img"
                  cover={<img src={image.url} alt="Placeholder" />}
                  actions={[
                    // <i className={ICONS.DELETE} onClick={() => handleDeleteImage(image.id)} />
                    <Button onClick={() =>
                      props.deleteFeedbackImgaes({
                         relationshipId:(props.companyInfo || {}).relationshipId,
                         feedbackId:props.feedbackData.feedbackId,
                         ecomFeedBackImageId:image.id
                      })
                    }
                     
                     >Delete</Button>
                   
                    
                  ]}
                />
      ))
    ) : (
      ''
    )}
  </div>
 

        </Col>

      </Row>
    </Drawer>
  );
};

export default FeedbackDrawer;
