import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton,Menu, Pagination, Empty } from 'antd';

import PageBreadcrumb from '../../../PageBreadcrumb';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { PictureOutlined,PlusOutlined } from '@ant-design/icons';
import { MoreOutlined } from '@ant-design/icons';
import OpeningQuantity from '../../../../containers/modal/modalBody/product/openingQuantity';
import DropdownAction from "antd/lib/dropdown";
// import './index.scss';
import { checkACLPermission, fetchPaginationDataIfNeeded, getMomentLoclDateForUIReadOnly } from '../../../../utils';
import {
  CONSTANTS, ICONS, MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../static/constants';


const QuantityListingComp = (props) => {
  const { permissions, allUOM, companyInfo, showModal ,pageNumber , openingQuantityList,openingQuantityCount, qunatityList } = props;
  
  // const getRowHeight = (params) => {
  //   let height = 36;
  //   return height;
  // }

  const openingStockDetailModal = (data) => {

      const stockPayload ={
        openingQuantityMasterId: data?.openingQuantityMasterId
      }
    props.pushModalToStack({
      title: "Opening Balance Details",
      modalBody: (
        <OpeningQuantity {...props} data={data} stockPayload={stockPayload} />
      ),
      width: 1000,
      hideFooter: true,
      keyboard: true,
      wrapClassName: "modal-custom-detail",
    });
  };
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const addItemConfirmationModal = (text ,e) => {
    const labelName = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.addUOM({ text: text, relationshipId: companyInfo.relationshipId , id: e.uomId ,version: e.version}, props)
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const newUOMAddition = (e) => {
   
    const formData = {};
    formData.relationshipId = companyInfo.relationshipId;
    formData.selectedValue = e.uomName;
    formData.textBoxValue = e.uomName;
    const payload = { formData: formData };
    const data = {
      title: <FormattedMessage id='addItem.text.uom' defaultMessage='' />,
      formData,
      modalData: { modalType: MODAL_TYPE.UOM, data: {payload , textBoxValue : e.uomName} },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(formData.textBoxValue , e);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      }
    };
    showModal(data);
  }

  const deleteUOM = (payload) => {
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.uomName }}
      />;
    const modalData = {
      modalBody,
      handleSubmit: () => { props.deleteUOM({ id: payload.uomId, relationshipId: companyInfo.relationshipId }) },
    };
    showModal(modalData);
  }

  // const updateUOM = (payload) => {
  //   const modalBody =
  //     <FormattedMessage
  //       id='updateItem.confirmation'
  //       defaultMessage=''
  //       values={{ value: payload.uomName }}
  //     />;
  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => { props.updateUOM(payload) },
  //   };
  //   showModal(modalData);
  // }

  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.UOM, PERMISSION_VALUES.CREATE),
      delete: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.UOM, PERMISSION_VALUES.DELETE),
      update: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.UOM, PERMISSION_VALUES.UPDATE),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    })
    return permittedAction;
  }

  const permittedAction = getActionPermissionData();
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };
    props.fetchOpeningQuantityList(payload);
};
const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.fetchOpeningQuantityList(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
};

  const breadCrumbList = [
    {
      name: 'Products',
    },
    {
      name: 'Opening Quantity',
    }
  ];
  const actionMenu = (data) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" onClick={()=>{
        //  function need to be added 
       props.updateDrawerState({
        isQuantityDrawerVisible: true,
        isUpdate:true,
        data
       })
            }}
          >
           <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

           <Menu.Item key="2" onClick={()=>{
            // props. deleteStockQuantity(data)
           }
           
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>

        </Menu >
    )
  };
  return (
    <Fragment>
      <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id='sidebar.menuItem.quantity'
                defaultMessage=""
              />
             
            </div>
            <div className="vertical-sep"/>
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
          {
            permittedAction.create && (
              <button className="create-action-btn" onClick={()=>{
                props.updateDrawerState({
                    isQuantityDrawerVisible: true,
                    isUpdate:false,
                    data: {},
                })
              }}>
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id='button.create.label' defaultMessage='' />
              </button>
            )
          }
          </div>
        </div>
      <div className='view-container'>
      

        <Skeleton loading={props.isLoading}
          active
          paragraph={{ rows: 12 }}
        >
           <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='sidebar.menuItem.quantity' defaultMessage='' />
                        </div>
                        {
                          openingQuantityCount ? <>
                              <div className="vertical-sep" />
                              <div>{openingQuantityCount||0}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions"></div>
                </div>
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th><FormattedMessage id='product.detail.tab.outboundInventory.quantity' defaultMessage=''/></th>
                        <th><FormattedMessage id='drawer.inventory.purchasedPrice' defaultMessage=''/></th>
                        <th><FormattedMessage id='product.detail.panel.productName.label' defaultMessage=''/></th>
                        {/* <th><FormattedMessage id='inventory.extras.uom.listing.header.description' defaultMessage=''/></th> */}
                        <th><FormattedMessage id='inventory.extras.uom.listing.header.dateCreated' defaultMessage=''/></th>
                        <th><FormattedMessage id='common.listing.header.action' defaultMessage=''/></th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        (openingQuantityList[pageNumber] || []).length ? openingQuantityList[pageNumber].map((rowData,index)=>{

                          const val = (rowData?.openingQuantityDetailList||[])[0]
                         return (<tr key={index}>
                        <td>{index+1}</td>
                        <td>{val?.quantity||0}</td>
                        <td>{val?.anItemPurchasePrice||0}
      </td>
      <td className="one-line-view">
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                openingStockDetailModal(rowData);
                              }}
                            >
                              {`${val?.itemName}${val?.attributeValue1 ? ` (${val?.attributeValue1}  ${val?.attributeValue2 ? `| ${val?.attributeValue2}`:''} ${val?.attributeValue3 ? `| ${val?.attributeValue3}` : ''}) `:''}`||""}
                        
                            </div>
                          </td>
                          <td>{rowData.dateCreated ? <div>
        {getMomentLoclDateForUIReadOnly({date: rowData.dateCreated,  format: CONSTANTS.DISPLAY_DATE_FORMAT})}
      </div> : "-"}</td>
                        
                        <td style={{paddingLeft: '50px'}}> 
                        <div className="action-icon">
                          <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                            <MoreOutlined />
                          </DropdownAction>
                        </div>
                      </td>
                      </tr>)
                        }):  (
                          <tr key="empty">
                            <td colSpan={"100%"}>
                              <Empty />
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={allUOM || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                getRowHeight={getRowHeight}
                onGridSizeChanged={onFirstDataRendered}
                editType={'fullRow'}
              >
              </CustomAgGrid>
            </div>
          </div> */}
        </Skeleton>
        <div className="footer">
            <div className="f-left"></div>

            <div className="f-right">
            <Pagination
                size="small"
                total={openingQuantityCount}
                showTotal={showTotal}
                defaultPageSize={25}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                 onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  props.updateState({ pageSize: pageSize, pageNumber: pageNumber ,isDataRender: true});
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
      </div>
    </Fragment>

  );
};

export default injectIntl(QuantityListingComp);
