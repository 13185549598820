import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Button, Card, Col, Dropdown, Menu, Pagination, Row, Skeleton, Switch } from "antd";
import { ICONS } from "../../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import storeDef from "../../../../assets/images/store-def.jpg";
import moment from "moment-timezone";
import '../blogList/index.scss'
import { getMomentDateForUIReadOnly } from "../../../../utils";



const CategoryList = (props) => {
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.blogs.category" defaultMessage="" />
      ),
    },
   
  ];
  
  

  
  const actionMenu = (category) => (
    <Menu className="row-action-dropdown">
      <Menu.Item  key="0" value="title" disabled={true}>
        Actions
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item key="1" 
      onClick={() => handleViewClick(category)}>
        <i className="fi fi-tr-overview"></i>
        View
      </Menu.Item> */}
      <Menu.Item key="2" 
       onClick={() => {
        props.updateState({ categoryCreateDrawerVisible: true , isUpdate: true , categoryData:category});

       }}>
        <i className="fi fi-rr-edit"></i>
        Edit
      </Menu.Item>
      <Menu.Item key="3" onClick={() => {
         const data = {
          title: "Confirmation",
          
       
          modalBody:<div>Are you sure you want to delete?</div>,
          handleSubmit: () => {
           props.deleteCategory({categoryId: category.blogCategoryId} ,props)
          },
        };
          props.pushModalToStack(data)
        
      
      }}
        
        >
        <i className="fi fi-rr-trash"></i>
        Delete
      </Menu.Item>
    </Menu>
  );

  const [childOnList, setChildOnList] = useState([]);

  const isChildOn = (id) => childOnList.includes(id);

  const setChildOff = (id) => {
    setChildOnList(childOnList.filter(itemId => itemId !== id));
  };

  const toggleChild = (id) => {
    setChildOnList((prevList) =>
      prevList.includes(id) ? prevList.filter((itemId) => itemId !== id) : [...prevList, id]
    );
  };

  const getSubcategories = (subcategories = [], parentIndex = '0') => {
    return subcategories.map((subcategory, i) => {
      const index = `${parentIndex}.${i + 1}`;
      const isSubcategoryOpen = isChildOn(subcategory.blogCategoryId);
  
      // Convert parentIndex to string and calculate the nesting level
      const nestingLevel = parentIndex.toString().split('.').length;
      const marginLeft = 40 + (i+1) * 10; // 30px base margin + 10px for each nesting level
  
      return (
        <React.Fragment key={index}>
          <tr>
            <td>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}px` }}>
                {subcategory?.subCategories && subcategory.subCategories.length > 0 && (
                  <span
                   
                    onClick={() => toggleChild(subcategory.blogCategoryId)}
                    style={{ marginRight: '10px', marginLeft: '-20px' }}
                  >
                    {isSubcategoryOpen ? <i className="fi fi-br-minus"></i> : <i className="fi fi-br-plus"></i>}
                  </span>
                )}
                <div>{subcategory.categoryName||<div className="empty-data-box"/>}</div>
              </div>
            </td>
            <td className="text-center">
                <span>{(subcategory?.subCategories||[])?.length}</span>
              </td>
              <td className="text-center" >
              {subcategory.dateCreated
                                            ? getMomentDateForUIReadOnly({
                                                date: subcategory.dateCreated,
                                            })
                                            : <div className="empty-data-box"/>}
              </td>
              <td className="switch-status text-center">
              <Switch
                            // checkedChildren="Active"
                            // unCheckedChildren="Inactive"
                            defaultChecked={subcategory?.isPublished}
                            onChange={(checked)=>{ 
                              const data = {
                                title: "Confirmation",
                                
                                // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                                modalBody:<div>Are you sure you want change the status?</div>,
                                handleSubmit: () => {
                                  props.updateCategoryPublishStatus({categoryId: subcategory?.blogCategoryId ,isPublished: checked ? 1: 0} ,props)

                                },
                              };
                              
                                props.pushModalToStack(data)
                            }}
                          />
              </td>
        
              <td className="text-center">
                      <div className="action-icon">
                              <DropdownAction
                                overlay={actionMenu(subcategory)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </div>
                       
                      </td>
             
             
          </tr>
          {isSubcategoryOpen && getSubcategories(subcategory.subCategories, index)}
        </React.Fragment>
      );
    });
  };
  const {blogCategoryList} = props;

    return (
        <>
          <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.blogs.category"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        <button
            className="create-action-btn"
            onClick={() => {
              props.updateState({ categoryCreateDrawerVisible: true , isUpdate: false});
            }}
          >
            <i className={ICONS.ADD} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>
        <div className="view-container">
        <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">Category List</div>
                   
                           
                                <div className="vertical-sep" />
                                <div>{props.categoryListCount||0}</div>
                            
                        
                </div>

              
            </div>
            <Skeleton loading={props.isLoading}
                active
                paragraph={{ rows: 12 }}
            >


               





              
                <div className='table-container'>
                <table>
              <thead>
                <tr>
                  <th className='text-left' width={"30%"}>Category Name</th>
                  <th className="text-center">Count</th>
                  <th className="text-center">Date Created</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {(blogCategoryList[props.pageNumber||1]||[]).map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div className="plus-minus-icon">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {item.subCategories && item.subCategories.length > 0 ? (
                                <>
                                  <span
                                 
                                  onClick={() => toggleChild(item.blogCategoryId)}
                                >
                                  {isChildOn(item.blogCategoryId) ? <i class="fi fi-br-minus"></i> : <i class="fi fi-br-plus"></i>}
                                </span>
                                <div style={{marginLeft:"10px"}}>{item?.categoryName||<div className="empty-data-box"/>}</div>
                                </>
                                
                              )
                            :
                            <div style={{marginLeft:"22px"}}>{item?.categoryName||<div className="empty-data-box"/>}</div>
                            }
                             
                            </div>
                          </div>
                        </td>
                    
                        <td className="text-center">
                          <span>{(item?.subCategories||[])?.length}</span>
                        </td>
                        <td className="text-center">
                        {item.dateCreated
                                            ? getMomentDateForUIReadOnly({
                                                date: item.dateCreated,
                                            })
                                            : <div className="empty-data-box"/>}
                        </td>
                     
                        <td className="switch-status text-center">
                      <Switch
                               
                                checked={item?.isPublished}
                                onChange={(checked)=>{ 
                                  const data = {
                                    title: "Confirmation",
                                    
                                    // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                                    modalBody:<div>Are you sure you want change the status?</div>,
                                    handleSubmit: () => {
                                      props.updateCategoryPublishStatus({categoryId: item?.blogCategoryId ,isPublished: checked ? 1: 0} ,props)
    
                                    },
                                  };
                                  
                                    props.pushModalToStack(data)
                                }}
                              />
                      </td>
                      <td className="text-center">
                      <div className="action-icon">
                              <DropdownAction
                                overlay={actionMenu(item)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </div>
                       
                      </td>
                      </tr>
                      {isChildOn(item.blogCategoryId) && getSubcategories(item.subCategories, index + 1)}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
                </div>

            </Skeleton>
            <Pagination
                size="small"
                total={32}
                
                defaultPageSize={props.pageSize} 
                showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                // onChange={(pageNumber, pageSize) => {
                //     loadPageData(pageNumber, pageSize);
                 
                //     updateState({ pageSize: pageSize, pageNumber: pageNumber })
                // }}
                // onShowSizeChange={(pageNumber, pageSize) => {
                //     handlePageSizeChange(pageNumber||1, pageSize, props)
                //     updateState({ pageSize: pageSize, pageNumber: pageNumber })
                // }}
            />
       
        </div>
        </>
      );
 
};

export default CategoryList;
