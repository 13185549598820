import { Button, Col, DatePicker, Drawer, Input, Radio, Row } from 'antd';
import React, { Fragment, useState } from 'react';
import { CustomButton } from '../../../general/CustomButton';
import { FormattedMessage } from 'react-intl';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { addDaysToDate, getCurrencySymbol, getMomentDateForUI } from '../../../../utils';
import find from 'lodash.find';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';
import filter from 'lodash.filter';
import { DetailDropdown } from '../../../general/DetailDropdown';
import { CONSTANTS } from '../../../../static/constants';
import { ErrorMsg } from '../../../general/ErrorMessage';
import moment from 'moment-timezone';

const { Search } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const QuantityDrawer = (props) => {
  const { pageNumber, companyInfo, intl, updateDrawerState, supplierSummary, 
    products, selectedProduct, submittedOnce, hasVariant, selectedVariantProduct, 
    variantList, productQuantity, purchasedPrice, allLedgerAccounts, 
    selectedCreditLedger, selectedDebitorLedger, selectedSupplierName, 
    suppliers, purchaseOrderDate} = props;
  const closeDrawer = () => props.updateState({ isQuantityDrawerVisible: false, data : {} });

  const validateTab = () => {
    return !(selectedProduct?.itemName && Number(productQuantity) && Number(purchasedPrice));
  };
  const handleSubmit = () => {

    props.createStockQuantity(props);
    props.hideModal();
    closeDrawer();
  };

   const  onSearch =(text) =>{
    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 25,
    
    };
    props.fetchProducts(payload);
  }

  const  onSearchSupplier =(text) =>{
  
    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      pageNumber: 1,
      searchedText: text,
      pageSize: 25,
    
    };
    props.fetchSuppliers(payload);
  }

  const handleSelectChange = (id, key) => {
    const selectedItem = find(products, { itemId: id }) || {};
     
     let list =[];
  
    if(selectedItem?.hasVariant){
      // list = [variantListItem , ...selectedItem?.inventoryItemVariantList]
      list = selectedItem?.inventoryItemVariantList;
    }
    updateDrawerState({
      [key]: selectedItem,
      hasVariant: selectedItem?.hasVariant,
      purchasedPrice: selectedItem?.inventoryItemVariantList?.[0]?.purchasedPrice,
      variantList: list,
      selectedVariantProduct:{},
    });
  };

  const handleLedgerSelectChange = (id, key) => {
    const val = find(allLedgerAccounts, { chartOfAccountDetailsId: id });
    updateDrawerState({ [key]: val });
  };

  return (
    <Drawer
      title={"Quantity"}
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.isQuantityDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={()=>{
              if (!props.submittedOnce) {
                props.updateDrawerState({ submittedOnce: true });
              }
              if(!validateTab(props)){
                if (props.hasVariant && !(selectedVariantProduct?.itemName)) {
                  return; 
                }
               
                const modalData = {
                  title: (
                    <FormattedMessage
                      id="drawer.inventory.confirmation.title"
                      defaultMessage=""
                    />
                  ),
                  modalBody: <Fragment>
                  <p>
                    <FormattedMessage
                      id="Are you sure you want to save?"
                      defaultMessage=""
                    />
                  </p>
                </Fragment>,
                  handleSubmit: () => {
                    handleSubmit(props)
                    props.hideModal();
                    closeDrawer();
                  },
                };
                props.showModal(modalData);
              }

            } }
            key={1}
          />
        </>
      }
    >
     
      <Row gutter={[16, 16]}>
        {/* Product Selection */}
        <Col span={24}>
          <div className="i-label">
            <FormattedMessage id="eStore.coupons.create.product.select" defaultMessage="" />
            <span className='required'>*</span>
          </div>
          <Dropdown
            items={products || []}
            sortItems
            valueKeyName='itemName'
            optionKeyName='itemId'
            value={selectedProduct?.itemName || ''}
            // onChange={(e)=>{
            //   onSearch(e.target.value)
            // }}
            onSearch={onSearch}
            placeholder={'Select Product'}
            canAddNew
            allowClear
            onClear={() => updateDrawerState({ selectedProduct: {} })}
            onAddNewClick={() => props.updateHeaderState({ inventory: { inventoryDrawerVisible: true } })}
            onSelect={(id) => handleSelectChange(id, 'selectedProduct')}
           
          />
          <ErrorMsg validator={() => !submittedOnce || !!selectedProduct?.itemName} message={"Product Name is required"} />
        </Col>

        {/* Variant Selection */}
        {hasVariant ? (
          <Col span={24}>
            <div className="i-label">
              <FormattedMessage id="product.openingStock.selectVariant" defaultMessage="" />
              <span className='required'>*</span>
            </div>
            <Dropdown
            items={variantList || []}
            sortItems
            valueKeyName='itemName'
            valueKeyName2="attributeValue1"
            valueKeyName3="attributeValue2"
            valueKeyName4="attributeValue3"
            optionKeyName='variantId'
            value={selectedVariantProduct?.itemName ? `${selectedVariantProduct?.itemName}${selectedVariantProduct?.attributeValue1 ? ` (${selectedVariantProduct?.attributeValue1} | ${selectedVariantProduct?.attributeValue2||''} ${selectedVariantProduct?.attributeValue3 ? `|${selectedVariantProduct?.attributeValue3}`: ''}) `:''}`:''}
            // onChange={(e)=>{
            //   onSearch(e.target.value)
            // }}
            onSearch={onSearch}
            placeholder={'Select VariantName'}
          
            allowClear
            onClear={() => updateDrawerState({ selectedVariantProduct: {} })}
           
            onSelect={(variantId) =>{
              const variantProduct = find((variantList||[]),{variantId: variantId})
              props.updateDrawerState({
                selectedVariantProduct: variantProduct
              })
            }}
           
          />
           
            <ErrorMsg validator={() => !submittedOnce || !!selectedVariantProduct?.itemName} message={"Product Variant is required"} />
          </Col>
        ):""}

        {/* Quantity Input */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="product.detail.tab.outboundInventory.quantity" defaultMessage="" />
            <span className='required'>*</span>
          </div>
          <TextBox
            value={productQuantity}
            type="number"
            placeholder={"Enter Quantity"}
            onChange={(e) => updateDrawerState({ productQuantity: e.target.value })}
          />
          <ErrorMsg validator={() => !submittedOnce || !!Number(productQuantity)} message={"Product Quantity is required"} />
        </Col>

        {/* Purchase Price Input */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="drawer.inventory.purchasedPrice" defaultMessage="" />
            <span className='required'>*</span>
          </div>
          <TextBox
            value={purchasedPrice}
            addonBefore={getCurrencySymbol(companyInfo.currencyCode)}
            type="number"
            placeholder={"Purchase Price"}
            onChange={(e) => updateDrawerState({ purchasedPrice: e.target.value })}
          />
          <ErrorMsg validator={() => !submittedOnce || !!Number(purchasedPrice)} message={"Purchase Price is required"} />
        </Col>

        {/* Supplier Dropdown */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="drawer.supplier.companyName" defaultMessage="" />
          </div>
          <Dropdown
            items={suppliers[pageNumber] || []}
            sortItems
            valueKeyName='supplierStoreName'
            optionKeyName='supplierId'
            value={selectedSupplierName?.supplierStoreName || ''}
            placeholder={intl.formatMessage({ id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: '' })}
            canAddNew
            onSearch={onSearchSupplier}
            allowClear
            onClear={() => updateDrawerState({ selectedSupplierName: {} })}
            onAddNewClick={(payload) => props.updateHeaderState({ supplier: { supplierDrawerVisible: true, supplierName: payload.textEntered } })}
            onSelect={(supplierId) => {
              const supplierName = find(suppliers[pageNumber], { supplierId }) || {};
              updateDrawerState({ selectedSupplierName: supplierName });
            }}
          />
        </Col>

        {/* Date Picker */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="drawer.supplier.relationshipSince.placeholder" defaultMessage="" />
          </div>
          <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${
                    purchaseOrderDate
                      ? moment(new Date(purchaseOrderDate), format)
                      : moment(new Date(), format)
                  }`}
                  defaultValue={
                    purchaseOrderDate
                      ? moment(new Date(purchaseOrderDate), format)
                      : moment(new Date(), format)
                  }
                  onChange={(selectedDate) => updateDrawerState({ purchaseOrderDate: selectedDate })}
                 
                />
         
        </Col>

        {/* Credit Ledger Dropdown */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="product.openingStock.selectedCreditledger" defaultMessage="" />
          </div>
          <Dropdown
          
           
            items={allLedgerAccounts || []}
            valueKeyName='ledgerAccountName'
            value={allLedgerAccounts.length ? selectedCreditLedger?.ledgerAccountName : ''}
            optionKeyName='chartOfAccountDetailsId'
            onSelect={(id) => handleLedgerSelectChange(id, 'selectedCreditLedger')}
          />
        </Col>

        {/* Debit Ledger Dropdown */}
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="product.openingStock.selectedDebitledger" defaultMessage="" />
          </div>
          <Dropdown
           
           
            items={allLedgerAccounts || []}
            valueKeyName='ledgerAccountName'
            value={allLedgerAccounts.length ? selectedDebitorLedger?.ledgerAccountName : ''}
            optionKeyName='chartOfAccountDetailsId'
            onSelect={(id) => handleLedgerSelectChange(id, 'selectedDebitorLedger')}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default QuantityDrawer;
