import axios from "axios";
import config from "../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  INVENTORY_ACTION_LIST,
} from "../../../static/constants";
import cloneDeep from "lodash/cloneDeep";
import {
  fetchProducts,
  getProductCount,
  resetPaginatedData,
} from "../../product/Listing/action";
import { showToasterMessage } from "../../../utils";
import { version } from "jszip";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchCategory = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_DRAWER_LOADER });
    return (
      axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/getInventoryItemCategory?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=${payload.pageSize || 50}`
        )
        // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/getInventoryItemCategory?relationshipId=${101}&pageNumber=0&pageSize=50`)

        .then((res) => {
          dispatch({
            type: INVENTORY_ACTION_LIST.CATEGORY_LIST,
            data: res.data,
          });
          dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    );
  };
};
export const deleteCategory = (payload) => {
  const requestObj = {
    inventoryItemCategoryId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/deleteInventoryItemCategory`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.CATEGORY_LIST_DELETION,
          data: { id: payload.id },
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.CATEGORY_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

// export const addCategory = (payload) => {
//   const requestObj = {
//     relationshipId: payload.relationshipId,
//     categoryName: payload.text,
//     //dateCreated: getCurrentDateForBackend(new Date()),
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/createInventoryItemCategory`, requestObj)
//       .then(res => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         dispatch({type: INVENTORY_ACTION_LIST.CATEGORY_LIST_ADDITION, data: res.data})
//         showToasterMessage({
//           description: (res || {}).message || 'Created successfully.',
//           messageType: 'success'
//         })
//       })
//       .catch((err = {}) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (err.__isRetryRequest) {
//           err.actionToCall = INVENTORY_ACTION_LIST.CATEGORY_LIST_ADDITION;
//         } else {
//           showToasterMessage({
//             description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//           })
//         }
//       })
//   }
// }
export const addCategory = (payload, props) => {

  const requestObj = {
    relationshipId: payload.relationshipId,
    categoryName: payload.text || payload.categoryName,
    categoryDesc: payload.description,
    //dateCreated: getCurrentDateForBackend(new Date()),
    inventoryItemCategoryId: payload.inventoryItemCategoryId,
    version: payload.version,
    awsKeyName: payload.awsKeyName,
    isPublished: payload.isPublished,
    subcategories: payload.subcategories || [],
    inventoryItemCategoryRefs: payload.inventoryItemCategoryRefs || [],
    parentId: payload.parentId,
    parentName: payload.parentName,
    categoryPageTitle: payload.categoryPageTitle,
    categoryPageDescription: payload.categoryPageDescription,
    productTaxCode: payload.productTaxCode,
    categoryThumbnail:payload.categoryThumbnailImage

  };
  let formData = new FormData();
  // if (payload.fileList && payload.fileList.length) {
  //   payload.fileList.forEach((file) => {
  //     //formData.append('files', file.originFileObj, file.name);
  //     formData.append('files', file, file.name);
  //   })
  // }

  if (payload.fileList) {
    formData.append("files", payload.fileList);
  }

  if (payload.categoryThumbnailUploadeImage?.length) {

    formData.append("file", payload.categoryThumbnailUploadeImage[0].originFileObj);
  }
  formData.append("categoryData", JSON.stringify(requestObj, true));
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/createInventoryItemCategory`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.inventoryItemCategoryId) {
          props && props.fetchCategory && props.fetchCategory(payload);
        } else {
          dispatch({
            type: INVENTORY_ACTION_LIST.CATEGORY_LIST_ADDITION,
            data: res.data,
          });
        }
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.CATEGORY_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchBrand = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_DRAWER_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productBrand/getAllProductBrands?relationshipId=${payload.relationshipId}&pageSize=50&pageNumber=0`
      )
      .then((res) => {
        dispatch({ type: INVENTORY_ACTION_LIST.BRAND_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
      });
  };
};

export const deleteBrand = (payload) => {
  const requestObj = {
    itemBrandId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productBrand/deleteProductBrand`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.BRAND_LIST_DELETION,
          data: { id: payload.id },
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.BRAND_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addBrand = (payload, props) => {
  
  const requestObj = {
    relationshipId: payload.relationshipId,
    brandName: payload.text,
    itemBrandId: payload.id,
    version: payload.version,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productBrand/createProductBrand`,
        requestObj
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: INVENTORY_ACTION_LIST.BRAND_LIST_ADDITION,
          data: res.data,
        });
        const payload2 = {
          relationshipId: props.companyInfo.relationshipId,
        };
        props.fetchBrand(payload2);
        showToasterMessage({
          description: (res || {}).message || "Saved successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.BRAND_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchManufacturer = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productManufacturer/getProductManufacturer?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {

        dispatch({
          type: INVENTORY_ACTION_LIST.MANUFACTURE_LIST,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteManufacturer = (payload) => {
  const requestObj = {
    inventoryProductManufacturerId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productManufacturer/deleteProductManufacturer`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.MANUFACTURE_LIST_DELETION,
          data: { id: payload.id },
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.MANUFACTURE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addManufacturer = (payload, props) => {
 
  const requestObj = {
    relationshipId: payload.relationshipId,
    manufacturerName: payload.text,
    inventoryProductManufacturerId: payload.id,
    version: payload.version,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/productManufacturer/createProductManufacturer`,
        requestObj
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const payload2 = {
          relationshipId: payload.relationshipId,
          pageNumber: 1,
          pageSize: 100,
        };
        dispatch({
          type: INVENTORY_ACTION_LIST.MANUFACTURE_LIST_ADDITION,
          data: res.data,
        });
        props.fetchManufacturer(payload2);

        showToasterMessage({
          description: (res || {}).message || "Saved successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = INVENTORY_ACTION_LIST.MANUFACTURE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

// export const fetch = (payload) => {
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios
//         .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupUOM/getAllUOM?relationshipId=${payload.relationshipId}`)
//         .then(res => {
//           dispatch({ type: INVENTORY_ACTION_LIST.UOM_LIST, data: res.data });
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         })
//         .catch(err => {
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         })
//     }
//   }

export const fetchItemVariantAttributes = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/getAllItemVariantAttributes?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteAttribute = (payload) => {
  const requestObj = {
    itemVariantAttributeId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/deleteInventoryItemVariantAttribute`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_DELETION,
          data: { id: payload.id },
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall =
            INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addAttribute = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    attributeName: payload.text,
    updatedByUser: "",
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/createInventoryItemVariantAttribute`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_ADDITION,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall =
            INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchWarehouseNames = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/warehouseMaster/getAllWarehouseNames?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.WAREHOUSE_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
      });
  };
};

export const getSkuData = (payload) => {
  return (dispatch) => {
   
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/suggestSKU?relationshipId=${payload.relationshipId}&productName=` +
        payload.productName +
        `&skuName=` +
        (payload.skuName || "") +
        `&useSystemSku=` +
        payload.useSystemSku
      )
      .then((res) => {
        dispatch({ type: INVENTORY_ACTION_LIST.SKU_DATA, data: res.data });
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
export const isDuplicateProductName = (payload,callBack,props) => {
  return (dispatch) => {
   
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/isDuplicateProductName?relationshipId=${payload.relationshipId}&itemName=${payload.itemName}`)
      .then((res) => {
        // dispatch({ type: INVENTORY_ACTION_LIST.SKU_DATA, data: res.data });
        if(res.data){
          showToasterMessage({
            description: "Duplicate product name",
          });
        }
        callBack(res.data)
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
export const resetSKUData = () => {
  return dispatch => {
    dispatch({ type: INVENTORY_ACTION_LIST.SKU_DATA, data: {} });
  }
}

export const getAllGstRates = (payload) => {
  if (!payload.pageSize) {
    payload.pageSize = 10;
    payload.pageNumber = 0;
  }
  var url =
    `financeGSTGoodsRates/getAllGSTGoodsRates?financeType=` +
    payload.type +
    `&pageNumber=` +
    payload.pageNumber +
    `&pageSize=` +
    payload.pageSize;
  if (payload.searchString) {
    url = url + `&searchString=` + payload.searchString;
  }
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/` +
        url
      )
      .then((res) => {
        dispatch({ type: INVENTORY_ACTION_LIST.ALL_GST_RATES, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getAllLedgerAccounts = (payload) => {
  var url = `chartOfAccountDetails/getAllLedgerAccounts?relationshipId=${payload.relationshipId}&pageSize=500&pageNumber=0`;
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/` +
        url
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.ALL_LEDGER_ACCOUNTS,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getSalesLedgerAccounts = (payload) => {
  var url = `chartOfAccountDetails/getSalesLedgerAccounts?relationshipId=${payload.relationshipId}&pageSize=500&pageNumber=0`;
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/` +
        url
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.SALES_LEDGER_ACCOUNTS,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getPurchaseLedgerAccounts = (payload) => {
  var url = `chartOfAccountDetails/getPurchaseLedgerAccounts?relationshipId=${payload.relationshipId}&pageSize=500&pageNumber=0`;
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/` +
        url
      )
      .then((res) => {
        dispatch({
          type: INVENTORY_ACTION_LIST.PURCHASE_LEDGER_ACCOUNTS,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getAllTaxByName = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxModule/getAllTaxes?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        switch (payload.actionName) {
          case INVENTORY_ACTION_LIST.CGST_TAXES:
            dispatch({
              type: INVENTORY_ACTION_LIST.CGST_TAXES,
              data: res.data,
            });
            break;
          case INVENTORY_ACTION_LIST.SGST_TAXES:
            dispatch({
              type: INVENTORY_ACTION_LIST.SGST_TAXES,
              data: res.data,
            });
            break;
          case INVENTORY_ACTION_LIST.IGST_TAXES:
            dispatch({
              type: INVENTORY_ACTION_LIST.IGST_TAXES,
              data: res.data,
            });
            break;
          case INVENTORY_ACTION_LIST.GLOBLE_TAXES:
            dispatch({
              type: INVENTORY_ACTION_LIST.GLOBLE_TAXES,
              data: res.data,
            });
            break;
          default:
            break;
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const createInventoryItem = (payload) => {
 
  const requestObj = getItemRequestObj(payload);
  var formData = new FormData();
  // const obj = {
  //   [payload.sku]: payload.fileToUpload
  // }
  if (payload.fileToUpload && payload.fileToUpload?.length>0) {
    formData.append(`file[${payload.sku}]`, payload.fileToUpload);
  }
  formData.append("inventory", JSON.stringify(requestObj, true));
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/createInventoryItem`,
        formData
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: INVENTORY_ACTION_LIST.SKU_DATA, data: {} }); // Reset sku data
        payload.updateState({ inventory: { inventoryDrawerVisible: false } });
        let data = {
          relationshipId: payload.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        };
       if(payload.fromListingPage)
       { 
        resetPaginatedData()(dispatch);
        fetchProducts(data)(dispatch);
        getProductCount(data)(dispatch);
      }
        showToasterMessage({
          messageType: "success",
          description: lang["common.saved.success"],
        });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payload.updateState({ inventory: { inventoryDrawerVisible: false } });
        dispatch({ type: INVENTORY_ACTION_LIST.SKU_DATA, data: {} }); // Reset sku data
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };

};

const getVariantRow = (payload) => {
  if (
    payload.hasVariant &&
    payload.inventoryItemVariantList &&
    payload.inventoryItemVariantList.length > 0
  ) {


    return payload.inventoryItemVariantList;
  } else {

    var copy = cloneDeep({ ...payload.variantObj, attributeValue1: "", attributeValue2: "", attributeValue3: "" });
    copy.itemNameForReporting = payload.itemName;
    copy.currentStock = payload?.qtyCurrentStock;
    copy.itemId = payload.isUpdate ? payload.itemId : undefined;
    copy.relationshipId = payload.relationshipId;
    return [copy];
  }
};

const getDefaultTaxList = (payload) => {
  payload.defaultTaxObj.relationshipId = payload.relationshipId;
  payload.defaultTaxObj.itemId = payload.isUpdate ? payload.itemId : undefined;
  return [payload.defaultTaxObj];
};

const getItemRequestObj = (payload) => {
  let createdByUserId = payload.userInfo?.relationshipEmployeeId || 0;
  return {
    targetSales: payload.targetSales,
    coaPurchaseLedgerAccountId: payload.coaPurchaseLedgerAccountId || 0,
    coaSalesLedgerAccountId: payload.coaSalesLedgerAccountId || 0,
    itemName: payload.itemName,
    itemId: payload.isUpdate ? payload.itemId : undefined,
    productImage: payload.isUpdate ? payload.productImage : undefined,
    sku: payload.sku,
    description: payload.description,
    categoryId: payload.categoryId || 0,
    categoryName: payload.categoryName,
    brandId: payload.brandId || 0,
    brandName: payload.brandName,
    manufacturerId: payload.manufacturerId || 0,
    manufacturerName: payload.manufacturerName,
    articleNumber: payload.articleNumber,
    partNumber: payload.partNumber,
    customerStockNumber: payload.customerStockNumber,
    lowStockQty: payload.lowStockQty,
    reorderQty: payload.reorderQty,
    aboveThresholdAlert: payload.aboveThresholdAlert,
    supplierId: payload.supplierId,
    createdByUserId: createdByUserId,
    hsnCodeRate: payload.hsnCodeRate,
    hsnCodeDescription: payload.hsnCodeDescription,
    useSystemSku: payload.useSystemSku,
    totalVariants: payload.totalVariants,
    shortDescription:payload.shortDescription,
    inventoryItemDefaultTaxList: getDefaultTaxList(payload),
    hasVariant: payload.hasVariant || 0,
    outOfStock: payload.outOfStock || 0,
    velocityAlert: payload.velocityAlert || 0,
    inventoryItemVariantList: getVariantRow(payload),
    relationshipId: payload.relationshipId,
    warehouseMasterId: payload.warehouseMasterId,
    warehouseName: payload.warehouseName,
    itemPageDescription: payload.itemPageDescription,
    itemPageTitle: payload.itemPageTitle,
    //isPopulateTax: payload.isPopulateTax,
    isPopulateTax:
      payload.defaultTaxObj &&
        (payload.defaultTaxObj.taxSingleRateId || payload.defaultTaxObj.hsnCode)
        ? 1
        : 0,
    //dateCreated: getCurrentDateForBackend(new Date()),
    upc: payload.upc,
    ean: payload.ean,
    isbn: payload.isbn,
    amazonAsinNumber: payload.amazonAsinNumber,
    inventoryItemNotificationList: [],
    version: payload.version,
    shippingWeight: payload.shippingWeight,
    measurementUnit: payload.measurementUnit,
    serialNumber: payload.serialNumber,
    b2bIsBestSellingItem: payload.b2bIsBestSellingItem || 0,
    b2bIsClearanceItem: payload.b2bIsClearanceItem || 0,
    b2bIsDealOfTheDayItem: payload.b2bIsDealOfTheDayItem || 0,
    b2bIsNewArrivalItem: payload.b2bIsNewArrivalItem || 0,
    b2bIsPublisedItem: payload.b2bIsPublisedItem || 1,
    b2bIsUnPublisedItem: payload.b2bIsUnPublisedItem ||0,
    inventoryItemCategoryRefs: payload.inventoryItemCategoryRefs || [],
    customerMaterialNumber:payload.customerMaterialNumber
 
  };
};

export const getItemByCategory = (payload, props) => {
  return dispatch => {
    return axios
    .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecomInventoryItem/getItemsByFilters`,payload)
    .then(res => {
        // if(props.activeTabKey === "1"){
        //   dispatch({ type: INVENTORY_ACTION_LIST.FETCH_RETURN_PRODUCTS, data: res.data });
        // }
        // else if(props.activeTabKey === "2"){

        //   dispatch({ type: INVENTORY_ACTION_LIST.FETCH_REPLACE_PRODUCTS, data: res.data });
        // }
        // else if(props.activeTabKey === "3"){
        //   dispatch({ type: INVENTORY_ACTION_LIST.FETCH_CANCEL_PRODUCTS, data: res.data });
        // }
        const actionMap = {
          "1": INVENTORY_ACTION_LIST.FETCH_RETURN_PRODUCTS,
          "2": INVENTORY_ACTION_LIST.FETCH_REPLACE_PRODUCTS,
          "3": INVENTORY_ACTION_LIST.FETCH_CANCEL_PRODUCTS,
        };
        
        const actionType = actionMap[props.activeTabKey || "1"];
        if (actionType) {
          dispatch({ type: actionType, data: res.data });
        }


      })
      .catch((err) => {
          if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
          }
      })
  }
}