import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Empty } from "antd";
import moment from "moment-timezone";
import { fetchPaginationDataIfNeeded } from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import { CONSTANTS } from "../../../../static/constants";
import StandardPurchaseRequest from "../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest";

const purchaseRequestDraft = (props) => {
  const {
    purchaseRequestDraftList,
    updateState,
    companyInfo,
    purchaseRequestDraftCount,
    pageNumber,
  } = props;
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchPurchaseRequestDrafts",
      "purchaseRequestDraftList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetDraftPaginatedData();
    props.fetchPurchaseRequestDrafts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
  //         },
  //         field: "purchaseRequestDraftId",

  //         cellRendererFramework: (param) => <div className="cursor-pointer" >
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/pr-create',
  //                         state: {
  //                             prData: param.data.prData,
  //                             purchaseRequestDraftId: param.data.purchaseRequestDraftId,
  //                             clone: true
  //                         }
  //                     }}
  //             >Draft-{param.value}</Link>
  //         </div>,
  //         rowDrag: true,
  //         resizable: true,
  //         width: 100,
  //     },

  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
  //     //     },
  //     //     cellRendererFramework: (link) => {
  //     //         return link.data.prData.totalPOAmount && link.data.prData.totalPOAmount !== '-' ? <span> {link.data.prData.foreignCurrencyIcon ? <i className={link.data.prData.foreignCurrencyIcon}></i> : (link.data.prData.foreignCurrency ? <span>{link.data.prData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  { Number(link.data.prData.totalPOAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //     //     },
  //     //     field: "totalPOAmount",
  //     //     resizable: true,
  //     //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //     //     width: 100,
  //     // },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "purchaseRequestDate",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.data.prData.purchaseRequestDate && moment(link.data.prData.purchaseRequestDate).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
  //                     {(link.data.prData.purchaseRequestDate && moment(link.data.prData.purchaseRequestDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true,
  //         width: 100,
  //     },

  // ]

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     params.api.sizeColumnsToFit();
  // }

  // const _isColumnToHide = (columnName) => {
  //     return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
  // }

  return (
    <Fragment>
      {/* <div className='view-container sales-order-details mt0'> */}
     
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.purchaseRequestDraft.table.heading"
                defaultMessage=""
              />
            </div>        
                <div className="vertical-sep" />
                <div>{props.purchaseRequestDraftCount}</div>          
          </div>
          <div className="right-actions"></div>
        </Skeleton>
        </div>
        {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            {props.txColumns.length ? <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={purchaseRequestDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true} 
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                            >
                            </AgGridReact>: null}
                        </div>
                    </div> */}
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.draftNo"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.date"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {purchaseRequestDraftList[pageNumber] && (purchaseRequestDraftList[pageNumber] || []).length ?
                purchaseRequestDraftList[pageNumber].map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="cursor-pointer">
                          <div
                            onClick={() => {
                              props.popModalFromStack();
                              props.updateState({
                                prData: rowData.prData,
                                purchaseRequestDraftId:
                                  rowData.purchaseRequestDraftId,
                                clone: true,
                              });
                              props.pushModalToStack({
                                modalBody: (
                                  <StandardPurchaseRequest
                                    {...props}
                                    versionOfDraft ={rowData.version}
                                    prDataa={rowData.prData}
                                    purchaseRequestDraftId={
                                      rowData.purchaseRequestDraftId
                                    }
                                    clone={true}
                                  />
                                ),
                                width: "100%",
                                hideTitle: true,
                                hideFooter: true,
                                keyboard: false,
                                wrapClassName: "new-transaction-wrapper",
                              });
                            }}
                            // to={
                            //     {
                            //         pathname: '/admin/pr-create',
                            //         state: {
                            //             prData: rowData.prData,
                            //             purchaseRequestDraftId: rowData.purchaseRequestDraftId,
                            //             clone: true
                            //         }
                            //     }}
                          >
                            Draft-{rowData.purchaseRequestDraftId}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Tooltip
                            placement="topLeft"
                            title={
                              rowData.prData.purchaseRequestDate &&
                              moment(rowData.prData.purchaseRequestDate).format(
                                CONSTANTS.TABLE_DATE_FORMAT
                              )
                            }
                          >
                            {rowData.prData.purchaseRequestDate &&
                              moment(rowData.prData.purchaseRequestDate).format(
                                CONSTANTS.TABLE_DATE_FORMAT
                              )}
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })
              :
              <tr key="empty-data-box">
              <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                  <Empty />
              </td>
          </tr>
              }
            </tbody>
          </table>
        </div>
        </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={purchaseRequestDraftCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(purchaseRequestDraft);
