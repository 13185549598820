import React, { Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Input,
  Popover,
  Tooltip,
  Divider,
  Skeleton
} from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";

import { Dropdown } from "../../../general/Dropdown";
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import {
  showToasterMessage,
  fixedDecimalAndFormateNumber,
  fixedDecimalNumber,
} from "../../../../utils";
import { CustomTableUpload } from "../../../general/UploadTable";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import {
  CUSTOM_CONFIGURATION,
  CUSTOM_CONFIGURATION_FOOTER,
} from "../../../../static/ckEditorConfigConstants";
import { AWSFileTable } from "../../../general/AWSFileTable";
import { TextBox } from "../../../general/TextBox";
// import { TextBox } from "../../../general/TextBox";
// import { PlusCircleOutlined } from '@ant-design/icons';
// import { hideModal } from "../../../../actions/commonActions";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountList:props.rootDiscountList || [{}],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      rootExpenseList: props.rootExpenseList || [{}],
      setOnce:false,
      totalCouponDiscount:props.couponAmount || 0,
      coupondata:props.coupondata || 0,
    };
  }
  calculateSubtotal = (emptycoupan) => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let calcSubtotal = 0;
    // let totalCouponDiscount =!emptycoupan? this.props.couponAmount || 0:0;
    let totalCouponDiscount =0;
    let maxValueReached = 0

    if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
      this.setState({
        rootDiscountPercent: this.props.rootDiscountPercent,
        rootDiscountAmount: this.props.rootDiscountAmount,
      });
    }
    let minVaidation =this.state.coupondata?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? this.state.coupondata?.coupon?.minPurchaseAmount <= this.state.subtotal : this.state.coupondata?.coupon?.minPurchaseAmount <= this.state.total : true 
    if(!minVaidation   && Object.keys(this.state.coupondata || {})?.length ){
      showToasterMessage({
        messageType: "warning",
        description: "Add More Item To apply Coupon",
      });
      this.props.updateState({
        coupondata:null,
        viewCoupon:true,
        couponAmount:0,
        totalCouponDiscount:0
      })
      this.setState({
        coupondata:null,
        viewCoupon:true,
        couponAmount:0,
        totalCouponDiscount:0,
        // total:this.state.total + preCoupanAmt,
      })
  this.calculateSubtotal(true);
}
    if(Object.keys(this.state.coupondata || {})?.length){
      let type = this.state.coupondata?.couponType === "PERCENTAGE" ?1:0;
      if(type){
        maxValueReached = (this.state?.subtotal*(this.state.coupondata?.discountPercentage/100)) >=  this.state.coupondata?.maxDiscount ? true :false;

      }


    }
    if (!this.state.expenseAmount && this.props.expenseAmount) {
      this.setState({
        expenseAmount: this.props.expenseAmount,
      });
    }
    let rootDiscountList = this.state.rootDiscountList || [];
    let rootExpenseList = this.props.rootExpenseList || [];
    rootDiscountList.forEach((discountObj, i) => {
      discountObj.rootDiscountAmount = 0;
      rootDiscountList[i] = discountObj;
    });
    let self = this;
   let coupondata = this.props.coupondata;
   let props = this.props;
  //  let state = .state;
  this.gridApi &&
  this.gridApi.forEachNode(function (rowNode, index) {
    let stringifiedRowData = JSON.stringify(rowNode.data);
    let totalAmountOfColumn = 0;
    
    const rowdata = JSON.parse(stringifiedRowData);
    if (rowdata.qty && rowdata.rate) {
      totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate); 
        calcSubtotal = Number(calcSubtotal) + totalAmountOfColumn;
    }
  });
    this.gridApi &&
      this.gridApi.forEachNode(function (rowNode, index) {
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
            amountAfterCoupan:!emptycoupan ? 0 : 0
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }

          if(Object.keys(coupondata || {})?.length && props?.couponSetting?.isCoupanAppliedBeforeTax  && !emptycoupan  ){
            let discountAmount = Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            if(coupondata.couponType === "PERCENTAGE"){
              let discountPercent = maxValueReached ?((1 - ((Number(calcSubtotal -discountAmount) - (self.state.coupondata?.maxDiscount))  / Number(calcSubtotal -discountAmount))) * 100) : coupondata.discountPercentage;
              let itemLevelCoupAmount = Number(discountPercent) * 0.01 * totalAmountOfColumn;
              taxObj.amountAfterCoupan =(totalAmountOfColumn - itemLevelCoupAmount)?.toFixed(2);
              
              
              
              totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
            }else if(coupondata.couponType === "FLAT"){

              let discountPercent = ((1 - ((Number(calcSubtotal -discountAmount) - (self.state.coupondata?.maxDiscount))  / Number(calcSubtotal -discountAmount))) * 100)?.toFixed(2);
              let itemLevelCoupAmount = Number((Number(discountPercent) * 0.01 * totalAmountOfColumn)?.toFixed(2));

              taxObj.amountAfterCoupan = (totalAmountOfColumn - itemLevelCoupAmount)?.toFixed(2);
              totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
            }
          }
          
          rootDiscountList.forEach((discountObj, i) => {
            if (discountObj.rootDiscountPercent) {
              const rootDiscountPercent = discountObj.rootDiscountPercent;
              let itemLevelDiscountAmount = Number(rootDiscountPercent) * 0.01 * totalAmountOfColumn;

              if (discountObj.isTaxable) {
                totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount = totalDiscount + itemLevelDiscountAmount + (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount || 0);
                rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount +(Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount || 0);
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0)
                ).toFixed(2);
                totalTax = totalTax + (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||0);
              } else {
                totalAmountOfColumn =
                  totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount = totalDiscount + itemLevelDiscountAmount;
                rootDiscountAmount =
                  rootDiscountAmount + itemLevelDiscountAmount;
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount
                ).toFixed(2);
              }
              // if (discountObj.taxPercent) {
              //     let taxAmount = ((Number(discountObj.taxPercent) * 0.01) * itemLevelDiscountAmount) || 0;
              //     totalDiscount = totalDiscount + taxAmount;
              //     totalAmountOfColumn = totalAmountOfColumn - taxAmount;
              //     discountObj.taxAmount = (Number(discountObj.taxPercent) * 0.01) * discountObj.rootDiscountAmount;
              // }
              rootDiscountList[i] = discountObj;
            }
          });
          // if (self.state.rootDiscountPercent) {
          //   let itemLevelDiscountAmount =
          //     Number(self.state.rootDiscountPercent) *
          //     0.01 *
          //     totalAmountOfColumn;
          //   totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
          //   totalDiscount = totalDiscount + itemLevelDiscountAmount;
          //   rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          // }

          if (rowdata.tax) {
            // if(Object.keys(coupondata || {})?.length && props?.couponSetting?.isCoupanAppliedBeforeTax  && !emptycoupan){
            //   if(coupondata.couponType === "PERCENTAGE"){
            //     let discountPercent = coupondata.discountPercentage;
            //     let itemLevelCoupAmount = Number(discountPercent) * 0.01 * totalAmountOfColumn;
            //     taxObj.amountAfterCoupan = itemLevelCoupAmount;
            //     totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
            //   }
            // }
            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            taxObj.taxName = rowdata.taxName;
            let taxAmount = Number(rowdata.tax || 0) * 0.01 * (taxObj.amountAfterCoupan?taxObj.amountAfterCoupan: totalAmountOfColumn);
            totalTax = totalTax + taxAmount;
          };
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal =
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                    ele.amountAfterCoupan =
                    Number(ele.amountAfterCoupan || 0) + Number(taxObj.amountAfterCoupan || 0);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    (ele.amountAfterCoupan > 0 ?(ele.amountAfterCoupan || 0) :Number(ele.subtotal || 0)  );
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (
                Number(taxObj.taxPercent || 0) *
                0.01 *
                (taxObj.amountAfterCoupan > 0 ?(taxObj.amountAfterCoupan || 0) :  Number((taxObj.subtotal || 0)))
              ).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;
        }
      });


      let expenseTax = 0;
      let expenseAmt = 0;
      rootExpenseList.forEach((expenseObj, i) => {
        expenseAmt += Number(expenseObj.expenseAmount || 0);
        if (
          expenseObj.selectedExpenseLedger &&
          expenseObj.selectedExpenseLedger.isLedgerTaxable &&
          expenseObj.expenseLedgerAccountTaxRate &&
          expenseObj.expenseAmount
        ) {
          expenseTax =
            (Number(expenseObj.expenseAmount || 0) *
              (expenseObj.expenseLedgerAccountTaxRate || 0)) /
            100;
        }
      });
    // total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;
    total = Number(subtotal || 0) + totalTax + expenseAmt + expenseTax;

      if(Object.keys(this.state.coupondata || {})?.length && !props?.couponSetting?.isCoupanAppliedBeforeTax){

        let type = this.state.coupondata?.couponType === "PERCENTAGE" ?1:0;
        let maxDiscount = this.state.coupondata?.maxDiscount;
        let ammount = total*(this.state.coupondata?.discountPercentage/100);

        totalCouponDiscount = !emptycoupan ? type ? ammount >= maxDiscount ? maxDiscount : ammount : maxDiscount:0;
      }

      total = total - (!emptycoupan ?totalCouponDiscount:0);

    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount: Number(rootDiscountAmount || 0).toFixed(2),
      rootDiscountList: this.props.rootDiscountList || rootDiscountList,
      totalCouponDiscount:(totalCouponDiscount || 0).toFixed(2)
    });
    this.props.updateState({
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      expenseAmount: 0,
      rootDiscountList:null
    });
  };

  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        addFunc = props.addExpenseTypes;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.expenseType" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        hideFooter: false,
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        deleteFunc = props.deleteExpenseTypes;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };
  newLedgerAccounts = (props) => {
    props.updateDrawerState({
     ledgerAccountsDrawerVisible:true
    })
   };
  render() {
    //const { txColumnSetting } = this.props;
    const self = this;
    const { itemLevelTaxes } = this.state;
    const isPlaceOfSupplySameAsRelationship =
      (this.props.relationshipBillingAddress || {}).stateName ===
      this.props.placeOfSupply;
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      onDataRendered(params);
    };

    if(this.props.valueSet && !this.state.setOnce){
     {Object.keys(this.props.coupondata || {})?.length && this.props.applieCoupon({
        relationshipId:this.props.companyInfo?.relationshipId,
        customerId:this.props.customer?.customerId,
        couponCode:this.props.coupanValue
      },(data)=>{
        if(data?.isValid){
         let minVaidation =data?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? data?.coupon?.minPurchaseAmount <= this.state.subtotal : data?.coupon?.minPurchaseAmount <= this.state.total: true 
         if(minVaidation){
           this.props.updateState({
             viewCoupon:false,
             coupondata:data,
           })
           this.setState({
             viewCoupon:false,
             coupondata:data,
           })

         this.calculateSubtotal();
         }

        }
      })}
      this.setState({
        rootExpenseList:this.props.rootExpenseList,
        rootDiscountList:this.props.rootDiscountList,
        totalCouponDiscount:this.props.couponAmount,
        coupondata:this.props.coupondata,
            setOnce:true    
      })}
    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    if (
      this.gridApi &&
      this.props.priceTypeToApply &&
      this.props.isToPriceTypeToApply
    ) {
      let self = this;
      this.gridApi.forEachNode(function (rowNode, index) {
        if (rowNode.data.product) {
          let rate =
            self.props.priceTypeToApply ===
            AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]
              ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2)
              : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
          rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
        }
      });
      this.props.updateState({
        isToPriceTypeToApply: false,
      });
    }

    const saveSalesOrder = (props, state, agGridObj, isDraft) => {
      // let isRequiredFiledMissing = false;
      // self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
      //   let { data } = rowNode;
      //   // if (index === 0 && (!data.product || !data.rate || !data.qty)) {
      //   if (index === 0 && (!(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
      //     isRequiredFiledMissing = true;
      //   } else if (!data.product && !(Number(data.rate || 0)) && !(Number(data.qty || 0))) {
      //     data = {};
      //   } else if (!(Number(data.rate || 0)) || !(Number(data.qty || 0))) {
      //     isRequiredFiledMissing = true;
      //   }
      // });

      // if ((isRequiredFiledMissing && !isDraft) || !(this.props.customer && (this.props.customer.customerId || this.props.customerId))) {
      //   return showToasterMessage({
      //     messageType: 'error', description: 'Please select Customer, Product/Description, Quantity and Rate'
      //   });
      // }

      let errorKey = "";

      if (!this.props.customer || !this.props.customer.customerId) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "missingCustomer.errorMsg",
            defaultMessage: "",
          }),
        });
      }

      self.gridApi &&
        self.gridApi.forEachNode((rowNode, index) => {
          let { data } = rowNode;
          if (index === 0 && !data.product && !data.description) {
            errorKey = "missingProduct.errorMsg";
          } else if (index === 0 && !Number(data.qty || 0)) {
            errorKey = "missingQty.errorMsg";
          } else if (index === 0 && !Number(data.rate || 0)) {
            errorKey = "missingRate.errorMsg";
          }
        });

      if (errorKey && !isDraft) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: errorKey,
            defaultMessage: "",
          }),
        });
      }

      // if ((props.fileList && props.fileList.length && (props.qualityCheckList && !props.qualityCheckList.length) && !isDraft)) {
      //   return showToasterMessage({
      //     messageType: 'error', description: 'Please select quality check'
      //   });
      // }

      const modalData = {
        modalBody: (
          <FormattedMessage
            id="customer.salesOrder.form.confirmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
       
          const payload = {
            props,
            state,
            fileToUpload: state.fileToUpload,
            agGridObj,
            isPlaceOfSupplySameAsRelationship:
              isPlaceOfSupplySameAsRelationship,
            isDraft: isDraft,
          };
          if (isDraft) {
            props.createSalesOrderDraft(payload);
          } else {
            props.createSalesOrder(payload);
          }
          props.hideModal();
        },
      };
      props.showModal(modalData);
    };

    // const saveColumnIndex = (columnState) => {
    //   let data = this.props.txPreferences || {};
    //   let gridPreference = {};
    //   data.relationshipId = (this.props.companyInfo || {}).relationshipId;
    //   if (data.tablePreference) {
    //     gridPreference = JSON.parse(data.tablePreference);
    //   }
    //   gridPreference.columnSetting = columnState;
    //   gridPreference.columnList = this.props.txColumns;
    //   data.gridPreference = JSON.stringify(gridPreference);

    //   this.props.saveUserPrefrences(data);
    // }

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //   if (event.column) {
    //     moveEvent = event;
    //   }
    //   if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //     saveColumnIndex(moveEvent.columnApi.getColumnState());
    //   }
    // }

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        //event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
            gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        headerClass: "pointer-none",
        rowDrag: true,
        pinned: true,
        suppressMovable: true,
        lockPosition: true,
        colId: "sno_1",
        hide: false,
        minWidth: 75,
        maxWidth: 75,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD] || {}).width,
        cellEditor: "customTreeDropDownStock",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
                (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue.length > 1
                    ? obj.data.selectedProductValue[1]
                    : 0;
                const itemVariantStockId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue.length === 3
                    ? obj.data.selectedProductValue[2]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                      variantId: Number(variantId),
                    })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                obj.node.data.inventoryItemVariantId = variantId;
                obj.node.data.itemVariantStockId = itemVariantStockId;
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }
              }
              if (
                this.props.priceTypeToApply &&
                obj.node.data.variant &&
                !this.props.isDataToUpdate
              ) {
                const rate =
                  this.props.priceTypeToApply ===
                  AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD
                    .PRICE_TYPE_LIST[0]
                    ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2)
                    : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(
                        2
                      );
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                ////obj.node.setDataValue('specialDiscount', 0);
                ////obj.node.setDataValue('baseRate', Number(rate));
                ////obj.node.setDataValue('rate', Number(rate));
                ////obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (
                  obj.node.data.productObj.isPopulateTax &&
                  !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)
                ) {
                  const defaultItemHsnAndTax =
                    obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                    {};
                  obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                    selectedTax =
                      find(this.props.taxes, {
                        taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                      }) || {};
                  } else {
                    selectedTax =
                      find(this.props.taxes, {
                        taxPercent:
                          (defaultItemHsnAndTax || {}).igstRate ||
                          (defaultItemHsnAndTax || {}).cgstRate ||
                          (defaultItemHsnAndTax || {}).globleTaxRate,
                      }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.tax = selectedTax.taxPercent;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                }
              }
              let isDuplicateItem = false;
              this.gridApi &&
                this.gridApi.forEachNode((rowNode, index) => {
                  const { data } = rowNode;
                  if (
                    data.product === selectedValue &&
                    index !== obj.node.rowIndex
                  ) {
                    isDuplicateItem = true;
                  }
                });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: "warning",
                  description: "Duplicate product is selected",
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.inventoryItemVariantId &&
                  data.inventoryItemVariantId ===
                    obj.node.data.inventoryItemVariantId &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
        editable: true,
        minWidth: 120,

        resizable: true,
        sortable: true,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        cellEditor: 'CKEditorGrid',
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              //params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.node.data.minHeight || 30
              }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        editable: true, // !this.props.isSQConvertions,
        sortable: true,
        minWidth: 80,
        maxWidth: 100,

        suppressMovable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.qty = value;
              this.calculateSubtotal();
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue, option) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
              obj.node.data.uomId = option.key;
            },
            allowClear: true,
            onClear: () => {
              ////obj.node.setDataValue(obj.colDef.field, undefined);
              this.props.updateState({
                uomName: undefined,
                uomId: undefined,
              });
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.hsn"
              defaultMessage=""
            />
          );
        },
        field: "hsnCode",
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        editable: true,
        sortable: true,
        resizable: true,
        hide:
          _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) ||
          (this.props.companyInfo || {}).countryName !== "India",
        colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
        cellEditor: "customTextEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.hsnCode = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              {" "}
              <span>MRP</span>
              <Tooltip
                placement="right"
                title={this.props.intl.formatMessage({
                  id: "tooltip.originalRate",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        field: "baseRate",
        maxWidth: 150,
        minWidth: 100,
        suppressMovable: true,
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        valueGetter: (params) => {
          return (
            params.data.baseRate && Number(params.data.baseRate || 0).toFixed(2)
          );
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
        editable: true,
        resizable: true,
        maxWidth: 150,
        minWidth: 100,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (params.data.specialDiscount && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          if (this.props.priceListId && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              (this.props.priceListType.toLowerCase() === "markdown"
                ? (params.data.specialDiscount || 0) *
                    0.01 *
                    params.data.baseRate +
                  this.props.priceListPercentage * 0.01 * params.data.baseRate
                : (params.data.specialDiscount || 0) *
                    0.01 *
                    params.data.baseRate -
                  this.props.priceListPercentage * 0.01 * params.data.baseRate);
          }
          return (
            params.data.rate &&
            Number(params.data.rate || 0).toFixed(
              (this.props.companyInfo || {}).decimalDigitsLengthPrice || 0
            )
          );
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.specialDiscount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        editable: true,
        resizable: true,
        minWidth: 115,
        maxWidth: 140,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
        colId: GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT + "_1",
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
        
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.specialDiscount = value;
              obj.api.refreshCells();
            },
          };
          },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.discount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        resizable: true,
        minWidth: 115,
        maxWidth: 140,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        resizable: true,
        suppressMovable: true,
        minWidth: 100,
        //maxWidth: 300,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.TAXES,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              obj.node.data.taxId = selectedTax.taxSingleRateId;
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
      },


      {
        headerComponentFramework: () => {
          return (
           "Taxable Amount"
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX + "_1",
        resizable: true,
        // pinned: "right",
        // lockPosition: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            this.calculateSubtotal();
          } 
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        // cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        //minWidth: 120,
        maxWidth: 200,
        suppressMovable: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            params.data.amount = totalAmountOfColumn
              ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
              : "";
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.comment"
                defaultMessage=""
              />
              <Tooltip
                placement="top"
                title={this.props.intl.formatMessage({
                  id: "tooltip.warehouseDetail",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
        colId: GRID_PREFERENCE_CONSTANTS.COMMENT + "_1",
        cellEditor: "agLargeTextCellEditor",
        cellEditorParams: (params) => {
          setTimeout(() => {
            if (params.node.data.scrollHeight) {
              document.querySelector(
                "textarea.ag-text-area-input"
              ).style.height = "inherit";
              document.querySelector(
                "textarea.ag-text-area-input"
              ).style.height = params.node.data.scrollHeight;
            }
          }, 100);
          return {
            maxLength: "1000",
            cols: "20",
            rows: 2,
          };
        },
        cellRendererFramework: (params) => {
          return <pre> {params.data.comment}</pre>;
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.event.target.style.height = "inherit";
          params.event.target.style.height = `${params.event.target.scrollHeight}px`;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 54) {
            params.node.setRowHeight(
              params.event.target.scrollHeight -
                params.event.target.scrollHeight * 0.1
            );
          } else {
            //params.node.setRowHeight(params.event.target.scrollHeight - 26);
          }
          this.gridApi && this.gridApi.onRowHeightChanged();
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.partNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
        editable: true,
        sortable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        resizable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
        colId: GRID_PREFERENCE_CONSTANTS.PART_NUMBER + "_1",
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage id="common.materialNumber" defaultMessage="" />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.MATERIAL_NUMBER_FIELD,
        editable: true,
        sortable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        resizable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER),
        colId: GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER + "_1",
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.stockNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER + "_1",
        editable: true,
        sortable: true,
        resizable: true,
        //minWidth: 80,
        //maxWidth: 120,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.origin"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
        editable: true,
        resizable: true,
        sortable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
        colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.countries,
            optionKeyName: "countryId",
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            valueKeyName: "countryName",
            onSelect: (selectedValue) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.origin = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              ////obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.origin = undefined;
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.hsCode"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
        editable: true,
        resizable: true,
        //minWidth: 115,
        //maxWidth: 130,
        sortable: true,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
        colId: GRID_PREFERENCE_CONSTANTS.HS_CODE + "_1",
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="">
              <Popover
                content={content}
                title={<div className="text-center">Add/Remove </div>}
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        field: "action",
        colId: "action_1",
        pinned: "right",
        minWidth: 50,
        maxWidth: 50,
        cellRenderer: "customActionEditor",
        suppressNavigable: true,
        cellClass: this.props.isSQConvertions ? "custom-readonly-cell" : "",
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({
                remove: [
                  this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex)
                    .data,
                ],
              });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          };
        },
      },
    ];
    const content = <AddAndRemoveColumn {...this.props} />;

    // if (self.gridColumnApi && self.props.txColumnSetting) {
    //   (Object.keys(self.props.txColumnSetting)).forEach(function (key) {
    //     if (self && self.props.txColumnSetting[key].index) {
    //       self.gridColumnApi.moveColumn(key, self.props.txColumnSetting[key].index);
    //     }
    //   })
    // }
    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };

    if (this.props.toUpdatePriceList) {
      this.gridApi.refreshCells({ force: true });
      this.props.updateState({ toUpdatePriceList: false });
    }

    const onModelUpdated = (params) => {
      this.calculateSubtotal();
      if (this.props.tableDataReRendered) {
        // params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_ORDER_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          switch (columnName) {
            case "isChecked": {
              e.pinned = "left";
              e.hide = false;
              break;
            }
            case "sno": {
              e.pinned = "left";
              e.hide = false;
              break;
            }
            case "action": {
              e.width = 52;
              actionIndex = index;
              e.hide = false;
              e.pinned = "right";
              break;
            }
            case "amount": {
              e.hide = false;
              e.lockPosition = true;
              amountIndex = index;
              e.pinned = "right";
              break;
            }
            default:
              e.hide = _isColumnToHide(columnName);
              break;
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_ORDER_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);

        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          switch (columnName) {
            case "isChecked": {
              e.pinned = "left";
              e.hide = false;
              break;
            }
            case "sno": {
              e.pinned = "left";
              e.hide = false;
              break;
            }
            case "action": {
              e.width = 52;
              actionIndex = index;
              e.hide = false;
              e.pinned = "right";
              break;
            }
            case "amount": {
              e.hide = false;
              e.lockPosition = true;
              amountIndex = index;
              e.pinned = "right";
              break;
            }
            default:
              e.hide = _isColumnToHide(columnName);
              break;
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    };

    return (
      <Fragment>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 7 }} active>
        
        <div className="transaction-table">
          {this.props.txColumns.length ? (
            <CustomAgGrid
              columnDefs={columnDefs}
              getRowHeight={getRowHeight}
              onModelUpdated={onModelUpdated}
              onGridReady={onGridReady}
              onColumnResized={onColumnResized}
              // onDragStopped={() => {
              //   onColumnMoved({}, true);
              // }}
              // //onColumnMoved={onColumnMoved}
              rowData={(this.props.customerSalesOrderDetailsList || [{}]).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)}
              gridStyle={{
                //width: '100%',
                height: "100%",
                marginBottom: "14px",
              }}
            />
          ) : null}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="grey-button"
            onClick={() => {
              this.gridApi.updateRowData({ add: [{}] });
            }}
          >
            <FormattedMessage
              id="customer.salesOrder.form.button.addLine"
              defaultMessage=""
            />
          </Button>
          <Button
            className="grey-button"
            onClick={() => {
              this.props.showModal({
                modalBody: "Are you sure you want to clear?",
                handleSubmit: () => {
                  let gridApi = this.gridApi;
                  let self = this;
                  this.gridApi.forEachNode(function (rowNode, index) {
                    gridApi.updateRowData({ remove: [rowNode.data] });
                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                      gridApi.updateRowData({ add: [{}] });
                    }
                    self.calculateSubtotal();
                  });
                },
              });
            }}
          >
            <FormattedMessage
              id="customer.salesOrder.form.button.clearAllLines"
              defaultMessage=""
            />
          </Button>
        </div>
        <div className="upload-btn-so">
          <CustomTableUpload
            acceptFormats={[".xls", ".pdf", ".jpeg", ".jpg"]}
            fileList={this.props.fileList}
            onChange={(fileList) => {
              this.props.updateState({
                fileList,
              });
            }}
          />
        </div>
        {(this.props?.docDetailList||[])?.length ?
                    <>


                        Previous Data
                      
                        <AWSFileTable
                        {...this.props}
                        docDetailList={this.props.docDetailList}
                        fetchTxDetail={(data) => {
                            const val = this.props.soEditDetail
                            val.docDetailList = []
                            // this.props.updateState({
                            //     soEditDetail: val
                            // })
                            this.props.fetchSODetail({
                              customerId: this.props.soEditDetail.customerId,
                              salesOrderMasterId: this.props.soEditDetail.salesOrderMasterId,
                              soMasterId: this.props.soEditDetail.salesOrderMasterId,
                              relationshipId: this.props.soEditDetail.relationshipId,
                          })
                        }}
                      />
                    </>
                    : ""}
        <Divider style={{ margin: "10px 0px" }} />

        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <FormattedMessage
                  id="customer.salesOrder.form.internalRemarks.label"
                  defaultMessage=""
                />
              </Col>
              <Col span={24}>
                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"remarksInternal"}`}
                  data={this.props.remarksInternal}
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ remarksInternal: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter internal remarks",
                  }}
                />
              </Col>
            </Row>
            {/* <TextArea maxLength={500}
              className="description-textarea"
              rows={4}
              placeholder={intl.formatMessage(
                { id: 'customer.salesOrder.form.internalRemarks.placeholder', defaultMessage: '' }
              )}
              value={this.props.remarksInternal}
              onChange={(e) => { this.props.updateState({ remarksInternal: e.target.value }); }}
            /> */}

            <Col span={24}>
              <FormattedMessage
                id="customer.salesOrder.form.customerRemarks.label"
                defaultMessage=""
              />
            </Col>
            <Col span={24}>
              <CKEditor
                type="inline"
                className="description-textarea"
                key={`${"remarksCustomer"}`}
                data={this.props.remarksCustomer}
                onInit={(editor) => {
                  this.editor = editor;
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  this.props.updateState({ remarksCustomer: data });
                }}
                config={{
                  ...CUSTOM_CONFIGURATION,
                  placeholder: "Enter customer remarks",
                }}
              />
            </Col>

            {/* <TextArea maxLength={500}
              className="description-textarea"
              rows={4}
              placeholder={intl.formatMessage(
                { id: 'customer.salesOrder.form.customerRemarks.placeholder', defaultMessage: '' }
              )}
              value={this.props.remarksCustomer}
              onChange={(e) => { this.props.updateState({ remarksCustomer: e.target.value }); }}
            /> */}
          </Col>

          <Col span={7} offset={5}>
            <Row gutter={16}>
              <Col span={12}>
                <Row gutter={[16, 8]}>
                  <Col>
                    <Checkbox
                      checked={this.props.isRootDiscount}
                      onChange={() => {
                        this.props.updateState({
                          isRootDiscount: !this.props.isRootDiscount,
                        });
                      }}
                    >
                      <FormattedMessage
                        id="customer.salesOrder.form.totalDiscount.label"
                        defaultMessage=""
                      />
                    </Checkbox>
                  </Col>
                  {this.props.isRootDiscount ? (
                    <>
                      {this.state.rootDiscountList &&
                      this.state.rootDiscountList.length > 0
                        ? (this.state.rootDiscountList || []).map(
                            (rootDiscount, i) => {
                              return (
                                <>
                                  {/* <Col>
                                                                <span className="label" ><FormattedMessage id='supplier.purchaseOrder.form.totalDiscount.label' defaultMessage='' /></span>
                                                                <Tooltip placement="right" title={<FormattedMessage id='tooltip.rootDiscount' defaultMessage='' />} trigger="click">
                                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                                </Tooltip>
                                                            </Col> */}

                                  <Col span={24}>
                                    <span
                                      className="mark"
                                      style={{ left: "7px", width: "36px" }}
                                      title="If checked discount will be taxable."
                                    >
                                      <Checkbox
                                        // value={rootDiscount.isTaxable}
                                        checked={rootDiscount.isTaxable ?true:false}
                                        onClick={(e) => {
                                          rootDiscount.isTaxable = e.target
                                            .checked
                                            ? 1
                                            : 0;
                                          let rootDiscountList =
                                            this.state.rootDiscountList;
                                          rootDiscountList[i] = rootDiscount;
                                          this.setState({
                                            rootDiscountList: rootDiscountList,
                                          });
                                          if (this.state.subtotal) {
                                            this.calculateSubtotal();
                                          }
                                        }}
                                      ></Checkbox>
                                    </span>
                                    <Input
                                      style={{
                                        width: "100%",
                                        paddingLeft:"40px"
                                       
                                      }}
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        rootDiscount.rootDiscountPercent || ""
                                      }
                                      onChange={(e) => {
                                        rootDiscount.rootDiscountPercent =
                                          e.target.value;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      onBlur={(e) => {
                                        if (this.state.subtotal) {
                                          this.calculateSubtotal();
                                        }
                                      }}
                                    />
                                    {/* <span className="mark" style={{position:'absolute', right:'0px', left:'auto'}}>%</span> */}
                                    <div
                                      className="right1"
                                      style={{ right: "8px", top: "4px" }}
                                    >
                                      %
                                    </div>
                                  </Col>

                                  {/* { rootDiscount.rootDiscountAmount ? <span>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{(Number(rootDiscount.rootDiscountAmount) + Number(rootDiscount.taxAmount||0))|| ""}</span>: ''}  */}

                                  {/* <div className="input-label-control" style={{width: '170px'}}>
                                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                        <Input
                                                                            disabled
                                                                            placeholder="0.00"
                                                                            type='number'
                                                                            value={rootDiscount.rootDiscountAmount || ""}
                                                                            onChange={(e) => {
                                                                                rootDiscount.rootDiscountAmount= Number(e.target.value);
                                                                                let rootDiscountList = this.state.rootDiscountList;
                                                                                rootDiscountList[i] = rootDiscount;
                                                                                this.setState({
                                                                                    rootDiscountList: rootDiscountList
                                                                                })
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div> */}

                                  {/* <Col span={3}>
                                                                <div className="input-group" style={{padding: '5px', width:'100%'}}>
                                                                    <Dropdown
                                                                        items={this.props.taxes}
                                                                        value={rootDiscount.taxName}
                                                                        placeholder={this.props.intl.formatMessage({ id: 'customer.salesQuote.form.gridHeader.tax' })}
                                                                        onSelect={(selectedOption, option) => {
                                                                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                                                                            rootDiscount.taxPercent = selectedTax.taxPercent;
                                                                            rootDiscount.taxName = selectedTax.taxName;
                                                                            rootDiscount.taxId= option.key;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            });
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }}

                                                                        valueKeyName='taxNameDisplay'
                                                                        optionKeyName='taxSingleRateId'
                                                                        allowClear={true}
                                                                        onClear={() => {
                                                                            rootDiscount.tax = null;
                                                                            rootDiscount.taxName = null;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col> */}
                                  <Col span={24}>
                                    <div
                                      className="price-box"
                                      style={{
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        padding: "0px",
                                        display:"flex",
                                        alignItems:"center"
                                      }}
                                    >
                                      <div className="icons1">
                                      {(this.props.companyInfo || {})
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                (this.props.companyInfo || {})
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            (this.props.companyInfo || {})
                                              .currencyCode + " "
                                          )}
                                      </div>
                                      {rootDiscount.rootDiscountAmount ? (
                                        <span style={{paddingLeft:'50px' ,}}>
                                         
                                          {Number(
                                            rootDiscount.rootDiscountAmount
                                          ) +
                                            Number(
                                              rootDiscount.taxAmount || 0
                                            ) || ""}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Col>
                                  <Col span={24}>
                                    <Dropdown
                                      items={this.props.allLedgerAccounts}
                                      value={rootDiscount.ledgerAccountName}
                                      placeholder={this.props.intl.formatMessage(
                                        {
                                          id: "selectLedger.text",
                                          defaultMessage: "",
                                        }
                                      )}
                                      onSelect={(selectedOption, option) => {
                                        rootDiscount.chartOfAccountDetailsId =
                                          selectedOption;
                                        rootDiscount.ledgerAccountName =
                                          option.name;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      valueKeyName="ledgerAccountName"
                                      optionKeyName="chartOfAccountDetailsId"
                                      addressLineKey1="accountingEntry"
                                      allowClear={true}
                                      onClear={() => {
                                        rootDiscount.chartOfAccountDetailsId =
                                          null;
                                        rootDiscount.ledgerAccountName = null;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      canAddNew={true}
                                      onAddNewClick={(props) => {
                                        this.newLedgerAccounts(this.props);
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <i
                                      className="fa fa-trash fa-lg cursor-pointer"
                                      onClick={() => {
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        if (
                                          rootDiscountList &&
                                          rootDiscountList.length > 1
                                        ) {
                                          rootDiscountList.splice(i, 1);
                                        } else {
                                          rootDiscountList = [];
                                          this.setState({
                                            rootDiscountList: [],

                                          });
                                          this.props.updateState({
                                            isRootDiscount:false
                                          })
                                        }
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      
                                        if (this.state.subtotal) {
                                          setTimeout(()=>{
                                            this.calculateSubtotal();
                                          },500)
                                        }
                                      }}
                                      type="delete"
                                    />
                                  </Col>
                                </>
                              );
                            }
                          )
                        : ""}
                      {this.state.rootDiscountList.length < 5 && (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            marginTop: "-21px",
                          }}
                          className="mb20, mr10"
                        >
                          <span
                            className="cursor-pointer pr10"
                            onClick={() => {
                              let discountList = this.state.rootDiscountList;
                              discountList.push({});
                              this.setState({ rootDiscountList: discountList });
                            
                            }}
                          >
                            <FormattedMessage id="add" />{" "}
                            {/* <FormattedMessage id="modal.txDetails.common.discount" /> */}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>

              <Col span={12}>
                <Row gutter={[16, 8]}>
                  <Col >
                    <Checkbox
                      checked={this.props.isRootExpense}
                      onChange={() => {
                        this.props.updateState({
                          isRootExpense: !this.props.isRootExpense,
                        });
                      }}
                    >
                      <FormattedMessage
                        id="customer.salesInvoice.form.checkbox.expense"
                        defaultMessage=""
                      />
                    </Checkbox>
                  </Col>
                  {this.props.isRootExpense ? (
                    <>
                      {this.state.rootExpenseList &&
                      this.state.rootExpenseList.length > 0
                        ? (this.state.rootExpenseList || []).map(
                            (rootExpense, i) => {
                              return (
                                <>
                                  {/*                                                     
                                                        <Col span={7} className="pt5">
                                                            <FormattedMessage id='supplier.purchaseOrder.form.totalExpense.label' defaultMessage='' />
                                                        </Col> */}

                                  {/* <Col span={16}>
                                                             <Dropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.expenseTypes}
                                                    valueKeyName='expenseType'
                                                    value={this.props.expenseId}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            expenseId: undefined,
                                                            expensName: undefined
                                                        })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                    }}
                                                    onSelect={(expenseId) => {
                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                        this.props.updateState({
                                                            expenseId,
                                                            expensName: selectedExpense.expenseType

                                                        })
                                                    }}
                                                    optionKeyName='lkExpenseTypeId'
                                                /> 
                                                </Col>*/}

                                  <Col span={24}>
                                    <div className="input-group">
                                      <div
                                        className="input-label-control"
                                        style={{
                                          width: "100%",
                                          position: "relative",
                                        }}
                                      >
                                        <span
                                          className="mark"
                                          style={{ height: "32px" }}
                                        >
                                          {(this.props.companyInfo || {})
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                (this.props.companyInfo || {})
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            (this.props.companyInfo || {})
                                              .currencyCode + " "
                                          )}
                                        </span>
                                        <Input
                                          style={{ height: "32px" }}
                                          placeholder="0.00"
                                          value={rootExpense.expenseAmount}
                                          type="number"
                                          onChange={(e) => {
                                            rootExpense.expenseAmount = Number(
                                              e.target.value
                                            );

                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            rootExpenseList[i] = rootExpense;
                                            this.setState(
                                              {
                                                rootExpenseList:
                                                  rootExpenseList,
                                              },
                                              () => {
                                                if (this.state.subtotal) {
                                                  this.calculateSubtotal();
                                                }
                                              }
                                            );

                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });

                                            // this.setState({
                                            //     expenseAmount: Number(e.target.value)
                                            // },
                                          }}
                                        />
                                      </div>

                                      {/* <span>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <span>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </span>
                                                                : ''
                                                            }
                                                        </span> */}
                                    </div>
                                  </Col>

                                  <Col span={24}>
                                    {" "}
                                    <div
                                      className="price-box1"
                                      style={{ margin: "0px", padding: "0px" ,display:"flex",alignItems:"center"}}
                                    >
                                      <div className="icons2">Tax Amt.</div>
                                      <div 
                                      style={{paddingLeft:'70px'}}
                                      >
                                        {rootExpense.selectedExpenseLedger &&
                                        rootExpense.selectedExpenseLedger
                                          .isLedgerTaxable &&
                                        rootExpense.expenseLedgerAccountTaxRate &&
                                        rootExpense.expenseAmount ? (
                                          <span>
                                            {Number(
                                              (rootExpense.expenseAmount *
                                                (rootExpense.expenseLedgerAccountTaxRate ||
                                                  0)) /
                                                100
                                            ).toFixed(2)}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>{" "}
                                  </Col>
                                  <Col span={24}>
                                    <Dropdown
                                      items={this.props.allLedgerAccounts}
                                      value={
                                        rootExpense.ledgerAccountName
                                      }
                                      placeholder={this.props.intl.formatMessage(
                                        {
                                          id: "selectLedger.text",
                                          defaultMessage: "",
                                        }
                                      )}
                                      onSelect={(selectedOption, option) => {
                                        const selectedExpenseLedger = find(
                                          this.props.allLedgerAccounts,
                                          {
                                            chartOfAccountDetailsId:
                                              Number(selectedOption),
                                          }
                                        );
                                        // this.setState({
                                        //     expenseLedgerAccountId: selectedOption,
                                        //     expenseLedgerAccountName: option.name,
                                        //     expenseLedgerAccountTaxRate: selectedExpenseLedger.taxSingleRatePercent || 0,
                                        //     selectedExpenseLedger
                                        // });
                                        rootExpense.chartOfAccountDetailsId =
                                          selectedOption;
                                        rootExpense.ledgerAccountName =
                                          option.name;
                                        rootExpense.expenseLedgerAccountTaxRate =
                                          selectedExpenseLedger.taxSingleRatePercent ||
                                          0;
                                        rootExpense.selectedExpenseLedger =
                                          selectedExpenseLedger;

                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        rootExpenseList[i] = rootExpense;
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                                                
                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      valueKeyName="ledgerAccountName"
                                      optionKeyName="chartOfAccountDetailsId"
                                      addressLineKey1="accountingEntry"
                                      allowClear={true}
                                      onClear={() => {
                                        // this.setState({
                                        //     expenseLedgerAccountId: null,
                                        //     expenseLedgerAccountName: null,
                                        //     selectedExpenseLedger: null,
                                        //     expenseLedgerAccountTaxRate: null,
                                        // });
                                        rootExpense.chartOfAccountDetailsId =
                                          null;
                                        rootExpense.ledgerAccountName = null;
                                        rootExpense.expenseLedgerAccountTaxRate =
                                          null;
                                        rootExpense.selectedExpenseLedger =
                                          null;

                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        rootExpenseList[i] = rootExpense;
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      canAddNew={true}
                                      onAddNewClick={(props) => {
                                        this.newLedgerAccounts(this.props);
                                      }}
                                    />
                                  </Col>
                                  <div>

                                  <Col span={24}>
                                    <i
                                      className="fa fa-trash fa-lg cursor-pointer"
                                      onClick={() => {
                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        if (
                                          rootExpenseList &&
                                          rootExpenseList.length > 1
                                        ) {
                                          rootExpenseList.splice(i, 1);
                                        } else {
                                          rootExpenseList = [{}];
                                        }
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });

                                        setTimeout(()=>{

                                          if (this.state.subtotal) {
                                            this.calculateSubtotal();
                                          }

                                        },500)

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      type="delete"
                                    />
                                  </Col>
                                  </div>

                                  {/* <Row type="flex" justify="end" className="mb20">
                                                        <Col span={8}>
                                                            <span className="label"><FormattedMessage id='supplier.purchaseOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={8}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    </span>
                                                                    <Input
                                                                        placeholder="0.00"
                                                                        value={rootExpense.expenseAmount}
                                                                        type='number'
                                                                        onChange={(e) => {
                                                                            rootExpense.expenseAmount = Number(e.target.value);
        
                                                                            let rootExpenseList = this.state.rootExpenseList;
                                                                            rootExpenseList[i] = rootExpense;
                                                                            this.setState({
                                                                                rootExpenseList: rootExpenseList
                                                                            },() => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            });
                                                                            
                                                                            // this.setState({
                                                                            //     expenseAmount: Number(e.target.value)
                                                                            // },
                                                                             
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col span={8}>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <div>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </div>
                                                                : ''
                                                            }
                                                        </Col>
                                                    </Row> */}
                                </>
                              );
                            }
                          )
                        : ""}
                      {this.props.rootExpenseList?.length < 5 && (
                        <div
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: "-21px",
                        }}
                        onClick={() => {
                          let ExpenseList = this.state.rootExpenseList;
                          ExpenseList.push({});
                          this.setState({ rootExpenseList: ExpenseList });

                          this.props.updateState({
                            rootExpenseList: ExpenseList,
                          });
                        }}
                          className=" mb20, mr10 cursor-pointer"
                        >
                          <span
                         
                            className="cursor-pointer pr10"
                           
                          >
                            <FormattedMessage id="add" />{" "}
                            {/* <FormattedMessage id="supplier.purchaseOrder.form.totalExpense.label" /> */}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>

            {/* {
                false && <Checkbox onChange={() => {
                  this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                  defaultMessage='' />
                </Checkbox>
              } */}

            {/* <Row type="flex" justify="end">
                {
                  this.props.isRootDiscount
                    ?
                    <Fragment>
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.totalDiscount.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input
                              type='number'
                              placeholder="0.00"
                              value={this.state.rootDiscountPercent || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountPercent: e.target.value
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }
                              }
                            />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input
                              disabled
                              placeholder="0.00"
                              type='number'
                              value={this.state.rootDiscountAmount || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountAmount: Number(e.target.value)
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Fragment>
                    : ''
                }
              </Row>

              {
                sectionVisible.tax
                  ? <Fragment>
                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.taxName.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          items={this.props.taxes}
                          value={this.props.taxName}
                          onSelect={(selectedTax) => {
                            this.props.updateState({
                              taxName: selectedTax
                            })
                          }}
                          valueKeyName='description'
                          optionKeyName='taxSingleRateId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              taxName: undefined
                            });
                            this.calculateSubtotal();
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.tax.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input placeholder="0.00" />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input placeholder="0.00" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              }

              {
                this.props.isRootExpense
                  ? <Fragment>
                    <Row type="flex" className='amount-summary' justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.totalExpense.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          style={{ width: '100%' }}
                          items={this.props.expenseTypes}
                          valueKeyName='expenseType'
                          value={this.props.expenseId}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              textBoxValue: payload.textEntered,
                              submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                          }}
                          onSelect={(expenseId) => {
                            const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                            this.props.updateState({
                              expenseId,
                              expensName: selectedExpense.expenseType

                            })
                          }}
                          optionKeyName='lkExpenseTypeId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              expenseType: undefined,
                              expenseId: undefined
                            })
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end" className="mb20">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input
                              placeholder="0.00"
                              type='number'
                              value={this.state.expenseAmount}
                              onChange={(e) => {
                                this.setState({
                                  expenseAmount: Number(e.target.value)
                                }, () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              } */}

            <div className="product-amount-values">
              <Row>
                <Col span={15}>
                  <FormattedMessage
                    id="customer.salesOrder.form.subTotal.label"
                    defaultMessage=""
                  />
                </Col>
                <Col span={9}>
                  {(this.props.companyInfo || {}).currencyIcon ? (
                    <i
                      className={(this.props.companyInfo || {}).currencyIcon}
                    ></i>
                  ) : (
                    (this.props.companyInfo || {}).currencyCode + " "
                  )}
                  {fixedDecimalAndFormateNumber(
                    this.state.subtotalWithoutDiscount
                  )}
                </Col>
              </Row>
            

              {this.state.totalDiscount === "0.00" ||
              this.state.totalDiscount === 0 ? null : (
                <Row>
                  <Col span={15}>
                    <FormattedMessage
                      id="customer.salesOrder.form.totalDiscount.label"
                      defaultMessage=""
                    />
                  </Col>
                  <Col span={9}>
                    -
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                  </Col>
                </Row>
              )}

              {/* 
                  {
                    false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                        <div className="w-50">
                            <span className="title">Tax</span>
                        </div>
                        <div className="w-50-default">
                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                            {this.state.totalTax}
                        </div>
                    </div>
                  } 
              */}

              {itemLevelTaxes.length === 0
                ? null
                : itemLevelTaxes.map((taxObj, i) => {
                    if (isPlaceOfSupplySameAsRelationship) {
                    }
                    return (this.props.companyInfo || {}).countryName ===
                      "India" ? (
                      isPlaceOfSupplySameAsRelationship ? (
                        <div key={i}>
                          <Row>
                            <Col span={15}>
                              CGST @ {taxObj.taxPercent / 2}% on{" "}
                              {taxObj.amountAfterCoupan >0 ? <span>
                                {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                </span> :<span>
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>}
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                              {( Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                            </Col>
                          </Row>

                          <Row>
                            <Col span={15}>
                              <span className="title">
                                SGST @ { taxObj.taxPercent / 2}% on{" "}
                                {taxObj.amountAfterCoupan >0 ? <span>
                                {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                </span> :<span>
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                              {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div key={i}>
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                IGST @ {taxObj.taxPercent}% on{" "}
                                {taxObj.amountAfterCoupan >0 ? <span>
                                {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                </span> :<span>
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {Number(taxObj.taxAmount || 0).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      )
                    ) : (
                      <div key={i}>
                        <Row>
                          <Col span={15}>
                            <span className="title">
                              {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                             {taxObj.amountAfterCoupan >0 ? <span>
                                {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                </span> :<span>
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>}
                            </span>
                          </Col>
                          <Col span={9}>
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                            {Number(taxObj.taxAmount || 0).toFixed(2)}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}

            {this.props.couponList && this.props.couponList[this.props.pageNumber || 1]?.length? <Row>
                <Col span={10}>
                COUPON
                </Col>
             { this.props.viewCoupon ?   <Col style={{position:"relative"}} span={14}>
                
                <TextBox
                value={this.props?.coupanValue}
                onChange={(e)=>{
                  this.props.updateState({
                    coupanValue:e.target.value
                  })
                }}
                style={{paddingRight:"20px"}}
                
                />
                <div  onClick={()=>{
                  if(this.props.customerName){
                    this.props.applieCoupon({
                      relationshipId:this.props.companyInfo?.relationshipId,
                      customerId:this.props.customer?.customerId,
                      couponCode:this.props.coupanValue
                    },(data)=>{
                      if(data?.isValid){
                       let minVaidation =data?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? data?.coupon?.minPurchaseAmount <= this.state.subtotal : data?.coupon?.minPurchaseAmount <= this.state.total: true 
                       if(minVaidation){
                        showToasterMessage({
                          description: 'Applied successfully.',
                          messageType: 'success'
                        })
                         this.props.updateState({
                           viewCoupon:false,
                           coupondata:data,
                         })
                         this.setState({
                           viewCoupon:false,
                           coupondata:data,
                         })
 
                       this.calculateSubtotal();
                       }else{
                        showToasterMessage({
                          messageType: "warning",
                          description: "Add More Item To apply Coupon",
                        }); 
                       }

                      }else{
                        showToasterMessage({
                          messageType: "warning",
                          description: "Invalid Coupon",
                        });    
                      }
                    })
                  }else{
                    showToasterMessage({
                      messageType: "warning",
                      description: "Please Select Customer",
                    });
                  }
                    
                }} style={{position:"absolute",right:"4px",top:"10px"}}className="cursor-pointer">
                  Apply
                </div>
                </Col>:<Col span={14}  onClick={()=>{
                  this.props.updateState({
                    viewCoupon:true
                  })
                }}className="cursor-pointer">
                  {this.props.coupondata?
                  <span style={{display:"flex"}}>
                    {`${this.props.coupanValue ? `(${this.props.coupanValue})` : ''}  -${this.state?.totalCouponDiscount || 0} ` + " "}
                    <span className="" onClick={()=>{
                       this.props.updateState({
                        coupondata:null,
                        viewCoupon:true,
                        couponAmount:0,
                        totalCouponDiscount:0
                      })
                      this.setState({
                        coupondata:null,
                        viewCoupon:true,
                        couponAmount:0,
                        totalCouponDiscount:0,
                        // total:this.state.total + preCoupanAmt,
                      })
                      this.calculateSubtotal(true);

                    }}>Remove</span>
                  </span>
                  :"Apply"}
                </Col>}
              </Row>:""}
              <Row>
                <Col span={15}>
                  <span className="title">
                    <FormattedMessage
                      id="customer.salesOrder.form.totalAmount.label"
                      defaultMessage=""
                    />
                  </span>
                </Col>
                <Col span={9}>
                  {(this.props.companyInfo || {}).currencyIcon ? (
                    <i
                      className={(this.props.companyInfo || {}).currencyIcon}
                    ></i>
                  ) : (
                    (this.props.companyInfo || {}).currencyCode + " "
                  )}
                  {fixedDecimalAndFormateNumber(this.state.total)}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <CKEditor
          type="inline"
          className="add-footer-textarea"
          key={"footer"}
          data={this.props.footer}
          onInit={(editor) => {
            this.editor = editor;
          }}
          onChange={(event) => {
            const data = event.editor.getData();
            this.props.updateState({ footer: data });
          }}
          config={{
            ...CUSTOM_CONFIGURATION_FOOTER,
            placeholder: "Add Footer Here",
          }}
        />
         <div className="pb40"></div>
        {/* <div className="txn-footer-container">
          {
            !this.props.footer ? 
              <Button className="add-footer" onClick={()=>{
                const data = {
                  title: 'Add Footer',
                  modalType: MODAL_TYPE.FOOTER,
                  hideFooter: true,
                  handleSubmit: () => {
                    this.props.hideModal();
                  }
                };
                this.props.showModal(data)
              }}>
                <PlusCircleOutlined /> Add Footer
              </Button>
            : 
            <div>{this.props.footer}</div>
          }
        </div> */}

        <div className="footer">
          <div className="left-fItems">
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.disconnect.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </div>

          <div className="right-fItems">
            {!this.props.update && (
              <Button
                className="grey-button"
                loading={this.props.saveLoading}
                disabled={this.props.saveLoading}
                onClick={() => {
                  saveSalesOrder(this.props, this.state, this.gridApi, true);
                }}
              >
                <FormattedMessage id="button.draft.label" defaultMessage="" />
              </Button>
            )}

            <Button
              className="ant-btn-primary"
              loading={this.props.saveLoading}
              disabled={this.props.saveLoading}
              onClick={() => {
                saveSalesOrder(this.props, this.state, this.gridApi);
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
        </Skeleton>
      </Fragment>
    );
  }
}

export default injectIntl(ProductDetails);
