import React, { useEffect, useState } from "react"
import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, Select, Upload, message } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getAWSFileURL } from "../../../../../utils";
import config from "../../../../../config/environmentConfig"
import { BUCKET_NAME } from "../../../../../static/constants";

const { Option } = Select;

const { Dragger } = Upload;

function ImageUpload(props) {
    const { productDetails } = props;
    console.log(productDetails,"productDetails:::::::::")
    const variantList = productDetails.inventoryItemVariantList?.find((obj) => obj.variantId === props.variantId)
    const imageVal = variantList?.inventoryItemVariantImageList?.map((val) => {
        return getAWSFileURL(val.image, props.companyInfo.bucketName + "/image")
    })
    const handleFileInputChange = (fileList) => {
        const files = Array.isArray(fileList) ? fileList : [fileList];

        props.updateState({
            files: files,
        });

        files.forEach((file) => {
            if (file) {
                const reader = new FileReader();
                reader.onloadend = handleFileRead;
                // reader.readAsDataURL(file);
            }
        });
    };

    const handleFileRead = (event) => {
        const fileContent = event.target.result;
    }

    const handleSelect = (value, obj) => {
        const selectedVariantId = Number(obj.key);

        //   const imageList = (variantList?.inventoryItemVariantImageList || [])?.map((val) => {

        //         props.imagePreview({
        //             relationshipId: props.companyInfo.relationshipId,
        //             bucketName: props.companyInfo.bucketName,
        //             objectKey: val.image,
        //             folderName: BUCKET_NAME.VARIANT_IMAGE,
        //         });
        //     });

        props.updateState({
            selectedVariant: value,
            variantId: selectedVariantId,
        });
    };

    const propss = {
        name: 'file',
        multiple: true,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',

        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                handleFileInputChange(info.fileList);

            }
            if (status === 'done') {
                handleFileInputChange(info.fileList);

            } else if (status === 'error') {
                handleFileInputChange(info.fileList);

            }
        },
        onDrop(e) {
            handleFileInputChange(e.dataTransfer.files)

        },
    };

    return <>
        <div className="image-container">
            {productDetails.hasVariant === 1 ?
                <>
                    <div className="drawer-label">
                        <div className="pb10">
                            <FormattedMessage id="uploadImage.variant.label" defaultMessage="" />
                        </div>
                        <Select
                            value={props.selectedVariant}
                            onChange={handleSelect}
                            style={{ width: '100%' }}
                            placeholder={props.intl.formatMessage({
                                id: "common.select.placeholder",
                                defaultMessage: "",
                            })}
                        >
                            {(productDetails.inventoryItemVariantList || []).map((item) => (
                                <Option
                                    key={item.variantId}
                                    value={item.sku}
                                >
                                    {`${item.attributeValue1 || ''} ${item.attributeValue2 || ''} ${item.attributeValue3 || ''}`}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        {imageVal && imageVal.length > 0 ? (
                            imageVal.map((imageUrl, index) => (
                                <img key={index} src={imageUrl} alt="" height={50} width={50} />
                            ))
                        ) : (
                            ''
                        )}

                    </div>
                </>
                :
                ""}

            <div className="media-content">

                <Dragger {...propss}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                    </p>
                </Dragger>

            </div>
        </div>
        <div className="image-btn">
            <Button
                onClick={() => {
                    props.updateState({ submitted: true });
                    const payload = {
                        relationshipId: props.companyInfo.relationshipId,
                        variantId: props.variantId,
                        bucketName: props.companyInfo.bucketName,
                        files: props.files
                    }
                    const modalData = {
                        modalBody: <FormattedMessage id="modal.commmon.submit" defaultMessage="" />,
                        handleSubmit: () => {
                            props.uploadVariantImage(payload, props);
                            props.popModalFromStack();
                        },
                    };
                    props.pushModalToStack(modalData);

                }}
            >
                <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>

        </div>

    </>
}
export default injectIntl(ImageUpload);