import React from "react";
import { UploadOutlined, CloseOutlined, PlusOutlined, FileTextOutlined, } from "@ant-design/icons";
import { Button, Upload, Row, Col, Switch, DatePicker, Tabs, Checkbox, Divider, Tooltip, } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import { MODAL_TYPE, ICONS } from "../../../../../static/constants";
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from "../../../../general/TextBox";
import { TextArea } from "../../../../general/TextAreaComponent";
import { getLabelName } from "../../customerInfo";
import { getCurrentDateForBackend, getMomentDateForUI, getUserDefinedName, } from "../../../../../utils";
import * as find from "lodash.find";
import { CUSTOM_CONFIGURATION_FORM } from "./../../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import moment from "moment-timezone";
import { ErrorMsg } from "../../../../general/ErrorMessage";

const { Dragger } = Upload;
const { TabPane } = Tabs;


function CreateAssignmentComp(props) {
  const { companyInfo, classData = {} } = props;
  console.log(props,"asssignmentttttttttt")
  const removeQuestion = (props, index) => {
    const modalData = {
      modalBody: (
        <FormattedMessage
          id="deleteItem.confirmation"
          values={{ value: "question" }}
        />
      ),
      handleSubmit: () => {
        let questionList = props.questionList;
        questionList.splice(index, 1);
        props.updateState({ questionList });
      },
    };
    props.pushModalToStack(modalData);
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.ASSIGNMENTS_GROUP: {
        addFunc = props.addAssignmentGroup;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({
          text: text,
          relationshipId: companyInfo.relationshipId,
          props,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.ASSIGNMENTS_GROUP: {
        title = <FormattedMessage id="assignment.group" defaultMessage="" />;
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.ASSIGNMENTS_GROUP: {
        deleteFunc = props.deleteAssignmentGroup;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          props,
        });
      },
    };
    showModal(modalData);
  };

  const handleUpload = (file) => {
    const newFile = {
      uid: file.uid,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
    };

    const updatedDocDetailList = [
      ...props.docDetailList,
      newFile
    ].filter((item, index, self) =>
      index === self.findIndex((t) => t.fileName === item.fileName)
    );

    props.updateState({ docDetailList: updatedDocDetailList });
    return false;
  };


  const handleRemove = (file) => {
    const id = file.docDetailsId || file.uid;
    const updatedList = props.docDetailList.filter(item => item.docDetailsId !== id && item.uid !== id);
    props.updateState({ docDetailList: updatedList });
  };


  const onSubmitAssignment = () => {
    if (
      (!props.classData || !props.classData.classId) ||
      !props.assignmentName ||
      !props.dueDate ||
      // !props.assignmentGroupId ||
      !props.assignmentGrade ||
      !props.numberOfAttempts ||
      !props.scheduling ||
      (props.numberOfAttempts === "Multiple" && !props.maxAttempts) ||
      (props.scheduling === "Schedule for Later" && !props.startDate) ||
      (props.assignmentGrade === "Points" && !props.assignmentPoints)
    ) {
      props.updateState({ submittedAssign: true });
      return
    }

    const modalData = {
      modalBody: "Are you sure you want to submit?",
      handleSubmit: () => {
        let payload = {
          assignmentId: props.assignmentId || 0,
          relationshipId: companyInfo.relationshipId,
          assignmentName: props.assignmentName,
          assignmentDescription: props.assignmentDescription,
          dueDate: getCurrentDateForBackend(
            props.dueDate || new Date()
          ),
          startDate: props.startDate
            ? getCurrentDateForBackend(props.startDate || new Date())
            : null,
          //  endDate: props.endDate ? getCurrentDateForBackend(props.endDate || new Date()) : null,
          classId: classData.classId,
          className: classData.className,

          classSectionId: props.classSectionId,
          sectionName: props.sectionName,
          subjectId: props.subjectId,

          assignmentGroupId: props.assignmentGroupId,
          numberOfAttempts: props.numberOfAttempts,
          maxAttempts:
            props.numberOfAttempts === "Single"
              ? 1
              : props.maxAttempts || 1,
          // scoreType: props.assignmentGroupId,
          gradingSystem: props.assignmentGrade,
          assignmentGradePoint:
            props.assignmentGrade === "Points"
              ? props.assignmentPoints
              : 0,
          questionList: JSON.stringify(props.questionList || "[]"),
          isAttachementRequiredInResponse:
            props.isAttachementRequiredInResponse,
          docDetailList: props.docDetailList || [],
          scheduling: props.scheduling,
          assignmentResponseList: props.assignmentResponseList,
        };
        props.createAssignment(payload, props);
        // props.popModalFromStack();
      },
    };
    props.pushModalToStack(modalData);

  }
  return (
    <div>

      <Tabs type="line" style={{ padding: '0px 20px' }}>
        <TabPane tab="Form" key="1" style={{ height: `calc(100vh - 192px)`, overflowY: `auto` }}>
          <Row className="mt10">
            <Col span={11}>
              <Row gutter={[16, 16]}>

                <Col span={12}>
                  <div className="i-label">{getUserDefinedName('schoolManagement.class.name', props)}<span className="required">*</span></div>
                  <Dropdown
                    items={props.classList[1] || []}
                    valueKeyName='className'
                    optionKeyName='classId'
                    valueKeyName2='courseName'
                    value={props.classId}
                    placeholder={getUserDefinedName('schoolManagement.class.name', props)}
                    onSelect={(optionValue, option) => {
                      let selectedClass = find((props.classList[1] || []), { classId: option.value }) || {};
                      props.updateState({
                        className: selectedClass.className,
                        classId: selectedClass.classId,
                        classData: selectedClass,
                        classSectionId: '',
                        sectionName: '',
                        subjectName: ''
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.classId;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="i-label">{getUserDefinedName('schoolManagement.section.name', props)}<span className="required">*</span></div>
                  <Dropdown
                    items={(classData || {}).classSectionList}
                    valueKeyName='sectionName'
                    optionKeyName='classSectionId'
                    value={props.sectionName}
                    placeholder={getUserDefinedName('schoolManagement.section.name', props)}
                    onSelect={(optionValue, option) => {
                      props.updateState({
                        classSectionId: optionValue,
                        sectionName: option.name
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.classId;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="i-label">{getUserDefinedName('schoolManagement.subject.name', props)}</div>
                  <Dropdown
                    items={(classData || {}).subjectList}
                    valueKeyName='subjectName'
                    optionKeyName='subjectId'
                    value={props.subjectName}
                    placeholder={getUserDefinedName('schoolManagement.subject.name', props)}
                    onSelect={(optionValue, option) => {
                      props.updateState({
                        subjectId: optionValue,
                        subjectName: option.name
                      });
                    }}
                  />

                </Col>

                <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage id="assignment.name" /><span className="required">*</span>
                  </div>
                  <TextBox
                    type="text"
                    value={props.assignmentName}
                    placeholder={'Assignment Name'}
                    maxLength={40}
                    onChange={(e) => {
                      props.updateState({
                        assignmentName: e.target.value,
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.assignmentName;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage id="assignment.group" />
                  </div>
                  <Dropdown
                    items={props.assignmentGroupList || []}
                    valueKeyName="groupName"
                    optionKeyName="assignmentGroupId"
                    value={props.assignmentGroupId}
                    placeholder="Select"
                    onSelect={(optionValue, option) => {
                      let selectedGroup =
                        find(props.assignmentGroupList || [], {
                          assignmentGroupId: option.value,
                        }) || {};
                      props.updateState({
                        assignmentGroup: selectedGroup.groupName,
                        assignmentGroupId: selectedGroup.assignmentGroupId,
                      });
                    }}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(
                        props,
                        MODAL_TYPE.ASSIGNMENTS_GROUP,
                        payload
                      );
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        groupName: payload.textEntered,
                        submittedOnce: false,
                      };
                      handleNewItemAddition(
                        props,
                        payload,
                        MODAL_TYPE.ASSIGNMENTS_GROUP
                      );
                    }}
                  />
                  {/* <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.assignmentGroupId;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  /> */}
                </Col>

                <Col span={12}>
                  <div className="i-label">
                    Grading System<span className="required">*</span>
                  </div>
                  <Dropdown
                    items={[
                      "Pass/Fail",
                      "Percentage",
                      "Points",
                      "Letter Grade(A,B,C,D)",
                      "Ungraded",
                    ]}
                    value={props.assignmentGrade}
                    placeholder="Select"
                    onSelect={(optionValue) => {
                      props.updateState({
                        assignmentGrade: optionValue,
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.assignmentGrade;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                {props.assignmentGrade && props.assignmentGrade === "Points" ? (
                  <Col span={12}>
                    <div className="i-label">
                      <FormattedMessage id="assignment.points" /><span className="required">*</span>
                    </div>
                    <TextBox
                      value={props.assignmentPoints}
                      maxLength={20}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          re.test(e.target.value)
                        ) {
                          props.updateState({
                            assignmentPoints: e.target.value,
                          });
                        } else if (!props.assignmentPoints) {
                          props.updateState({ assignmentPoints: "" });
                        }
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return !props.submittedAssign || props.assignmentPoints;
                      }}
                      message={props.intl.formatMessage({
                        id: "requiredField.missing.msg",
                        defaultMessage: "",
                      })}
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col span={12}>
                  <div className="i-label">
                    Type of Attempts<span className="required">*</span>
                  </div>
                  <Dropdown
                    items={["Single", "Multiple"]}
                    value={props.numberOfAttempts}
                    placeholder="Select"
                    onSelect={(optionValue) => {
                      props.updateState({
                        numberOfAttempts: optionValue,
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.numberOfAttempts;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>
                {props.numberOfAttempts && props.numberOfAttempts === "Multiple" ? (
                  <Col span={12}>
                    <div className="i-label">
                      <FormattedMessage id="assignment.maxAttemp" /><span className="required">*</span>
                    </div>
                    <TextBox
                      type="text"
                      value={props.maxAttempts}
                      maxLength={20}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          re.test(e.target.value)
                        ) {
                          props.updateState({
                            maxAttempts: e.target.value,
                          });
                        } else if (!props.maxAttempts) {
                          props.updateState({ maxAttempts: "" });
                        }
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return !props.submittedAssign || props.maxAttempts;
                      }}
                      message={props.intl.formatMessage({
                        id: "requiredField.missing.msg",
                        defaultMessage: "",
                      })}
                    />
                  </Col>
                ) : (
                  ""
                )}
                <>
                  <Col span={12}>
                    <div className="i-label">
                      Scheduling<span className="required">*</span>
                    </div>
                    <Dropdown
                      items={["Start Assignment Now", "Schedule for Later"]}
                      value={props.scheduling}
                      placeholder="Select"
                      onSelect={(optionValue) => {
                        props.updateState({
                          scheduling: optionValue,
                        });
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return !props.submittedAssign || props.scheduling;
                      }}
                      message={props.intl.formatMessage({
                        id: "requiredField.missing.msg",
                        defaultMessage: "",
                      })}
                    />
                  </Col>
                </>
                {props.scheduling && props.scheduling === "Schedule for Later" ? (
                  <Col span={12}>
                    <div className="i-label">
                      Start Date<span className="required">*</span>
                    </div>
                    <DatePicker
                      value={
                        props.startDate
                          ? getMomentDateForUI({
                            date: props.startDate,
                            format: "YYYY-MM-DD",
                          })
                          : null
                      }
                      onChange={(selectedDate) => {
                        props.updateState({ startDate: selectedDate });
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return !props.submittedAssign || props.selectedDate;
                      }}
                      message={props.intl.formatMessage({
                        id: "requiredField.missing.msg",
                        defaultMessage: "",
                      })}
                    />
                  </Col>
                ) : (
                  ""
                )}

                <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage id="common.dueDate" /><span className="required">*</span>
                  </div>
                  <DatePicker
                    value={
                      props.dueDate
                        ? getMomentDateForUI({
                          date: props.dueDate,
                          format: "YYYY-MM-DD",
                        })
                        : null
                    }
                    disabledDate={(current) => {
                      return current < moment().endOf("day");
                    }}
                    onChange={(selectedDate) => {
                      props.updateState({ dueDate: selectedDate });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedAssign || props.dueDate;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col span={12}>
                  <div className="i-label">
                    Attachment Required in Response
                  </div>
                  <Checkbox
                    checked={props.isAttachementRequiredInResponse}
                    onChange={(e) => {
                      props.updateState({
                        isAttachementRequiredInResponse: e.target.checked
                          ? 1
                          : 0,
                      });
                    }}
                  />
                </Col>
              </Row>
              {/* <div style={{ paddingTop:'10px '}}>
                  <p className="ant-upload-drag-icon" style={{ paddingTop:'10px'}}>
                    3- Browse or drag & drop excel file to upload.
                  </p></div> */}
            </Col>

            <Col span={1}>
              <div
                style={{
                  background: "#e4e4e4",
                  width: "2px",
                  height: "490px",
                  float: "right",
                }}
              />
            </Col>

            <Col span={11} offset={1}>
              <div
                style={{
                  textAlign: "center",
                  height: "initial",
                  background: "initial",
                }}
                className="rem-bgs"
              >
                <div style={{ paddingTop: "10px " }}>
                  <Row>
                    {/* <Col span={24}>
                                    <FormattedMessage id='assignment.description' />:
                                    </Col> */}
                    <Col span={24}>
                      {/* <TextArea
                                        type='text'
                                        style={{ width: '100%' }}
                                        value={props.assignmentDescription}
                                        maxLength={1000}
                                        onChange={e => {
                                            props.updateState({
                                                assignmentDescription: e.target.value,
                                            })
                                        }}
                                    /> */}
                      <CKEditor
                        // editor={ClassicEditor}
                        data={props.assignmentDescription || ""}
                        key={`${"emailBody"}`}
                        // onInit={editor => {
                        //     this.editor = editor;
                        // }}
                        onChange={(event) => {
                          const data = event.editor.getData();
                          props.updateState({ assignmentDescription: data });
                        }}
                        config={{
                          ...CUSTOM_CONFIGURATION_FORM,
                          placeholder: "Assignment Description",
                        }}
                      />
                    </Col>
                  </Row>
                </div>


                <br />

                <Dragger
                  multiple={true}
                  showUploadList={false}
                  beforeUpload={handleUpload}
                  fileList={props.docDetailList}
                  onRemove={handleRemove}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined
                      style={{ fontSize: "34px", color: "#7883b0" }}
                    />
                  </p>
                  <p className="ant-upload-text" style={{ color: "#363b51" }}>
                    {" "}
                    Drag and Drop file{" "}
                  </p>

                  <p className="ant-upload-text">or</p>
                  <p className="ant-upload-hint">
                    <div className="brawse-but">
                      {" "}
                      <Button
                        type="primary"
                        style={{
                          borderRadius: "0px",
                          padding: "0px 50px",
                          color: "#3157c2",
                          border: "solid 0px #578dc8",
                        }}
                      >
                        Browse files
                      </Button>
                    </div>
                  </p>
                </Dragger>
                {props.docDetailList && props.docDetailList.length ? (
                  <Row className="mt10">
                    <Col span={24}>
                      <table style={{ width: "100%", textAlign: "center" }}>
                        <thead>
                          <tr style={{ border: "1px solid #ddd" }}>
                            <th style={{ borderLeft: "1px solid #ddd" }}>
                              File Name
                            </th>
                            <th style={{ borderLeft: "1px solid #ddd" }}>
                              Size
                            </th>
                            <th style={{ borderLeft: "1px solid #ddd" }}>
                              Type
                            </th>

                            <th style={{ borderLeft: "1px solid #ddd" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.docDetailList.map((doc, i) => {
                            return (
                              <tr
                                key={i}
                                style={{ border: "1px solid #ddd" }}
                                className="pt5"
                              >
                                <td style={{ borderLeft: "1px solid #ddd" }}>
                                  {doc.fileName}
                                </td>
                                <td style={{ borderLeft: "1px solid #ddd" }}>
                                  {doc.fileSize || ""}
                                </td>
                                <td style={{ borderLeft: "1px solid #ddd" }}>
                                  {doc.fileType || ""}
                                </td>

                                <td style={{ borderLeft: "1px solid #ddd" }}>


                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#888",
                                      fontSize: "14px",
                                      padding: "10px 0px",
                                    }}

                                  >
                                    {" "}
                                    <i
                                      className="fa fa-trash"
                                      onClick={() => handleRemove(doc)}
                                      style={{ width: "20px" }}
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

              </div>

            </Col>
          </Row>
        </TabPane>

        <TabPane tab="Q&A" key="2" style={{ height: `calc(100vh - 192px)`, overflowY: `auto` }}>
          <div style={{ padding: '0 20%' }}>
            {(props.questionList || []).map((queObj, i) => {
              return (
                <>
                  <div key={"ques" + i} className="ques-box">
                    <div className="ques-body">
                      <Row gutter={[8, 16]}>
                        <Col span={12}>
                          <TextArea
                            maxLength={500}
                            autoSize={{
                              minRows: 1,
                              maxRows: 4,
                            }}
                            placeholder={`Enter Question ${i + 1}`}
                            value={queObj.questionText}
                            onChange={(e) => {
                              queObj.questionText = e.target.value;
                              let questionList = props.questionList;
                              questionList.splice(i, 1, queObj);
                              props.updateState({ questionList });
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <Dropdown
                            items={[
                              {
                                optionText: (
                                  <span>
                                    <i
                                      className="fa fa-dot-circle-o"
                                      aria-hidden="true"
                                    />{" "}
                                    Single Choice
                                  </span>
                                ),
                                optionKey: "singleChoice",
                              },
                              {
                                optionText: (
                                  <span>
                                    <i
                                      className="fa fa-check-square-o"
                                      aria-hidden="true"
                                    />{" "}
                                    Multiple Choice
                                  </span>
                                ),
                                optionKey: "multiChoice",
                              },
                              {
                                optionText: (
                                  <span>
                                    <FileTextOutlined /> Short Answer
                                  </span>
                                ),
                                optionKey: "shortAnswer",
                              },
                            ]}
                            optionKeyName="optionKey"
                            valueKeyName="optionText"
                            placeholder="Answer Type"
                            defaultValue="singleChoice"
                            value={queObj.answerType}
                            onSelect={(optionValue) => {
                              queObj.answerType = optionValue;
                              if (optionValue === "shortAnswer") {
                                queObj.optionList = [];
                              }
                              let questionList = props.questionList;
                              questionList.splice(i, 1, queObj);
                              props.updateState({ questionList });
                            }}
                          />
                        </Col>

                      </Row>

                      {(queObj.optionList || []).map((optObj, j) => {
                        return (
                          <div key={"que" + j}>
                            <Row
                              gutter={[8, 8]}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Col span={1} style={{ fontSize: "18px" }}>
                                {queObj.answerType === "multiChoice" ? (
                                  <i
                                    className="fa fa-square-o"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <i
                                    className="fa fa-circle-o"
                                    aria-hidden="true"
                                  />
                                )}
                              </Col>
                              <Col span={22}>
                                <TextArea
                                  maxLength={200}
                                  // style={{ width: '100%', padding: '5px' }}
                                  autoSize={{
                                    minRows: 1,
                                    maxRows: 3,
                                  }}
                                  // rows={1}
                                  // placeholder={props.intl.formatMessage({ id: 'optionText.placeholder' })}
                                  placeholder={`Option ${j + 1}`}
                                  value={optObj.optText}
                                  onChange={(e) => {
                                    optObj.optText = e.target.value;
                                    let optionList = queObj.optionList;
                                    optionList.splice(j, 1, optObj);
                                    queObj.optionList = optionList;
                                    let questionList = props.questionList;
                                    questionList.splice(i, 1, queObj);
                                    props.updateState({ questionList });
                                  }}
                                />
                              </Col>
                              <Col span={1} style={{ fontSize: "18px" }}>
                                <Tooltip
                                  placement="left"
                                  title={"Delete answer"}
                                  trigger="hover"
                                >
                                  <div
                                    onClick={() => {
                                      let optionList = queObj.optionList;
                                      optionList.splice(j, 1);
                                      queObj.optionList = optionList;
                                      let questionList = props.questionList;
                                      questionList.splice(i, 1, queObj);
                                      props.updateState({ questionList });
                                    }}
                                  >
                                    <CloseOutlined />
                                  </div>
                                </Tooltip>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      {queObj.answerType !== "shortAnswer" ? (
                        <Row
                          gutter={[8, 8]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Col span={1} style={{ fontSize: "18px" }}>
                            {queObj.answerType === "multiChoice" ? (
                              <i
                                className="fa fa-square-o"
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="fa fa-circle-o"
                                aria-hidden="true"
                              />
                            )}
                          </Col>
                          <Col span={22}>
                            <span
                              className="add-link"
                              onClick={() => {
                                let optionList = queObj.optionList;
                                optionList.push({});
                                queObj.optionList = optionList;
                                let questionList = props.questionList;
                                questionList.splice(i, 1, queObj);
                                props.updateState({ questionList });
                              }}
                            >
                              Add
                            </span>{" "}
                            Option
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Divider style={{ margin: "10px 0px" }} />
                      <div className="ques-footer">
                        <span
                          span={1}
                          style={{ fontSize: "16px", cursor: "pointer" }}
                          onClick={() => {
                            removeQuestion(props, i);
                          }}
                        >
                          <i className={ICONS.DELETE_P} />
                        </span>
                        <span className="sep" />
                        <span>Required</span>
                        <div style={{ width: "50px" }}>
                          <Switch
                            checked={queObj.isRequired === 1}
                            onClick={(checked, e) => {
                              queObj.isRequired = checked ? 1 : 0;
                              let questionList = props.questionList;
                              questionList.splice(i, 1, queObj);
                              props.updateState({ questionList });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: 'sticky',
                bottom: '-15px'
              }}
            >
              <Button
                className="addQues-btn mt10 mb15"
                type="primary"
                onClick={() => {
                  let questionList = props.questionList;
                  questionList.push({ optionList: [{}] });
                  props.updateState({ questionList });
                }}
              >
                <span style={{ color: "#58596C" }}>
                  <PlusOutlined />
                </span>
                <FormattedMessage id="addQuestion.text" />
              </Button>
            </div>
          </div>
        </TabPane>

      </Tabs>
      <div className="assignment-footer">
        <Button
          className="grey-button"
          onClick={() => {
            props.showModal({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
              footer: { submit: { intlId: "button.close.label" } },
              handleSubmit: () => {
                props.popModalFromStack();
              }
            })
          }}

        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onSubmitAssignment}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(CreateAssignmentComp);
